/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type useSetScenarioDescriptionMutationVariables = {
    scenarioId: string;
    description: string;
};
export type useSetScenarioDescriptionMutationResponse = {
    readonly setScenarioDescription: {
        readonly id: string;
        readonly description: string;
    };
};
export type useSetScenarioDescriptionMutation = {
    readonly response: useSetScenarioDescriptionMutationResponse;
    readonly variables: useSetScenarioDescriptionMutationVariables;
};



/*
mutation useSetScenarioDescriptionMutation(
  $scenarioId: ID!
  $description: String!
) {
  setScenarioDescription(scenarioId: $scenarioId, description: $description) {
    id
    description
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "description"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "scenarioId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "description",
        "variableName": "description"
      },
      {
        "kind": "Variable",
        "name": "scenarioId",
        "variableName": "scenarioId"
      }
    ],
    "concreteType": "Scenario",
    "kind": "LinkedField",
    "name": "setScenarioDescription",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useSetScenarioDescriptionMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useSetScenarioDescriptionMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "d6b1e602c286f56c685d8f6e98376048",
    "id": null,
    "metadata": {},
    "name": "useSetScenarioDescriptionMutation",
    "operationKind": "mutation",
    "text": "mutation useSetScenarioDescriptionMutation(\n  $scenarioId: ID!\n  $description: String!\n) {\n  setScenarioDescription(scenarioId: $scenarioId, description: $description) {\n    id\n    description\n  }\n}\n"
  }
};
})();
(node as any).hash = '06e29df0967c855d0d383f3f7ec2b6cc';
export default node;
