import {currencyValueFormatter} from "../ValueFormatters/CurrencyValueFormatter";

export const currencyColumn = {
    width: 115,
    valueFormatter: currencyValueFormatter,
    headerClass: 'ag-right-aligned-header',
    cellStyle: (params: any) => {
        if (params.value < 0)
            return {textAlign: "right", color: "#f5222d"}
        return {textAlign: "right"};
    },
}
