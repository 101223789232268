import {currencyColumn} from "./ColumnTypes/CurrencyColumn";
import {importantColumn} from "./ColumnTypes/ImportantColumn";
import {percentColumn} from "./ColumnTypes/PercentageColumn";
import {dateColumn} from "./ColumnTypes/DateColumn";
import {selectColumn} from "./ColumnTypes/SelectColumn";
import AgCalendarCellEditor from "./CellEditors/AgCalendarCellEditor";
import AgSelectCellEditor from "./CellEditors/AgSelectCellEditor";
import AgDeleteRenderer from "./CellRenderers/AgDeleteCellRenderer";
import AgBoolCellRenderer from "./CellRenderers/AgBoolCellRenderer";

export const columnTypes = {
    'currencyColumn': currencyColumn,
    'importantColumn': importantColumn,
    'percentageColumn': percentColumn,
    'dateColumn': dateColumn,
    'selectColumn': selectColumn
};


export const columnTypesComponents = {
    agCalendarCellEditor: AgCalendarCellEditor,
    selectCellEditor: AgSelectCellEditor,
    agDeleteRenderer: AgDeleteRenderer,
    agBoolRenderer: AgBoolCellRenderer,
}

