import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Avatar from "antd/lib/avatar/avatar";
import {Menu} from "antd";

const Profile = () => {
    const { user, isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
        return <div>Loading ...</div>;
    }

    if(!isAuthenticated)
        return <div>Not authenticated</div>

    return (
            <Menu.Item icon={<Avatar src={user?.picture} />}>
                {user?.name}
            </Menu.Item>
    );
};

export default Profile;