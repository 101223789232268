import {graphql} from "babel-plugin-relay/macro";
import {useMutation} from "react-relay/hooks";
import {useExpenseUpdateOrdinalsMutation} from "./__generated__/useExpenseUpdateOrdinalsMutation.graphql";
import {useCallback} from "react";
import {handleMutation} from "../HandleMutation";

const updateOrdinalMutation = graphql`
    mutation useFinancialAssetUpdateOrdinalsMutation($scenarioId: ID!, $inputType: InputType!, $ids: [String!]!){
        ordinalUpdate(scenarioId: $scenarioId, inputType: $inputType, ids: $ids ){
            assets {
                ...FinancialAssetsUpdate
            }
            result {
                ...ScenarioResultsDataResult
            }
            ...ScenarioDetailedSummary
            ...ScenarioHierarchicalMenu
        }
    }
`;

export default function useFinancialAssetUpdateOrdinals():[(scenarioId: string, id: string, ids: string[]) => void, boolean]{
    const [commit, committing] = useMutation<useExpenseUpdateOrdinalsMutation>(updateOrdinalMutation);
    const callback = useCallback((scenarioId: string, id: string, ids: string[]) => {
        handleMutation(id, "Financial Asset", "order", "update", commit, {
            variables: {scenarioId: scenarioId, inputType: "FinancialAsset", ids: ids}
        })
    }, [commit])

    return [callback, committing]
}