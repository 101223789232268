import {useFragment} from "react-relay/hooks";
import {ScenarioUpdate$key} from "./__generated__/ScenarioUpdate.graphql";
import {graphql} from "babel-plugin-relay/macro";
import React, {useContext, useState} from "react";
import {
    Button,
    DatePicker,
    Form,
    InputNumber,
    Space,
    Divider,
    Input,
    Select, FormProps, FormItemProps
} from 'antd';
import moment from 'moment';
import AppContext from "../../AppContext";
import useScenarioUpdate from "../Mutations/useScenarioUpdate";

const query =  graphql`
    fragment ScenarioUpdate on Scenario {
        id
        description
        startYear
        retirementAgeValue
        retirementAgeType
        inflationGeneralRate
        inflationCppRate
        inflationOasRate
    }
`;

export interface ScenarioUpdateProps {
    scenario: ScenarioUpdate$key
    formProps?: FormProps
    tailProps?: FormItemProps
    hideInflation?: boolean
}

export default function ScenarioUpdate (props: ScenarioUpdateProps) {
    const app = useContext(AppContext);
    const data = useFragment(query, props.scenario);
    const [commitUpdate, committingUpdate] = useScenarioUpdate();
    const [isChanged, setIsChanged] = useState(false);
    const [helpMessage, setHelpMessage] = useState("");

    const onFinish = (values:any) => {
        commitUpdate({
            id: data.id,
            description: data.description,
            startYear: (values.startYear as moment.Moment).year(),
            retirementAgeValue: values.retirementAgeValue,
            retirementAgeType: values.retirementAgeType,
            inflationGeneralRate: values.inflationGeneralRate,
            inflationCppRate: values.inflationCppRate,
            inflationOasRate: values.inflationOasRate
        },  response =>  {
                if(data.description !== response.scenarioUpdate.description)
                    app.setScenario({id: response.scenarioUpdate.id, name: response.scenarioUpdate.description})
                setIsChanged(false);
            }
        );
    }

    return (
            <Form
                {...props.formProps}
                initialValues={{
                      description: data.description,
                      startYear: moment(data.startYear, "YYYY"),
                      retirementAgeValue: data.retirementAgeValue,
                      retirementAgeType: data.retirementAgeType,
                      inflationGeneralRate: data.inflationGeneralRate,
                      inflationCppRate: data.inflationCppRate,
                      inflationOasRate: data.inflationOasRate
                  }}
                onFinish={onFinish}
                onChange={() => setIsChanged(true)}
            >
                <Form.Item
                    label="Retirement"
                    help={helpMessage}
                >
                    <Input.Group compact>
                        <Form.Item name="retirementAgeType" noStyle>
                            <Select style={{width: 200}} onChange={() => setIsChanged(true)}>
                                <Select.Option value={"Age"}>Age</Select.Option>
                                <Select.Option value={"WithdrawalRate"}>Withdrawal rate</Select.Option>
                                <Select.Option value={"FinAssetValue"}>Financial asset value</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            noStyle
                            rules={[{ required: true, min: 0 }]}
                            shouldUpdate={(prevValues, nextValues) => prevValues.retirementAgeType !== nextValues.retirementAgeType}
                        >
                            {({ getFieldValue }) => {
                                let field = <InputNumber
                                    min={25}
                                    max={100}
                                    onChange={() => setIsChanged(true)}
                                />
                                let fieldHelpMessage = "When your age is the specified value."

                                const fieldValue = getFieldValue('retirementAgeType');
                                if(fieldValue === "WithdrawalRate") {
                                    field = <InputNumber
                                        defaultValue={4}
                                        min={0}
                                        max={10}
                                        formatter={value => `${value}%`}
                                        parser={(value: any) => value === undefined ? 0 : value.replace('%', '')}
                                        onChange={() => setIsChanged(true)}/>

                                        fieldHelpMessage = "When your expenses divided by before tax financial assets equal the specified percentage.";
                                }
                                if(fieldValue === "FinAssetValue") {
                                    field = <InputNumber
                                        style={{width: 150}}
                                        step={10000}
                                        min={50000}
                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                        onChange={() => setIsChanged(true)}/>
                                    fieldHelpMessage = "When the value of your financial assets before taxes reaches the specified value.";
                                }

                                setHelpMessage(fieldHelpMessage)

                                return <Form.Item noStyle name="retirementAgeValue" rules={[{ required: true }]}>
                                    {field}
                                </Form.Item>;
                            }}
                        </Form.Item>
                    </Input.Group>
                </Form.Item>
                 <Form.Item
                    label="Forecast start year"
                    name="startYear"
                    rules={[{ required: true, message: 'Field required.' }]}>
                    <DatePicker picker="year" onChange={() => setIsChanged(true)} />
                </Form.Item>

                {props.hideInflation ? null : <Divider orientation="left">Inflation</Divider>}
                <Form.Item
                    hidden={props.hideInflation}
                    label="General"
                    name="inflationGeneralRate"
                    help={"Used for calculating real values and inflating tax brackets."}
                    rules={[{ required: true, message: 'Field required.' }]}>
                    <InputNumber
                        min={0}
                        max={100}
                        formatter={value => `${value}%`}
                        parser={(value:any) => value === undefined ? 0:value.replace('%', '')}
                        onChange={() => setIsChanged(true)}
                    />
                </Form.Item>

                <Form.Item
                    hidden={props.hideInflation}
                    label="CPP"
                    name="inflationCppRate"
                    help={"Used to inflate the CPP benefit, contribution maximums and exemptions."}
                    rules={[{ required: true, message: 'Field required.' }]}>
                    <InputNumber
                        min={0}
                        max={100}
                        formatter={value => `${value}%`}
                        parser={(value:any) => value === undefined ? 0:value.replace('%', '')}
                        onChange={() => setIsChanged(true)}
                    />
                </Form.Item>

                <Form.Item
                    hidden={props.hideInflation}
                    label="OAS"
                    name="inflationOasRate"
                    help={"Used to inflate the Old Age Security benefit payment and recovery thresholds (clawback)."}
                    rules={[{ required: true, message: 'Field required.' }]}>
                    <InputNumber
                        min={0}
                        max={100}
                        formatter={value => `${value}%`}
                        parser={(value:any) => value === undefined ? 0:value.replace('%', '')}
                        onChange={() => setIsChanged(true)}
                    />
                </Form.Item>
                <Divider />
                <Form.Item {...props.tailProps}>
                    <Space>
                        <Button type="primary" htmlType="submit" disabled={!isChanged || committingUpdate}>
                            Apply
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
)
};