import React from "react";
import {Card, Col, Row, Tooltip, Typography} from "antd";


const {Title, Paragraph, Text} = Typography;

export default function WelcomeStep() {

    return (
        <Card bordered={false} style={{height: "100%", background: "#fafafa"}}>
            <Row>
                <Col span={7}></Col>
                <Col span={10} style={{backgroundColor: "#ffffff", padding: 12}}>
                    <Title>Welcome to Wealth.li</Title>
                    <Paragraph>
                        To get started we are going to gather some information about you and your finances.
                    </Paragraph>
                    <Paragraph>
                        We'll start by building your <Tooltip title={"Current assets minus your current debts. If you have a negative net worth then you owe more than you have."}><Text underline>Net Worth</Text></Tooltip> statement then your Net Cash Flow statement.
                    </Paragraph>
                    <Paragraph>
                        When we are done you'll have a view of your financial future that will help you answer questions like:
                    </Paragraph>
                    <Paragraph>
                        <ol>
                            <li>How long will my money last?</li>
                            <li>How much can I spend?</li>
                            <li>When should I take my benefits CPP and OAS?</li>
                            <li>Which of my assets should I spend first?</li>
                            <li>Am I currently saving enough?</li>
                            <li>How much can I pass on to my children when I pass?</li>
                        </ol>
                    </Paragraph>
                </Col>
            </Row>
        </Card>
    )
}
