/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UserStatusType = "Active" | "Inactive" | "OnBoarding" | "Pending" | "%future added value";
export type useOnBoardingCompletedMutationVariables = {};
export type useOnBoardingCompletedMutationResponse = {
    readonly onBoardingCompleted: {
        readonly id: string;
        readonly status: UserStatusType;
    };
};
export type useOnBoardingCompletedMutation = {
    readonly response: useOnBoardingCompletedMutationResponse;
    readonly variables: useOnBoardingCompletedMutationVariables;
};



/*
mutation useOnBoardingCompletedMutation {
  onBoardingCompleted {
    id
    status
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "onBoardingCompleted",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useOnBoardingCompletedMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useOnBoardingCompletedMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "4452ee82f1c045a28fab91c58b726619",
    "id": null,
    "metadata": {},
    "name": "useOnBoardingCompletedMutation",
    "operationKind": "mutation",
    "text": "mutation useOnBoardingCompletedMutation {\n  onBoardingCompleted {\n    id\n    status\n  }\n}\n"
  }
};
})();
(node as any).hash = '9dcc04f82ab863eb6c8f36e892cd7ec6';
export default node;
