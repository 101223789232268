/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FinancialAssetType = "NonRegistered" | "Rrsp" | "Tfsa" | "%future added value";
export type EstateChartResult = {
    readonly retirementAge: number;
    readonly steps: ReadonlyArray<{
        readonly age: number;
        readonly year: number;
        readonly cash: number;
        readonly debtsOwingTotal: number;
        readonly financialAssetsTotal: number;
        readonly financialAssets: ReadonlyArray<{
            readonly type: FinancialAssetType | null;
            readonly value: number;
        }>;
        readonly realAssetsTotal: number;
        readonly estate: {
            readonly afterTax: number;
            readonly beforeTax: number;
            readonly afterTaxReal: number;
        };
    }>;
    readonly " $refType": "EstateChartResult";
};
export type EstateChartResult$data = EstateChartResult;
export type EstateChartResult$key = {
    readonly " $data"?: EstateChartResult$data;
    readonly " $fragmentRefs": FragmentRefs<"EstateChartResult">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EstateChartResult",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "retirementAge",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ResultStep",
      "kind": "LinkedField",
      "name": "steps",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "age",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "year",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cash",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "debtsOwingTotal",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "financialAssetsTotal",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AssetResult",
          "kind": "LinkedField",
          "name": "financialAssets",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "realAssetsTotal",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EstateResult",
          "kind": "LinkedField",
          "name": "estate",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "afterTax",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "beforeTax",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "afterTaxReal",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Result",
  "abstractKey": null
};
(node as any).hash = 'e8c5fdbbb14d482f34c1473bc9487984';
export default node;
