import {
    Button,
    Card,
    Layout,
    Result,
    Spin,
    Tabs,
} from "antd";
import React, {Suspense, useContext} from "react";
import {Route, Switch} from "react-router-dom";
import AppContext from "../AppContext";
import {LoadingOutlined, CloseOutlined} from '@ant-design/icons'
import ForecastWorkspace from "./Forecast/ForecastWorkspace";
import CompareWorkspace from "./Compare/CompareWorkspace";
import {useLazyLoadQuery} from "react-relay/hooks";
import {graphql} from "babel-plugin-relay/macro";
import {ScenarioEditor} from "./ScenarioEditor/ScenarioEditor";
import {PlannerContentQuery} from "./__generated__/PlannerContentQuery.graphql";
import ScenarioUpdate from "./ScenarioEditor/ScenarioUpdate";
import BenefitsUpdate from "./ScenarioEditor/Benefits/BenefitsUpdate";
import {useUserConfig} from "./UserConfigContext";
import ScenarioTableSider from "./ScenarioTableSider";
import useScenarioAdd from "./Mutations/useScenarioAdd";

const {Content, Sider} = Layout;

const query = graphql`
    query PlannerContentQuery($scenarioId: ID!) {
        me {
            person {
                scenarios(ids: [$scenarioId]) {
                    id
                    ...ScenarioEditorQuery
                    ...ScenarioUpdate
                    ...ScenarioTableSider
                    benefits {
                        ...BenefitsUpdate
                    }
                }
            }
        }
    }`;

export default function PlannerContent() {
    const [userConfig, setUserConfig] = useUserConfig();
    const app = useContext(AppContext);
    const data = useLazyLoadQuery<PlannerContentQuery>(query, {scenarioId: app.scenario.id});
    const [commitAdd] = useScenarioAdd();

    if(data.me.person.scenarios?.length === 0)
        return <Result
            status={"warning"}
            title="Please create a plan to start!"
            children={<div style={{textAlign: "center"}}><Button size={"large"} type={"primary"} onClick={() => commitAdd(app.person.id, null, "New plan", () => {})}>New plan</Button></div>}
        />;

    // const income = <span style={{float: "right", fontSize: "12px", color: "green"}}><DollarOutlined /> Income</span>;
    // const expense = <span style={{float: "right", fontSize: "12px", color: "red"}}><ShopOutlined /> Expense</span>;
    // const property = <span style={{float: "right", fontSize: "12px", color: "black"}}><HomeOutlined /> Property</span>;
    // const options = [
    //     {
    //         label: "Income",
    //         options: [
    //             {value: "11", label: <span>Salary {income}</span>},
    //             {value: "12", label: <span>Employer Match {income}</span>},
    //             {value: "13", label: <span>Bonus {income}</span>},
    //             {value: "14", label: <span>STI {income}</span>},
    //             {value: "15", label: <span>LTI {income}</span>},
    //             {value: "16", label: <span>Pension {income}</span>},
    //         ]
    //     },
    //     {
    //         label: "Expenses",
    //         options: [
    //             {value: "23", label: <span>Base {expense}</span>},
    //             {value: "24", label: <span>Food {expense}</span>},
    //             {value: "25", label: <span>Shelter {expense}</span>},
    //             {value: "28", label: <span>Transportation {expense}</span>},
    //             {value: "26", label: <span>Entertainment {expense}</span>},
    //             {value: "27", label: <span>Child care {expense}</span>},
    //         ]
    //     },
    //     {
    //         label: "Investing",
    //         options: [
    //             {value: "37", label: "Non-tax sheltered"},
    //             {value: "38", label: "TFSA"},
    //             {value: "39", label: "RRSP"},
    //         ]
    //     },
    //     {
    //         label: "Property",
    //         options: [
    //             {value: "410", label: <span>House {property}</span>},
    //             {value: "411", label: <span>Car {property}</span>},
    //             {value: "471", label: <span>Boat {property}</span>},
    //             {value: "472", label: <span>Custom {property}</span>},
    //         ]
    //     },
    //     {
    //         label: "Debt",
    //         options: [
    //             {value: "512", label: "Mortgage"},
    //             {value: "513", label: "Car Loan"},
    //         ]
    //     },
    // ];

    return (
        <Layout style={{height: "calc(100vh - 60px)"}}>
            <Sider
                width={275}
                style={{
                    overflow: 'auto',
                    backgroundColor: "#fafafa",
                    display: `${userConfig.siders.leftIsVisible ? "block" : "none"}`
                }}
            >
                <Card style={{backgroundColor: "#fafafa"}}
                      bordered={false}
                      title={"Financial Statements"}
                      extra={<Button type={"text"} style={{float: "right"}} onClick={() => setUserConfig({...userConfig, siders: {...userConfig.siders, leftIsVisible: !userConfig.siders.leftIsVisible}})}><CloseOutlined /></Button>}
                >
                    {/*<AutoComplete size={"large"} style={{width: "100%"}} placeholder="Add items" options={options} />*/}
                    <ScenarioTableSider data={data.me.person.scenarios[0]} />
                </Card>
            </Sider>
            <Layout>
                <Content style={{overflowY: "hidden"}}>
                    <Suspense fallback={
                        <div style={{height: "calc(100vh - 100px)", width: '100%', display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <Spin tip={`Loading...`} indicator={<LoadingOutlined spin style={{ fontSize: 24 }} />} />
                        </div>
                    }>
                        <Switch>
                            <Route path="/scenario/forecast">
                                <ForecastWorkspace />
                            </Route>
                            <Route path="/scenario/compare">
                                <CompareWorkspace />
                            </Route>
                            <Route path="/scenario/edit">
                                <ScenarioEditor key={"ScenarioEditor"} scenario={data.me.person.scenarios[0]} />
                            </Route>
                        </Switch>
                    </Suspense>
                </Content>
            </Layout>
            <Sider
                width={350}
                style={{
                    overflow: 'auto',
                    height: 'calc(100vh - 60px)',
                    backgroundColor: "#fafafa",
                    display: `${userConfig.siders.rightIsVisible ? "block" : "none"}`
                }}
            >
                <Card style={{backgroundColor: "#fafafa"}}
                      title={"Assumptions"}
                      extra={<Button type={"text"} onClick={() => setUserConfig({...userConfig, siders: {...userConfig.siders, rightIsVisible: !userConfig.siders.rightIsVisible}})}><CloseOutlined /></Button>}>
                    <Tabs size={"small"}>
                        <Tabs.TabPane tab={"General"} key={"general"}>
                            <ScenarioUpdate key={data.me.person.scenarios[0].id} scenario={data.me.person.scenarios[0]} formProps={{layout: "vertical"}} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={"Benefits"} key={"benefits"} >
                            <BenefitsUpdate key={data.me.person.scenarios[0].id} data={data.me.person.scenarios[0].benefits} />
                        </Tabs.TabPane>
                    </Tabs>
                </Card>
            </Sider>
        </Layout>
    )
}