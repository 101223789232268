import React, {Suspense, useState} from 'react';
import {RelayEnvironmentProvider, useLazyLoadQuery} from "react-relay/hooks";
import Environment from './Environment';
import {Spin} from "antd";
import {LoadingOutlined} from '@ant-design/icons'
import {BrowserRouter as Router} from "react-router-dom";
import AppContext from "./AppContext";
import OnBoarding from "./Components/OnBoarding/OnBoarding";
import './App.less';
import Planner from "./Components/Planner";
import {UserConfigProvider} from "./Components/UserConfigContext";
import {graphql} from "babel-plugin-relay/macro";
import {AppPersonQuery} from "./__generated__/AppPersonQuery.graphql";
import {useAuth0} from "@auth0/auth0-react";
import AppConfig from "./AppConfig";


const query =  graphql`
    query AppPersonQuery {
        me {
            status
        }
    }
`;

function AppContent(){
    const data = useLazyLoadQuery<AppPersonQuery>(query, {});

    if(data.me.status === "OnBoarding")
        return <OnBoarding />;

    return <Planner key={"planner"} />;

}

function AppLayout() {
    const [compare, setCompare] = useState<string[]>([]);
    const [scenario, setScenario] = useState({id: "00000000-0000-0000-0000-000000000000", name: "select a plan"});
    const [person, setPerson] = useState({id: "00000000-0000-0000-0000-000000000000"});
    const app = {
        scenario: scenario,
        setScenario: setScenario,
        compare: compare,
        setCompare: setCompare,
        person: person,
        setPerson: setPerson
    };

    return (
        <Suspense fallback={
            <div style={{height: "100vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <Spin tip={`Initializing...`} indicator={<LoadingOutlined spin style={{ fontSize: 24 }} />} />
            </div>
        }>
            <AppContext.Provider value={app}>
                <AppContent />
            </AppContext.Provider>
        </Suspense>
    );
}

function App() {
    const {isAuthenticated, getAccessTokenSilently, loginWithRedirect, isLoading } = useAuth0();
    const getToken = async () => {
        return await getAccessTokenSilently({
            audience: AppConfig.Auth.Audience,
            scope: AppConfig.Auth.Realm,
        });
    }

    if(isLoading) {
        return <div style={{height: "100vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Spin tip={`Authenticating...`} indicator={<LoadingOutlined spin style={{fontSize: 24}}/>}/>
        </div>
    }

    if(!isAuthenticated)
        loginWithRedirect({
            audience: AppConfig.Auth.Audience,
            scope: AppConfig.Auth.Realm,
        });

    return (
            <RelayEnvironmentProvider environment={Environment(getToken)}>
                <Router>
                    <UserConfigProvider>
                        <AppLayout/>
                    </UserConfigProvider>
                </Router>
            </RelayEnvironmentProvider>);
}

export default App;
