import React, {useEffect, useState} from "react";
import {AgGridReact} from "ag-grid-react";
import {GridReadyEvent} from "ag-grid-community/dist/lib/events";
import {GridApi, ValueSetterParams} from "ag-grid-community";
import {columnTypes, columnTypesComponents} from "../Grids/ColumnTypes";
import {DeleteColumnDef} from "../Grids/ColumnDefs";
import {onDragStopped} from "../Grids/Utilities";

export interface Loan {
    id: string
    description: string
    balance: number
    interestRate: number
    monthlyPayment: number
    taxDeductible: boolean
    futureStartDate: any
    onRemove: () => void
}

export default function LoansGrid (props: {
    data: Loan[]
    update: (value: Loan) => boolean
    updateOrdinals: (ids: string[]) => void
}) {
    const [gridApi, setGridApi] = useState<GridApi>();

    function onGridReady(event: GridReadyEvent) {
        setGridApi(event.api);
    }

    const [gridState] = useState({
        columnDefs: [
            {   headerName: "Description",
                field: "description",
                editable: true,
                rowDrag: true,
                valueSetter:  (params:ValueSetterParams) =>  props.update({...params.data, description: params.newValue})
            },
            {   headerName: "Balance",
                field: "balance",
                type: ['currencyColumn','importantColumn'],
                editable: true,
                valueSetter:  (params:ValueSetterParams) =>  props.update({...params.data, balance: params.newValue ?? 0})
            },
            {   headerName: "Interest Rate",
                field: "interestRate",
                width: 140,
                type: ['percentageColumn','importantColumn'],
                editable: true,
                valueSetter:  (params:ValueSetterParams) =>  props.update({...params.data, interestRate: params.newValue ?? 0})
            },
            {   headerName: "Monthly Payment",
                width: 150,
                field: "monthlyPayment",
                type: ['currencyColumn','importantColumn'],
                editable: true,
                valueSetter:  (params:ValueSetterParams) =>  props.update({...params.data, monthlyPayment: params.newValue ?? 0})
            },
            // {
            //     headerName: "Tax Deductible",
            //     field: "taxDeductible",
            //     type: "selectColumn",
            //     width: 105,
            //     editable: true,
            //     valueSetter: (params: ValueSetterParams) => props.update({...params.data, taxDeductible: params.newValue ?? 0}),
            //     cellRenderer: (params: ICellRendererParams) => params.data.taxableGains ? "Yes" : "No",
            //     cellEditorParams: {values: [
            //             { key: true, value: "Yes" },
            //             { key: false, value: "No" },
            //         ]},
            // },
            {
                headerName: "Start date",
                field: "futureStartDate",
                type: "dateColumn",
                width: 115,
                editable: true,
                valueSetter: (params: ValueSetterParams) => props.update({
                    ...params.data,
                    futureStartDate: params.newValue
                }),
                cellEditorParams: { allowClear: true}
            },
            DeleteColumnDef(),
        ],
        columnTypes: columnTypes,
        components: columnTypesComponents,
        defaultColDef: {
            resizable: true,
        },
    });

    useEffect(() => {
        gridApi?.setRowData(props.data)
    }, [gridApi, props.data])

    return <div className="ag-theme-material">
        <AgGridReact
            stopEditingWhenGridLosesFocus={true}
            rowData={props.data}
            domLayout={"autoHeight"}
            singleClickEdit={true}
            onDragStopped={event => onDragStopped(event, props.updateOrdinals)}
            suppressMoveWhenRowDragging={true}
            rowDragManaged={true}
            onGridReady={onGridReady}
            frameworkComponents={gridState.components}
            columnDefs={gridState.columnDefs}
            columnTypes={gridState.columnTypes}
            defaultColDef={gridState.defaultColDef}
            suppressNoRowsOverlay={true}
        />
    </div>
}