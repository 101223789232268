import React, {useContext, useState} from "react";
import {Button, Dropdown, InputNumber, Menu, Popover, Table, Typography} from "antd";
import {QuestionCircleOutlined, MoreOutlined, DeleteOutlined} from "@ant-design/icons"
import { graphql } from "babel-plugin-relay/macro";
import {useFragment} from "react-relay/hooks";
import {currencyNumberFormatter} from "./ScenarioEditor/Grids/ValueFormatters/CurrencyValueFormatter";
import {ColumnsType} from "antd/lib/table/interface";
import {TextProps} from "antd/es/typography/Text";
import {ScenarioTableSider$key} from "./__generated__/ScenarioTableSider.graphql";
import useIncomeRemove from "./Mutations/useIncomeRemove";
import AppContext from "../AppContext";
import useAmountUpdate from "./Mutations/useAmountUpdate";
import useIncomeUpdate from "./Mutations/useIncomeUpdate";
import moment from "moment";

const {Text, Paragraph} = Typography;

const query = graphql`
    fragment ScenarioTableSider on Scenario {
        income {
            currentIncome: incomeSources(type: None) {
                id
                description
                amount
            }
        }
        expenses {
            currentExpenses: expenseSources(type: None) {
                id
                description
                amount
            }
        }
        assets {
            financialAssets {
                id
                description
                amount
            }
            currentRealAssets: realAssets(current: true) {
                id
                description
                currentValue
            }
        }
        debts {
            loans {
                id
                description
                balance
                monthlyPayment
            }
            
        }
        benefits {
            rrspRoom
            tfsaRoom
            cppAge
            oasYearsInCanada
            oasMonthsDelayed
        }
        result {
            id
            retirementAge
            firstStep {
                tax {
                    cpp
                    ei
                    provincialTax
                    federalTax
                    total
                }
            }
            retirementStep {
                financialAssetsTotal
            }
            lastStep {
                age
                estate {
                    beforeTax
                    afterTax
                    afterTaxReal
                }
            }
        }
    }
`;


const AmountText = (props: {hoverKey: string | undefined, record: {text?: TextProps, key: string, editable?: {save: (value: number) => void}}, value: any}) => {
    const [editing, setEditing] = useState(false);
    const [newValue, setNewValue] = useState(props.value);
    const style = (props.hoverKey !== undefined && props.hoverKey === props.record.key && props.record.editable) ? {width: "100%", margin: -3, padding: 2, border: 1, borderStyle: "solid", borderColor: "#cacaca", cursor: "default"}:{};
    let textProps = {...props.record.text, style: {...style, ...props.record.text?.style}};
    if(props.value < 0)
        textProps = {...textProps, type: "danger"}

    return !editing || !props.record.editable
        ? <Text onClick={() => setEditing(true)} {...textProps}>{currencyNumberFormatter(props.value)}</Text>
        : <InputNumber
            style={{margin: -3, width: "75px"}}
            formatter={value => currencyNumberFormatter(value)}
            parser={(value:any) => value.replace(/\$\s?|(,*)|\(|\)/g, '')}
            ref={(input) => {input?.focus(); input?.select()}}
            onChange={value => {
                setNewValue(value);
            }}
            step={10000}
            onBlur={() => {
                setEditing(false);
                if(newValue !== props.value)
                    props.record.editable?.save(newValue);
            }}
            onPressEnter={() => {
                setEditing(false);
                if(newValue !== props.value)
                    props.record.editable?.save(newValue);
            }}
            value={props.value}
            size={"small"} />;
}

export default function ScenarioTableSider(props: {data: ScenarioTableSider$key}) {
    const app = useContext(AppContext);
    const data = useFragment(query, props.data);
    const [hoverKey, setHoverKey] = useState<string>();
    const [commitIncomeRemove] = useIncomeRemove();
    const [commitAmountUpdate] = useAmountUpdate();
    const [commitIncomeUpdate] = useIncomeUpdate();

    const incomeTotal = data.income.currentIncome.reduce((pv, cv) => pv + cv.amount, 0);
    const expenseTotal = data.expenses.currentExpenses.reduce((previousValue, currentValue) => previousValue + currentValue.amount, 0);
    const taxTotal = data.result.firstStep.tax.total;
    const loansTotal = 12 * data.debts.loans.reduce((previousValue, currentValue) => previousValue + currentValue.monthlyPayment, 0);
    const propertyTotal = data.assets.currentRealAssets.reduce((previousValue, currentValue) => previousValue + currentValue.currentValue, 0);
    const debtTotal = data.debts.loans.reduce((previousValue, currentValue) => previousValue + currentValue.balance, 0);
    const investmentsTotal = data.assets.financialAssets.reduce((previousValue, currentValue) => previousValue + currentValue.amount, 0);
    const netCashFlow = incomeTotal - expenseTotal - taxTotal - loansTotal;
    const netWorth = propertyTotal + investmentsTotal - debtTotal;

    const leafText = (description: string) => {return {ellipsis: {tooltip: description}}};

    const incomeMenu = () =><Menu>
        <Menu.Item key={"newIncome"} onClick={() =>
     commitIncomeUpdate(app.scenario.id, {
        id: "00000000-0000-0000-0000-000000000000",
        amount: 0,
        annualIncrease: 0,
        description: "New income",
        type: "Employment",
        startType: "Now",
        startDate: moment().format("yyyy-MM-DD"),
        endType: "Retirement",
        endDate: moment().format("yyyy-MM-DD")
     })} >Add</Menu.Item>
    </Menu>
    const menu = function (id: string, description: string){
        return <Menu key={`${id}-dropdown`}>
            {/*<Menu.Item key={"editIncome"} icon={<EditOutlined />}>Edit</Menu.Item>*/}
            {/*<Menu.Divider />*/}
            <Menu.Item key={"deleteIncome"} icon={<DeleteOutlined />} danger onClick={() => commitIncomeRemove(app.scenario.id, id, description)}>Delete</Menu.Item>
        </Menu>
    }

    const columns:ColumnsType<any> = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            width: "100px",
            onCell: () => {return {style: { border: 1, backgroundColor: "#fafafa"}}},
            render: (value:any, record) => record?.text ? <Text {...record.text} style={{width: "100px"}}>{value}</Text> : value,
        },
        {
            title: "menu",
            dataIndex: "menu",
            key: "menu",
            width: "25px",
            onCell: () => {return {style: {border: 0, padding: 0, backgroundColor: "#fafafa"}}},
            render: (value, record) => {
                if(!record?.key || record.menu === undefined)
                    return <div />

                return <Dropdown key={`${record.key}-menu`} overlay={record.menu} trigger={["click"]}><Button size={"small"} type={"text"} hidden={hoverKey !== record.key} icon={<MoreOutlined />} /></Dropdown>
            }
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
            align: "right",
            onCell: () => {return {style: {border:0, backgroundColor: "#fafafa"}}},
            render: (value:any, record) => {
                if(!record)
                    return;
                return <AmountText key={record.key} value={value} hoverKey={hoverKey} record={record} />
            },
        }
    ];

    const dataSource = [
        {key: "space1"},
        {
            key: "netcashflow",
            name: <div style={{width: "135px"}}>Net Cash Flow <Popover content={<Paragraph type={"secondary"} style={{width: 200}} >Current inflows minus your current outflow.
                A positive net cash flow means that your are making more money then you are spending.</Paragraph>} >
                <QuestionCircleOutlined style={{color: "rgba(0, 0, 0, 0.45)"}} />
            </Popover>
            </div>,
            amount: netCashFlow,
            text: {strong: true},
            children: [
                {
                    key: "income",
                    name: "Income",
                    amount: incomeTotal,
                    menu: incomeMenu(),
                    text: {strong: true},
                    children: data.income.currentIncome.map(x => {return {
                        key: x.id,
                        name: x.description,
                        amount: x.amount,
                        text: leafText(x.description),
                        editable: {save: (value:number) => commitAmountUpdate(app.scenario.id, "Income", x.id, value, x.description)},
                        menu: menu(x.id, x.description)
                    }})
                },
                {
                    key: "expenses",
                    name: "Expenses",
                    text: {strong: true},
                    amount: -expenseTotal,
                    children: data.expenses.currentExpenses.map(x => {return {
                        key: x.id,
                        name: x.description,
                        amount: -x.amount,
                        text: leafText(x.description),
                        editable: {save: (value:number) => commitAmountUpdate(app.scenario.id, "Expense", x.id, value, x.description)},
                    }})
                },
                {
                    key: "taxes",
                    name: "Taxes",
                    text: {strong: true},
                    amount: -taxTotal,
                    children: [
                        {
                            name: "Federal",
                            amount: -data.result.firstStep.tax.federalTax,
                            text: leafText("Federal"),
                        },
                        {
                            name: "Provincial",
                            amount: -data.result.firstStep.tax.provincialTax,
                            text: leafText("Provincial"),
                        },
                        {
                            name: "CPP",
                            amount: -data.result.firstStep.tax.cpp,
                            text: leafText("CPP"),
                        },
                        {
                            name: "EI",
                            amount: -data.result.firstStep.tax.ei,
                            text: leafText("EI"),
                        },

                    ]
                },
                (data.debts.loans.length === 0 ? {} :
                    {
                        key: "loans",
                        name: "Loans",
                        text: {strong: true},
                        amount: -loansTotal,
                        children: data.debts.loans.map(x => {return {
                            key: `${x.id}-loan1`,
                            name: x.description,
                            amount: -x.monthlyPayment * 12,
                            text: leafText(x.description),
                        }})
                    }
                ),
            ]
        },
        {
          key: "space"
        },
        {
            key: "networth",
            name: <div>Net Worth <Popover content={<Paragraph type={"secondary"} style={{width: 200}} >Current assets minus your current debts.
                If you have a negative net worth then you owe more than you have.</Paragraph>}>
                <QuestionCircleOutlined style={{color: "rgba(0, 0, 0, 0.45)"}} />
            </Popover>
            </div>,
            amount: netWorth,
            text: {strong: true},
            children: [
                {
                    key: "investing",
                    name: "Investing",
                    amount: investmentsTotal,
                    text: {strong: true},
                    children: data.assets.financialAssets.map(x => {return {
                        key: x.id,
                        name: x.description,
                        amount: x.amount,
                        text: leafText(x.description),
                        editable: {save: (value:number) => commitAmountUpdate(app.scenario.id, "FinancialAsset", x.id, value, x.description)},
                    }})
                },
                ...(data.assets.currentRealAssets.length === 0 ? [] : [
                {
                    key: "property",
                    name: "Property",
                    amount: propertyTotal,
                    text: {strong: true},
                    children: data.assets.currentRealAssets.map(x => {return {
                        key: x.id,
                        name: x.description,
                        amount: x.currentValue,
                        text: leafText(x.description),
                        editable: {save: (value:number) => commitAmountUpdate(app.scenario.id, "RealAsset", x.id, value, x.description)},
                    }})
                }]),
                ...(data.debts.loans.length === 0 ? [] : [
                {
                    key: "debt",
                    name: "Debt",
                    text: {strong: true},
                    amount: -debtTotal,
                    children: data.debts.loans.map(x => {return {
                        key: x.id,
                        name: x.description,
                        amount: -x.balance,
                        text: leafText(x.description),
                        editable: {save: (value:number) => commitAmountUpdate(app.scenario.id, "Loan", x.id, value, x.description)},
                    }})
                }]),
            ]
        }
    ];

    return (
           <Table
               size={"small"}
               columns={columns}
               dataSource={dataSource}
               pagination={false}
               showHeader={false}
               defaultExpandAllRows={true}
               //defaultExpandedRowKeys={["netcashflow","networth"]}
               expandable={{indentSize: 4}}
               onRow={(data) => {
                   return {
                       onMouseEnter: () => setHoverKey(data.key),
                       onMouseLeave: () => setHoverKey(undefined),
                       style: {height: "20px"}
                   }
               }}
           />
    );
}