import {ValueFormatterParams} from "ag-grid-community";

export const currencyNumberFormatter = (value: number) => {
    if(value === undefined)
        return "";

    const formattedValue = `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return value === 0 ? '-' : value < 0 ? `(${formattedValue.replace('-','')})`: formattedValue;
}

export const currencyValueFormatter = (params: ValueFormatterParams) => {
    return currencyNumberFormatter(params.value);
};