/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type OnBoardingQueryVariables = {};
export type OnBoardingQueryResponse = {
    readonly me: {
        readonly person: {
            readonly id: string;
            readonly defaultScenario: {
                readonly id: string;
                readonly description: string;
                readonly result: {
                    readonly " $fragmentRefs": FragmentRefs<"NetCashFlowChartResult" | "EstateChartResult" | "FinancialAssetsChartResult">;
                };
                readonly assets: {
                    readonly " $fragmentRefs": FragmentRefs<"FinancialAssetsUpdate">;
                };
            };
        };
    };
};
export type OnBoardingQuery = {
    readonly response: OnBoardingQueryResponse;
    readonly variables: OnBoardingQueryVariables;
};



/*
query OnBoardingQuery {
  me {
    person {
      id
      defaultScenario {
        id
        description
        result {
          ...NetCashFlowChartResult
          ...EstateChartResult
          ...FinancialAssetsChartResult
          id
        }
        assets {
          ...FinancialAssetsUpdate
          id
        }
      }
    }
    id
  }
}

fragment EstateChartResult on Result {
  retirementAge
  steps {
    age
    year
    cash
    debtsOwingTotal
    financialAssetsTotal
    financialAssets {
      type
      value
    }
    realAssetsTotal
    estate {
      afterTax
      beforeTax
      afterTaxReal
    }
  }
}

fragment FinancialAssetsChartResult on Result {
  retirementAge
  steps {
    age
    year
    financialAssets {
      name
      value
      type
    }
  }
}

fragment FinancialAssetsUpdate on Assets {
  id
  financialAssets {
    id
    description
    type
    amount
    cost
    percentCash
    rorCash
    percentFixedIncome
    rorFixedIncome
    percentEquity
    rorEquity
  }
}

fragment NetCashFlowChartResult on Result {
  retirementAge
  steps {
    age
    year
    expensesTotal
    debtsPaidTotal
    tax {
      total
    }
    income {
      name
      amount
    }
    oas {
      payment
    }
    cpp {
      payment
    }
    financialAssetsContribTotal
    financialAssets {
      name
      contribution
      type
    }
    realAssets {
      cash
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "payment",
    "storageKey": null
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cash",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OnBoardingQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Scenario",
                "kind": "LinkedField",
                "name": "defaultScenario",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Result",
                    "kind": "LinkedField",
                    "name": "result",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "NetCashFlowChartResult"
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "EstateChartResult"
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "FinancialAssetsChartResult"
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Assets",
                    "kind": "LinkedField",
                    "name": "assets",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "FinancialAssetsUpdate"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "OnBoardingQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Scenario",
                "kind": "LinkedField",
                "name": "defaultScenario",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Result",
                    "kind": "LinkedField",
                    "name": "result",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "retirementAge",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ResultStep",
                        "kind": "LinkedField",
                        "name": "steps",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "age",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "year",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "expensesTotal",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "debtsPaidTotal",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TaxResult",
                            "kind": "LinkedField",
                            "name": "tax",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "total",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "IncomeResult",
                            "kind": "LinkedField",
                            "name": "income",
                            "plural": true,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OASResults",
                            "kind": "LinkedField",
                            "name": "oas",
                            "plural": false,
                            "selections": (v4/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CPPResult",
                            "kind": "LinkedField",
                            "name": "cpp",
                            "plural": false,
                            "selections": (v4/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "financialAssetsContribTotal",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AssetResult",
                            "kind": "LinkedField",
                            "name": "financialAssets",
                            "plural": true,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "contribution",
                                "storageKey": null
                              },
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "value",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "RealAssetResult",
                            "kind": "LinkedField",
                            "name": "realAssets",
                            "plural": true,
                            "selections": [
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "debtsOwingTotal",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "financialAssetsTotal",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "realAssetsTotal",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EstateResult",
                            "kind": "LinkedField",
                            "name": "estate",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "afterTax",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "beforeTax",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "afterTaxReal",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Assets",
                    "kind": "LinkedField",
                    "name": "assets",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "FinancialAsset",
                        "kind": "LinkedField",
                        "name": "financialAssets",
                        "plural": true,
                        "selections": [
                          (v0/*: any*/),
                          (v1/*: any*/),
                          (v5/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cost",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "percentCash",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "rorCash",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "percentFixedIncome",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "rorFixedIncome",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "percentEquity",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "rorEquity",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a4d640ae278df9c0fd87e38fd2e3a4ab",
    "id": null,
    "metadata": {},
    "name": "OnBoardingQuery",
    "operationKind": "query",
    "text": "query OnBoardingQuery {\n  me {\n    person {\n      id\n      defaultScenario {\n        id\n        description\n        result {\n          ...NetCashFlowChartResult\n          ...EstateChartResult\n          ...FinancialAssetsChartResult\n          id\n        }\n        assets {\n          ...FinancialAssetsUpdate\n          id\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment EstateChartResult on Result {\n  retirementAge\n  steps {\n    age\n    year\n    cash\n    debtsOwingTotal\n    financialAssetsTotal\n    financialAssets {\n      type\n      value\n    }\n    realAssetsTotal\n    estate {\n      afterTax\n      beforeTax\n      afterTaxReal\n    }\n  }\n}\n\nfragment FinancialAssetsChartResult on Result {\n  retirementAge\n  steps {\n    age\n    year\n    financialAssets {\n      name\n      value\n      type\n    }\n  }\n}\n\nfragment FinancialAssetsUpdate on Assets {\n  id\n  financialAssets {\n    id\n    description\n    type\n    amount\n    cost\n    percentCash\n    rorCash\n    percentFixedIncome\n    rorFixedIncome\n    percentEquity\n    rorEquity\n  }\n}\n\nfragment NetCashFlowChartResult on Result {\n  retirementAge\n  steps {\n    age\n    year\n    expensesTotal\n    debtsPaidTotal\n    tax {\n      total\n    }\n    income {\n      name\n      amount\n    }\n    oas {\n      payment\n    }\n    cpp {\n      payment\n    }\n    financialAssetsContribTotal\n    financialAssets {\n      name\n      contribution\n      type\n    }\n    realAssets {\n      cash\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'b735bdecf6dfbc50a23d40c36f1138eb';
export default node;
