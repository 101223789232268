import {createContext} from "react";

export default createContext({
    scenario: {id: "", name: ""},
    setScenario: (scenario: {id: string, name: string}) => {},
    compare: [""],
    setCompare: (scenarioIds: string[]) => {},
    person: {id: ""},
    setPerson: (person: {id: string}) => {}
});
