import {graphql} from "babel-plugin-relay/macro";
import {useMutation} from "react-relay/hooks";
import {FinancialAssetType, useFinancialAssetUpdateMutation } from "./__generated__/useFinancialAssetUpdateMutation.graphql";
import {useCallback} from "react";
import {handleMutation} from "../HandleMutation";

const updateMutation = graphql`
    mutation useFinancialAssetUpdateMutation($scenarioId: ID!, $financialAssetInput: FinancialAssetInput!){
        financialAssetUpdate(scenarioId: $scenarioId, financialAssetInput: $financialAssetInput){
            assets {
                ...FinancialAssetsUpdate
            }
            result {
                ...ScenarioResultsDataResult
            }
            ...ScenarioDetailedSummary
            ...ScenarioHierarchicalMenu
        }
    }`;

export interface FinancialAssetInputType {
    id: string
    description: string
    type: FinancialAssetType
    amount: number
    cost: number
    percentCash: number
    rorCash: number
    percentFixedIncome: number
    rorFixedIncome: number
    percentEquity: number
    rorEquity: number
}

export default function useFinancialAssetUpdate():[(scenarioId: string, finAssetInput: FinancialAssetInputType) => void, boolean]{
    const [commit, committing] = useMutation<useFinancialAssetUpdateMutation>(updateMutation);
    const callback = useCallback((scenarioId: string, finAssetInput: FinancialAssetInputType) => {
        handleMutation(finAssetInput.id, finAssetInput.description, "financial asset", "update", commit,{
            variables: {
                scenarioId: scenarioId,
                financialAssetInput: {
                    id: finAssetInput.id,
                    description: finAssetInput.description,
                    type: finAssetInput.type,
                    amount: finAssetInput.amount,
                    cost: finAssetInput.cost,
                    percentCash: finAssetInput.percentCash,
                    rorCash: finAssetInput.rorCash,
                    percentFixedIncome: finAssetInput.percentFixedIncome,
                    rorFixedIncome: finAssetInput.rorFixedIncome,
                    percentEquity: finAssetInput.percentEquity,
                    rorEquity: finAssetInput.rorEquity,
                }
            }
        });
    }, [commit])

    return [callback, committing];
}