import {useLazyLoadQuery} from "react-relay/hooks";
import {HomePersonQuery} from "./__generated__/HomePersonQuery.graphql";
import {graphql} from "babel-plugin-relay/macro";
import React, {useContext} from "react";
import {Button, Card, Col, Divider, Layout, Row, Space, Tree, Typography} from 'antd';
import useScenarioAdd from "./Mutations/useScenarioAdd";
import AppContext from "../AppContext";

const {Content} = Layout;
const {Title} = Typography;

const query =  graphql`
    query HomePersonQuery {
        me {
            person {
                id
                firstName
                lastName
                scenarios {
                    id
                    startYear
                    description
                }
            }
        }
    }
`;

export default function Home() {
    const app = useContext(AppContext);
    const data = useLazyLoadQuery<HomePersonQuery>(query, {});
    const [commitAdd] = useScenarioAdd();

    return (
        <Layout>
            <Content style={{overflowY: "scroll"}}>
                <Row>
                    <Col span={24}>
                        <Card
                            title={<Title>Plans</Title>}
                            extra={
                                <Space>
                                    <Button type={"primary"} onClick={() => commitAdd(data.me.person.id, null, "New plan", () => {})}>New plan</Button>
                                    <Button type={"default"}>Simulate</Button>
                                    <Button type={"default"}>Compare</Button>
                                    <Divider type={"vertical"} />
                                    <Button type={"default"}>Archive</Button>
                                    <Button type={"default"} danger>Delete</Button>
                                </Space>
                            }
                        >
                            <Tree checkable
                                  onSelect={(selectedKeys, info) => app.setScenario({id: selectedKeys[0] as string, name: info.node.title as string})}
                                  treeData={data.me.person.scenarios.map(x => {return {title: x.description, key: x.id}})} />
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
};