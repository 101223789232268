import React, {ReactChild, useContext, useEffect, useState} from "react";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay/hooks";
import {Button, Space, Typography} from 'antd';
import {ShopOutlined} from '@ant-design/icons'
import moment from "moment";
import AppContext from "../../../AppContext";
import ExpensesGrid, {Expense} from "./ExpensesGrid";
import {ExpensesUpdate$key} from "./__generated__/ExpensesUpdate.graphql";
import EditorLayout from "../../EditorLayout";
import useExpenseUpdate from "../../Mutations/useExpenseUpdate";
import useExpenseRemove from "../../Mutations/useExpenseRemove";
import useExpenseUpdateOrdinals from "../../Mutations/useExpenseUpdateOrdinals";

const {Text} = Typography;

const query =  graphql`
    fragment ExpensesUpdate on Expenses {
        id
        expenseSources {
            id
            description
            amount
            inflation
            start {
                type
                date
            }
            end {
                type
                date
            }
        }
    }
`;


export interface ExpensesUpdateProps {
    income: ExpensesUpdate$key
    chart: ReactChild
}

export default function ExpensesUpdate (props: ExpensesUpdateProps) {
    const app = useContext(AppContext);
    const data = useFragment(query, props.income);

    const [expensesList, setExpensesList] = useState<Expense[]>([]);

    const [commitUpdate, committingUpdate] = useExpenseUpdate();
    const [commitRemove, committingRemove] = useExpenseRemove();
    const [commitUpdateOrdinals, committingUpdateOrdinals] = useExpenseUpdateOrdinals();

    useEffect(()=>{
        setExpensesList(data.expenseSources.map(x => {
            return {
                id: x.id,
                description: x.description,
                amount: x.amount,
                inflation: x.inflation,
                startType: x.start.type === "None" ? "Now" : x.start.type,
                startDate: x.start.date,
                endType: x.end.type === "None" ? "Never" : x.end.type,
                endDate: x.end.date,
                onRemove: () => commitRemove(app.scenario.id, x.id, x.description)
            }
        }));
    }, [data, commitRemove, app.scenario.id])

    const handleOnAdd = () => {
        const description = "Expense #" + (data.expenseSources.length+1);
        commitUpdate(app.scenario.id, {
            id: "00000000-0000-0000-0000-000000000000",
            description: description,
            amount: 0,
            inflation: 2,
            startType: "Now",
            startDate: moment().format("yyyy-MM-DD"),
            endType: "Never",
            endDate: moment().format("yyyy-MM-DD")
        })
    };

    const handleOnUpdate = (expense: Expense) => {
        commitUpdate(app.scenario.id, {
            id: expense.id,
            description: expense.description,
            amount: expense.amount,
            inflation: expense.inflation,
            startType: expense.startType,
            startDate: expense.startDate,
            endType: expense.endType,
            endDate: expense.endDate
        })
        return true;
    };

    const handleOnUpdateOrdinals = (order: string[]) => {
        commitUpdateOrdinals(app.scenario.id, data.id, order);
    };

    return (
        <EditorLayout
            controls={
                <Space align="center">
                    <Button onClick={handleOnAdd} type={"primary"} disabled={committingUpdate || committingRemove || committingUpdateOrdinals} ><ShopOutlined /> Add Expense</Button>
                    <Text type={"secondary"}>Note: Debt payments will be automatically included in cash flow calculations.</Text>
                </Space>
            }
            editor={<ExpensesGrid data={expensesList} update={handleOnUpdate} updateOrdinals={handleOnUpdateOrdinals} />}
            chart={props.chart} />
    )
};