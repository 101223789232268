import {Menu} from "antd";
import {Link, useLocation} from "react-router-dom";
import React from "react";
import {
    CreditCardOutlined,
    DollarOutlined,
    RiseOutlined,
    ShopOutlined,
    DashboardOutlined,
    HomeOutlined
} from '@ant-design/icons'
import {UserConfig, useUserConfig} from "../UserConfigContext";
import {SelectEventHandler} from "rc-menu/es/interface";

export default function ScenarioEditorMenu () {
    const [userConfig, setUserConfig] = useUserConfig();
    const location = useLocation();

    const handleOnSelect:SelectEventHandler = info => {
        const newUserConfig:UserConfig = {
            ...userConfig,
            details: {
                selectedTab: info.selectedKeys?.length === 1 ? info.selectedKeys[0].toString() : ""
            }
        };
        setUserConfig(newUserConfig);
    }

    return (<Menu style={{marginBottom: "4px"}} mode={"horizontal"} theme={"light"} selectedKeys={[location.pathname as string]} onSelect={handleOnSelect} >
        <Menu.Item key="/scenario/edit" icon={<DashboardOutlined />}>
            <Link to="/scenario/edit">Overview</Link>
        </Menu.Item>
        <Menu.Item key="/scenario/edit/income" icon={<DollarOutlined/>}>
            <Link to="/scenario/edit/income">Income</Link>
        </Menu.Item>
        <Menu.Item key="/scenario/edit/expenses" icon={<ShopOutlined/>}>
            <Link to="/scenario/edit/expenses">Expenses</Link>
        </Menu.Item>
        <Menu.Item key="/scenario/edit/assets-financial" icon={<RiseOutlined/>}>
            <Link to="/scenario/edit/assets-financial">Investing</Link>
        </Menu.Item>
        <Menu.Item key="/scenario/edit/assets-real" icon={<HomeOutlined />}>
            <Link to="/scenario/edit/assets-real">Property</Link>
        </Menu.Item>
        <Menu.Item key="/scenario/edit/debts" icon={<CreditCardOutlined />}>
            <Link to="/scenario/edit/debts">Debts</Link>
        </Menu.Item>
    </Menu>)
}