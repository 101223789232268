import {useCallback} from "react";
import {graphql} from "babel-plugin-relay/macro";
import {useMutation} from "react-relay/hooks";
import {useIncomeRemoveMutation} from "./__generated__/useIncomeRemoveMutation.graphql";
import {handleMutation} from "../HandleMutation";

const removeMutation = graphql`
    mutation useIncomeRemoveMutation($scenarioId: ID!, $incomeSourceId: String!){
        incomeSourceRemove(scenarioId: $scenarioId, incomeSourceId: $incomeSourceId){
            income {
                ...IncomeUpdate
            }
            result {
                ...ScenarioResultsDataResult
            }
            ...ScenarioDetailedSummary
            ...ScenarioHierarchicalMenu
        }
    }`;

export default function useIncomeRemove():[(scenarioId: string, id: string, description: string) => void, boolean]{
    const [commitRemove, committing] = useMutation<useIncomeRemoveMutation>(removeMutation);

    const mutation = useCallback(  (scenarioId: string, id: string, description: string) => {
        return handleMutation(id, description, "income", "remove", commitRemove, {
            variables: {scenarioId: scenarioId, incomeSourceId: id}
        })
    }, [commitRemove]);

    return [mutation, committing]
}