import {graphql} from "babel-plugin-relay/macro";
import {useMutation} from "react-relay";
import {useCallback} from "react";
import {handleMutation} from "../HandleMutation";
import { useRealAssetRemoveMutation } from "./__generated__/useRealAssetRemoveMutation.graphql";

const removeMutation = graphql`
    mutation useRealAssetRemoveMutation($scenarioId: ID!, $realAssetId: String!){
        realAssetRemove(scenarioId: $scenarioId, realAssetId: $realAssetId){
            assets {
                ...RealAssetsUpdate
            }
            result {
                ...ScenarioResultsDataResult
            }
            ...ScenarioDetailedSummary
            ...ScenarioHierarchicalMenu
        }
    }`;

export default function useRealAssetRemove():[(scenarioId: string, id: string, description:string) => void, boolean]{
    const [commit, committing] = useMutation<useRealAssetRemoveMutation>(removeMutation);
    const callback = useCallback((scenarioId: string, id: string, description:string) => {
        handleMutation(id, description, "financial asset", "remove", commit, {
            variables: {scenarioId: scenarioId, realAssetId: id}
        })
    }, [commit]);

    return [callback, committing];
}