import {graphql} from "babel-plugin-relay/macro";
import {useMutation} from "react-relay/hooks";
import {useExpenseUpdateMutation} from "./__generated__/useExpenseUpdateMutation.graphql";
import {handleMutation} from "../HandleMutation";
import {useCallback} from "react";

const updateMutation = graphql`
    mutation useExpenseUpdateMutation($scenarioId: ID!, $expenseSourceInput: ExpenseSourceInput!){
        expenseSourceUpdate(scenarioId: $scenarioId, expenseSourceInput: $expenseSourceInput){
            expenses {
                ...ExpensesUpdate
            }
            result {
                ...ScenarioResultsDataResult
            }
            ...ScenarioDetailedSummary
            ...ScenarioHierarchicalMenu
        }
    }`;

export interface ExpenseInputType {
    id: string
    description: string
    amount: number
    inflation: number
    startType: string
    startDate: unknown
    endType: string
    endDate: unknown
}

export default function useExpenseUpdate():[(scenarioId: string, expenseInput: ExpenseInputType) => void, boolean] {
    const [commit, committing] = useMutation<useExpenseUpdateMutation>(updateMutation);
    const callback = useCallback((scenarioId: string, expenseInput: ExpenseInputType) => {
        console.log(expenseInput)
        handleMutation(expenseInput.id, expenseInput.description, "expense", "update", commit, {
            variables: {
                scenarioId: scenarioId,
                expenseSourceInput: {
                    id: expenseInput.id,
                    description: expenseInput.description,
                    amount: expenseInput.amount,
                    inflation: expenseInput.inflation,
                    start: {
                        type: expenseInput.startType === "Now" ? "None" : expenseInput.startType === "Retirement" ? "Retirement" : "Date",
                        date: expenseInput.startDate !== null && expenseInput.startDate !== undefined ? expenseInput.startDate : null
                    },
                    end: {
                        type: expenseInput.endType === "Never" ? "None" : expenseInput.endType === "Retirement" ? "Retirement" : "Date",
                        date: expenseInput.endDate !== null && expenseInput.endDate !== undefined ? expenseInput.endDate : null
                    },
                }
            }
        })
    }, [commit])

    return [callback, committing];
}