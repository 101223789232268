import {useCallback} from "react";
import {graphql} from "babel-plugin-relay/macro";
import {useMutation} from "react-relay/hooks";
import {usePersonUpdateMutation} from "./__generated__/usePersonUpdateMutation.graphql";
import {handleMutation} from "../HandleMutation";

const mutation = graphql`
    mutation usePersonUpdateMutation($personInput: PersonInput!, $scenarioId: ID!){
        personUpdate(personInput: $personInput){
            id
            firstName
            lastName
            dob
            province {
                id
            }
            scenarios(ids: [$scenarioId]) {
                result {
                    ...ScenarioResultsDataResult
                }
                ...ScenarioDetailedSummary
            }
        }
    }`;

export default function usePersonUpdate(): [(scenarioId: string, personInput: {id: string, dob: string, firstName: string, lastName: string, provinceId: string}, onCompleted: () => void) => void, boolean]{
    const [commit, committing] = useMutation<usePersonUpdateMutation>(mutation);
    const callback = useCallback((scenarioId: string, personInput: {id: string, dob: string, firstName: string, lastName: string, provinceId: string}, onCompleted: () => void) => {
        handleMutation("", "Update person", "person", "update", commit, {
            variables: {
                personInput: {
                    id: personInput.id,
                    dob: personInput.dob,
                    firstName: personInput.firstName,
                    lastName: personInput.lastName,
                    provinceId: personInput.provinceId
                },
                scenarioId: scenarioId
            },
            onCompleted: () => {
                onCompleted();
            }
        })
    }, [commit]);

    return [callback, committing];
}