import React from "react";
import {TooltipCompare} from "../ScenarioEditor/Charts/Tooltip";
import {ChartTheme} from "../ScenarioEditor/Charts/ChartTheme";
import {Chart} from "../Chart/Chart";
import ChartSubject from "../Chart/ChartSubject";

const chartSubject = new ChartSubject();

export default function CompareChart(props: {dataSource: any[][], title: string, retirementAge: number[], type: string | undefined, dataType: "none" | "dollar" | "percent", showLegend?: boolean}) {
    if(props.dataSource.length === 0)
        return null;

    const maxYValue = Math.max(...props.dataSource.slice(2).map(x => x.slice(1)).flat());
    let minYValue = Math.min(...props.dataSource.slice(2).map(x => x.slice(1)).flat());
    minYValue = minYValue > 0 ? 0 : minYValue;

    const option = {
        ...ChartTheme,
        title: {
            text: props.title
        },
        legend: {
            show: props.showLegend ?? true,
            top: "bottom"
        },
        backgroundColor: "#FFFFFF",
        grid: [
            {
                left: '2%',
                right: '90px',
            }
        ],
        animation: true,
        xAxis: [
            {
                type: 'category',
                data: props.dataSource[0],
                axisTick: {
                    alignWithLabel: true
                },
            },
            {
                type: 'category',
                data: props.dataSource[1],
                axisTick: {
                    alignWithLabel: true
                },
            }
        ],
        tooltip: {...TooltipCompare(props.dataSource.slice(0, 2), props.dataType)},
        yAxis: [
            {
                scale: true,
                gridIndex: 0,
                position: "right",
                type: "value",
                splitNumber: 5,
                min: minYValue,
                max: maxYValue,
                name: (props.dataType === "dollar" ? "$" : "%"),
                nameLocation: "center",
                nameTextStyle: {
                    padding: 55
                }
            },
        ],
        series: props.dataSource.map((x, i) => {
            if( i <= 1)
                return null;
            const retirementIndex = props.retirementAge[i-2] - props.dataSource[0][0];

            return {
                type: props.type ?? "line",
                symbol: "none",
                lineStyle: {
                    width: i === 2 ? 3 : 2,
                    type: i === 2 ? "solid" : "dashed",
                },
                seriesLayoutBy: "row",
                name: props.dataSource[i][0],
                data: props.dataSource[i].slice(1),
                markLine: {
                    lineStyle: {
                        type: "dotted",
                        width: 2
                    },
                    data: [[{
                        //name: props.retirementAge[i - 2],
                        symbol: 'none',
                        coord: [
                            retirementIndex,
                            maxYValue
                        ]
                    }, {
                        symbol: 'none',
                        coord: [retirementIndex, minYValue]
                    }
                    ]]
                }
            }
        })
    };

    return <Chart style={{height: "100%"}} option={option} subject={chartSubject} />
}