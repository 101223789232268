import React, {ReactChild} from "react";

const EditorLayout = (props: {controls?: ReactChild, editor: ReactChild, chart?: ReactChild}) => {
    return (
        <div style={{height: "100%", display: "flex", flexFlow: "column"}}>
            {props.controls ?
                <div style={{flex: "0 0 32px"}}>
                    {props.controls}
                </div>
                : null
            }
            <div style={{flex: "1 0 200px"}}>
                {props.editor}
            </div>
            {props.chart !== undefined ?
                <div style={{flex: "1 0 50%"}}>
                    {props.chart}
                </div>
                : null
            }
        </div>
    )
}

export default EditorLayout;