import {useCallback, useContext} from "react";
import {graphql} from "babel-plugin-relay/macro";
import {useMutation} from "react-relay/hooks";
import {handleMutation} from "../HandleMutation";
import {useScenarioRemoveMutation} from "./__generated__/useScenarioRemoveMutation.graphql";
import AppContext from "../../AppContext";

const removeScenarioMutation = graphql`
    mutation useScenarioRemoveMutation($scenarioId: ID!){
        scenarioRemove(scenarioId: $scenarioId){
            scenarios {
                id
                description
            }
        }
    }`;

export default function useScenarioRemove() {
    const app = useContext(AppContext);
    const [commit] = useMutation<useScenarioRemoveMutation>(removeScenarioMutation)
    return useCallback((id: string, description: string) => {
        handleMutation(id, description, "scenario", "remove", commit, {
            variables: {
                scenarioId: id
            },
            onCompleted: response => {
                const length = response.scenarioRemove.scenarios.length;
                const scenario = response.scenarioRemove.scenarios[length - 1];
                const empty = {id: "00000000-0000-0000-0000-000000000000", name: "select a plan"};

                app.setScenario(scenario ? {id: scenario.id, name: scenario.description} : empty);
            }
        })
    }, [commit, app]);
}