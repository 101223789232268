import React, {useEffect, useState} from "react";
import {AgGridReact} from "ag-grid-react";
import {GridReadyEvent} from "ag-grid-community/dist/lib/events";
import {GridApi, ICellRendererParams, ValueSetterParams} from "ag-grid-community";
import {columnTypes, columnTypesComponents} from "../Grids/ColumnTypes";
import {DeleteColumnDef, TimingColumnDefs} from "../Grids/ColumnDefs";
import {updateIfDiff, onDragStopped} from "../Grids/Utilities";

export interface Income {
    id: string
    description: string
    amount: number
    annualIncrease: number
    type: "CapitalGains" | "EligibleDividends" | "Employment" | "IneligibleDividends" | "NonTaxable" | "Other" | "SelfEmployment" | "%future added value"
    startType: string
    startDate: any
    endType: string
    endDate: any
    onRemove: () => void
}

export default function IncomeGrid (props: {
    data: Income[]
    update: (income: Income) => boolean
    updateOrdinals: (ids: string[]) => void
}) {
    const [gridApi, setGridApi] = useState<GridApi>();

    function onGridReady(event: GridReadyEvent) {
        setGridApi(event.api);
    }

    const [gridState] = useState({
        columnDefs: [
            {   headerName: "Description",
                field: "description",
                editable: true,
                rowDrag: true,
                valueSetter:  (params:ValueSetterParams) => props.update({...params.data, description: params.newValue})
            },
            {   headerName: "Amount",
                field: "amount",
                type: ['currencyColumn','importantColumn'],
                editable: true,
                valueSetter:  (params:ValueSetterParams) => props.update({...params.data, amount: params.newValue ?? 0})
            },
            {   headerName: "Annual increase",
                width: 145,
                field: "annualIncrease",
                type: ['percentageColumn','importantColumn'],
                editable: true,
                valueSetter: (params:ValueSetterParams) => updateIfDiff<Income>(params, props.update, {annualIncrease: params.newValue ?? 0})
            },
            {   headerName: "Type",
                field: "type",
                type: "selectColumn",
                editable: true,
                width: 130,
                cellRenderer: (params: ICellRendererParams) => params.data.type.replace(/([A-Z])/g, ' $1'),
                valueSetter: (params: ValueSetterParams) =>  updateIfDiff<Income>(params, props.update, {type: params.newValue}),
                cellEditorParams: {values: [
                        { key: "Employment", value: "Employment" },
                        { key: "SelfEmployment", value: "Self Employment" },
                        // { key: "CapitalGains", value: "Capital Gains" },
                        { key: "EligibleDividends", value: "Eligible Dividends" },
                        { key: "IneligibleDividends", value: "Ineligible Dividends" },
                        { key: "Other", value: "Other" },
                        { key: "NonTaxable", value: "Non Taxable" },
                    ]},
            },
            ...TimingColumnDefs<Income>(props.update),
            DeleteColumnDef(),
        ],
        columnTypes: columnTypes,
        components: columnTypesComponents,
        defaultColDef: {
            resizable: true,
        },
    });

    useEffect(()=>{
        gridApi?.setRowData(props.data)
    }, [gridApi, props.data])

    return <div className="ag-theme-material" style={{height: "100%", overflow: "hidden"}}>
        <AgGridReact
            stopEditingWhenGridLosesFocus={true}
            rowData={props.data}
            singleClickEdit={true}
            onDragStopped={event => onDragStopped(event, props.updateOrdinals)}
            suppressMoveWhenRowDragging={true}
            rowDragManaged={true}
            onGridReady={onGridReady}
            frameworkComponents={gridState.components}
            columnDefs={gridState.columnDefs}
            columnTypes={gridState.columnTypes}
            defaultColDef={gridState.defaultColDef}
            suppressNoRowsOverlay={true}
        />
    </div>
}