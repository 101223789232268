/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ScenarioEditorQuery = {
    readonly id: string;
    readonly description: string;
    readonly income: {
        readonly id: string;
        readonly " $fragmentRefs": FragmentRefs<"IncomeUpdate">;
    };
    readonly expenses: {
        readonly id: string;
        readonly " $fragmentRefs": FragmentRefs<"ExpensesUpdate">;
    };
    readonly assets: {
        readonly id: string;
        readonly " $fragmentRefs": FragmentRefs<"RealAssetsUpdate" | "FinancialAssetsUpdate">;
    };
    readonly debts: {
        readonly id: string;
        readonly " $fragmentRefs": FragmentRefs<"DebtsUpdate">;
    };
    readonly benefits: {
        readonly id: string;
        readonly " $fragmentRefs": FragmentRefs<"BenefitsUpdate">;
    };
    readonly result: {
        readonly id: string;
        readonly " $fragmentRefs": FragmentRefs<"ScenarioResultsDataResult" | "NetCashFlowChartResult" | "ExpensesChartResult" | "FinancialAssetsChartResult" | "RealAssetsChartResult" | "DebtsChartResult" | "EstateChartResult">;
    };
    readonly " $fragmentRefs": FragmentRefs<"ScenarioDetailedSummary" | "ScenarioSummary" | "ScenarioUpdate">;
    readonly " $refType": "ScenarioEditorQuery";
};
export type ScenarioEditorQuery$data = ScenarioEditorQuery;
export type ScenarioEditorQuery$key = {
    readonly " $data"?: ScenarioEditorQuery$data;
    readonly " $fragmentRefs": FragmentRefs<"ScenarioEditorQuery">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScenarioEditorQuery",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Income",
      "kind": "LinkedField",
      "name": "income",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "IncomeUpdate"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Expenses",
      "kind": "LinkedField",
      "name": "expenses",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ExpensesUpdate"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Assets",
      "kind": "LinkedField",
      "name": "assets",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RealAssetsUpdate"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FinancialAssetsUpdate"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Debts",
      "kind": "LinkedField",
      "name": "debts",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DebtsUpdate"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Benefits",
      "kind": "LinkedField",
      "name": "benefits",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BenefitsUpdate"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Result",
      "kind": "LinkedField",
      "name": "result",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ScenarioResultsDataResult"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "NetCashFlowChartResult"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ExpensesChartResult"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FinancialAssetsChartResult"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RealAssetsChartResult"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DebtsChartResult"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EstateChartResult"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ScenarioDetailedSummary"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ScenarioSummary"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ScenarioUpdate"
    }
  ],
  "type": "Scenario",
  "abstractKey": null
};
})();
(node as any).hash = 'ea924d493973b14e4e50037a42a962f7';
export default node;
