import {graphql} from "babel-plugin-relay/macro";
import {useMutation} from "react-relay/hooks";
import {useExpenseRemoveMutation} from "./__generated__/useExpenseRemoveMutation.graphql";
import {useCallback} from "react";
import {handleMutation} from "../HandleMutation";

const removeMutation = graphql`
    mutation useExpenseRemoveMutation($scenarioId: ID!, $expenseSourceId: String!){
        expenseSourceRemove(scenarioId: $scenarioId, expenseSourceId: $expenseSourceId) {
            expenses {
                ...ExpensesUpdate
            }
            result {
                ...ScenarioResultsDataResult
            }
            ...ScenarioDetailedSummary
            ...ScenarioHierarchicalMenu
        }
    }`;

export default function useExpenseRemove():[(scenarioId: string, id: string, description: string) => void , boolean]{
    const [commit, committing] = useMutation<useExpenseRemoveMutation>(removeMutation);
    const callback = useCallback((scenarioId: string, id: string, description: string) => {
        handleMutation(id, description, "expense", "remove", commit, {
            variables: {scenarioId: scenarioId, expenseSourceId: id}
        })
    }, [commit]);

    return [callback, committing];
}