/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CompareDataProviderQuery = {
    readonly me: {
        readonly person: {
            readonly compare: ReadonlyArray<{
                readonly id: string;
                readonly description: string;
                readonly result: {
                    readonly id: string;
                    readonly retirementAge: number;
                    readonly steps: ReadonlyArray<{
                        readonly age: number;
                        readonly year: number;
                        readonly incomeTotal: number;
                        readonly expensesTotal: number;
                        readonly estate: {
                            readonly beforeTax: number;
                            readonly afterTax: number;
                            readonly afterTaxReal: number;
                            readonly taxRate: number;
                            readonly tax: number;
                        };
                        readonly tax: {
                            readonly marginalRate: number;
                        };
                        readonly financialAssetsTotal: number;
                        readonly financialAssetsContribTotal: number;
                        readonly savingsRate: number;
                        readonly dtiRatio: number;
                        readonly withdrawalRate: number;
                    }>;
                };
            }>;
        };
    };
    readonly " $refType": "CompareDataProviderQuery";
};
export type CompareDataProviderQuery$data = CompareDataProviderQuery;
export type CompareDataProviderQuery$key = {
    readonly " $data"?: CompareDataProviderQuery$data;
    readonly " $fragmentRefs": FragmentRefs<"CompareDataProviderQuery">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "scenarioId"
    },
    {
      "kind": "RootArgument",
      "name": "scenarioIds"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./CompareDataProviderRefetchQuery.graphql.ts')
    }
  },
  "name": "CompareDataProviderQuery",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "me",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Person",
          "kind": "LinkedField",
          "name": "person",
          "plural": false,
          "selections": [
            {
              "alias": "compare",
              "args": [
                {
                  "kind": "Variable",
                  "name": "ids",
                  "variableName": "scenarioIds"
                }
              ],
              "concreteType": "Scenario",
              "kind": "LinkedField",
              "name": "scenarios",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Result",
                  "kind": "LinkedField",
                  "name": "result",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "retirementAge",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ResultStep",
                      "kind": "LinkedField",
                      "name": "steps",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "age",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "year",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "incomeTotal",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "expensesTotal",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "EstateResult",
                          "kind": "LinkedField",
                          "name": "estate",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "beforeTax",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "afterTax",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "afterTaxReal",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "taxRate",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "tax",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "TaxResult",
                          "kind": "LinkedField",
                          "name": "tax",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "marginalRate",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "financialAssetsTotal",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "financialAssetsContribTotal",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "savingsRate",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "dtiRatio",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "withdrawalRate",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
(node as any).hash = 'b56f6941211a796de0dfaab970134855';
export default node;
