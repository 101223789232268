/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type PersonUpdateQueryVariables = {};
export type PersonUpdateQueryResponse = {
    readonly me: {
        readonly person: {
            readonly id: string;
            readonly firstName: string;
            readonly lastName: string;
            readonly dob: unknown;
            readonly province: {
                readonly id: string;
            };
        };
    };
    readonly provinces: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
    }>;
};
export type PersonUpdateQuery = {
    readonly response: PersonUpdateQueryResponse;
    readonly variables: PersonUpdateQueryVariables;
};



/*
query PersonUpdateQuery {
  me {
    person {
      id
      firstName
      lastName
      dob
      province {
        id
      }
    }
    id
  }
  provinces {
    id
    name
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Person",
  "kind": "LinkedField",
  "name": "person",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dob",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Province",
      "kind": "LinkedField",
      "name": "province",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Province",
  "kind": "LinkedField",
  "name": "provinces",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PersonUpdateQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      (v2/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PersonUpdateQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      (v2/*: any*/)
    ]
  },
  "params": {
    "cacheID": "15a5127c29a0b8684515ac0ce73d4619",
    "id": null,
    "metadata": {},
    "name": "PersonUpdateQuery",
    "operationKind": "query",
    "text": "query PersonUpdateQuery {\n  me {\n    person {\n      id\n      firstName\n      lastName\n      dob\n      province {\n        id\n      }\n    }\n    id\n  }\n  provinces {\n    id\n    name\n  }\n}\n"
  }
};
})();
(node as any).hash = '2f95a21fff5940c6c3b3eb0e9bccbebf';
export default node;
