/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ForecastWorkspaceQueryVariables = {
    scenarioId: string;
};
export type ForecastWorkspaceQueryResponse = {
    readonly me: {
        readonly person: {
            readonly scenarios: ReadonlyArray<{
                readonly id: string;
                readonly description: string;
                readonly startYear: number;
                readonly result: {
                    readonly id: string;
                    readonly " $fragmentRefs": FragmentRefs<"ScenarioResultsDataResult">;
                };
            }>;
        };
    };
};
export type ForecastWorkspaceQuery = {
    readonly response: ForecastWorkspaceQueryResponse;
    readonly variables: ForecastWorkspaceQueryVariables;
};



/*
query ForecastWorkspaceQuery(
  $scenarioId: ID!
) {
  me {
    person {
      scenarios(ids: [$scenarioId]) {
        id
        description
        startYear
        result {
          id
          ...ScenarioResultsDataResult
        }
      }
      id
    }
    id
  }
}

fragment ScenarioResultsDataResult on Result {
  id
  retirementAge
  steps {
    year
    age
    expenses {
      id
      sourceRecordId
      name
      amount
      amountNom
      isOverride
    }
    income {
      name
      amount
    }
    cash
    financialAssets {
      name
      rate
      value
      contribution
    }
    realAssets {
      name
      rate
      value
    }
    debts {
      name
      owing
      paid
    }
    estate {
      beforeTax
      afterTax
      afterTaxReal
      tax
      taxRate
    }
    tax {
      marginalRate
      effectiveRate
      provincialTax
      federalTax
      total
      cpp
      ei
    }
    oas {
      payment
      recovery
    }
    cpp {
      payment
    }
    expensesTotal
    incomeTotal
    cppOasTotal
    debtsOwingTotal
    debtsPaidTotal
    financialAssetsTotal
    financialAssetsContribTotal
    financialAssetsRorTotal
    realAssetsTotal
    savingsRate
    withdrawalRate
    dtiRatio
    tfsaRoom
    rrspRoom
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "scenarioId"
  }
],
v1 = [
  {
    "items": [
      {
        "kind": "Variable",
        "name": "ids.0",
        "variableName": "scenarioId"
      }
    ],
    "kind": "ListValue",
    "name": "ids"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startYear",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rate",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "payment",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ForecastWorkspaceQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "Scenario",
                "kind": "LinkedField",
                "name": "scenarios",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Result",
                    "kind": "LinkedField",
                    "name": "result",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "ScenarioResultsDataResult"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ForecastWorkspaceQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "Scenario",
                "kind": "LinkedField",
                "name": "scenarios",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Result",
                    "kind": "LinkedField",
                    "name": "result",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "retirementAge",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ResultStep",
                        "kind": "LinkedField",
                        "name": "steps",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "year",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "age",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ExpenseResult",
                            "kind": "LinkedField",
                            "name": "expenses",
                            "plural": true,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "sourceRecordId",
                                "storageKey": null
                              },
                              (v5/*: any*/),
                              (v6/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "amountNom",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isOverride",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "IncomeResult",
                            "kind": "LinkedField",
                            "name": "income",
                            "plural": true,
                            "selections": [
                              (v5/*: any*/),
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cash",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AssetResult",
                            "kind": "LinkedField",
                            "name": "financialAssets",
                            "plural": true,
                            "selections": [
                              (v5/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "contribution",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "RealAssetResult",
                            "kind": "LinkedField",
                            "name": "realAssets",
                            "plural": true,
                            "selections": [
                              (v5/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "DebtResult",
                            "kind": "LinkedField",
                            "name": "debts",
                            "plural": true,
                            "selections": [
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "owing",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "paid",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EstateResult",
                            "kind": "LinkedField",
                            "name": "estate",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "beforeTax",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "afterTax",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "afterTaxReal",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "tax",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "taxRate",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TaxResult",
                            "kind": "LinkedField",
                            "name": "tax",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "marginalRate",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "effectiveRate",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "provincialTax",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "federalTax",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "total",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "cpp",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "ei",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OASResults",
                            "kind": "LinkedField",
                            "name": "oas",
                            "plural": false,
                            "selections": [
                              (v9/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "recovery",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CPPResult",
                            "kind": "LinkedField",
                            "name": "cpp",
                            "plural": false,
                            "selections": [
                              (v9/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "expensesTotal",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "incomeTotal",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cppOasTotal",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "debtsOwingTotal",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "debtsPaidTotal",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "financialAssetsTotal",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "financialAssetsContribTotal",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "financialAssetsRorTotal",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "realAssetsTotal",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "savingsRate",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "withdrawalRate",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "dtiRatio",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "tfsaRoom",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "rrspRoom",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5fc37bc9b03c70206f4d92fdff9bdead",
    "id": null,
    "metadata": {},
    "name": "ForecastWorkspaceQuery",
    "operationKind": "query",
    "text": "query ForecastWorkspaceQuery(\n  $scenarioId: ID!\n) {\n  me {\n    person {\n      scenarios(ids: [$scenarioId]) {\n        id\n        description\n        startYear\n        result {\n          id\n          ...ScenarioResultsDataResult\n        }\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment ScenarioResultsDataResult on Result {\n  id\n  retirementAge\n  steps {\n    year\n    age\n    expenses {\n      id\n      sourceRecordId\n      name\n      amount\n      amountNom\n      isOverride\n    }\n    income {\n      name\n      amount\n    }\n    cash\n    financialAssets {\n      name\n      rate\n      value\n      contribution\n    }\n    realAssets {\n      name\n      rate\n      value\n    }\n    debts {\n      name\n      owing\n      paid\n    }\n    estate {\n      beforeTax\n      afterTax\n      afterTaxReal\n      tax\n      taxRate\n    }\n    tax {\n      marginalRate\n      effectiveRate\n      provincialTax\n      federalTax\n      total\n      cpp\n      ei\n    }\n    oas {\n      payment\n      recovery\n    }\n    cpp {\n      payment\n    }\n    expensesTotal\n    incomeTotal\n    cppOasTotal\n    debtsOwingTotal\n    debtsPaidTotal\n    financialAssetsTotal\n    financialAssetsContribTotal\n    financialAssetsRorTotal\n    realAssetsTotal\n    savingsRate\n    withdrawalRate\n    dtiRatio\n    tfsaRoom\n    rrspRoom\n  }\n}\n"
  }
};
})();
(node as any).hash = '2a82c2993021bd4fba560819b0e08952';
export default node;
