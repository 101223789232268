import React, {ReactChild} from "react";
import {Link, useLocation} from "react-router-dom";
import {Menu} from "antd";
import {
    DeploymentUnitOutlined,
    BlockOutlined,
    TableOutlined
} from '@ant-design/icons'
import {useUserConfig} from "./Components/UserConfigContext";

export function AppMenu(props: {children?: ReactChild}) {
    const location = useLocation();
    const [userConfig] = useUserConfig();
    const selectedLocation = `${location.pathname.split("/")[1]}/${location.pathname.split("/")[2] ?? ""}`;
    const calcSelectedLocation = selectedLocation === "" ? "summary" : selectedLocation;

    return (
            <Menu mode="horizontal" style={{backgroundColor: "#fafafa"}} theme={"light"} selectedKeys={[calcSelectedLocation]}>
                <Menu.Item key="scenario/edit" icon={<DeploymentUnitOutlined />}>
                    <Link to={userConfig.details.selectedTab}>Plan</Link>
                </Menu.Item>
                <Menu.Item key="scenario/forecast" icon={<TableOutlined />}>
                    <Link to="/scenario/forecast">Projection</Link>
                </Menu.Item>
                <Menu.Item key="scenario/compare" icon={<BlockOutlined />}>
                    <Link to="/scenario/compare">Compare</Link>
                </Menu.Item>
                {props.children ?
                <Menu.Item>
                    {props.children}
                </Menu.Item>
                    : null}
            </Menu>
    );
}