import {graphql} from "babel-plugin-relay/macro";
import {useMutation} from "react-relay";
import {
    useBenefitsUpdateMutation,
    useBenefitsUpdateMutationResponse
} from "./__generated__/useBenefitsUpdateMutation.graphql";
import {useCallback} from "react";
import {handleMutation} from "../HandleMutation";

const updateBenefitsMutation = graphql`
    mutation useBenefitsUpdateMutation($scenarioId: ID!, $rrspRoom: Int!, $tfsaRoom: Int!, $cppAge: Int!, $oasYearsInCanada: Int!, $oasMonthsDelayed: Int!) {
        benefitsUpdate(scenarioId: $scenarioId, rrspRoom: $rrspRoom, tfsaRoom: $tfsaRoom, cppAge: $cppAge, oasYearsInCanada: $oasYearsInCanada, oasMonthsDelayed: $oasMonthsDelayed){
            benefits {
                ...BenefitsUpdate
            }
            result {
                ...ScenarioResultsDataResult
            }
            ...ScenarioDetailedSummary
            ...ScenarioHierarchicalMenu
        }
    }
`;

export interface BenefitsInputType {
    scenarioId: string
    rrspRoom: number
    tfsaRoom: number
    cppAge: number
    oasYearsInCanada: number
    oasMonthsDelayed: number
}

export default function useBenefitsUpdate():[(value: BenefitsInputType, onCompleted?: (response: useBenefitsUpdateMutationResponse) => void) => void, boolean]{
    const [commit, committing] = useMutation<useBenefitsUpdateMutation>(updateBenefitsMutation);
    const callback = useCallback((value: BenefitsInputType, onCompleted?: (response: useBenefitsUpdateMutationResponse) => void) => {
        handleMutation(`benefits-${value.scenarioId}`, "Update benefits","Benefits", "update", commit, {
            variables: {
                scenarioId: value.scenarioId,
                rrspRoom: value.rrspRoom,
                tfsaRoom: value.tfsaRoom,
                cppAge: value.cppAge,
                oasYearsInCanada: value.oasYearsInCanada,
                oasMonthsDelayed: value.oasMonthsDelayed
            },
            onCompleted: response => {
                if(onCompleted)
                    onCompleted(response);
            }
        })

    }, [commit])

    return [callback, committing]
}