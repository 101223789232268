import React from "react";
import {ColumnState} from "ag-grid-community/dist/lib/columnController/columnController";

export interface UserConfig {
    compare: {
        chartOptions: {[key: string]: {options: number}},
        dataZoom: {
            start: number, end: number, dataZoomId: string
        } | null
    },
    details: {
        selectedTab: string
    },
    forecast: {
        columnState: ColumnState[],
        columnGroupState: {groupId: string, open: boolean}[]
    },
    siders: {
        leftIsVisible: boolean,
        rightIsVisible: boolean,
    }
}

const defaultState:UserConfig = {
    compare: {
        chartOptions: {},
        dataZoom: null,
    },
    details: {
        selectedTab: `/scenario/edit`
    },
    forecast: {
        columnState: [],
        columnGroupState: []
    },
    siders: {
        leftIsVisible: true,
        rightIsVisible: false,
    }
};

const UserConfigContext = React.createContext<[UserConfig, (value: UserConfig) => void]>([defaultState, () => {}]);

function useUserConfig() {
    const context = React.useContext(UserConfigContext);
    if (!context) {
        throw new Error(`useUserConfig must be used within a UserConfigProvider`)
    }

    return context
}

function UserConfigProvider(props:any) {
    const [userConfig, setUserConfig] = React.useState<UserConfig>(defaultState);
    const value = React.useMemo(() => [userConfig, setUserConfig], [userConfig]);
    return <UserConfigContext.Provider value={value} {...props} />
}

export {UserConfigProvider, useUserConfig}