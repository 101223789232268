/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AssumptionsStepQueryVariables = {};
export type AssumptionsStepQueryResponse = {
    readonly me: {
        readonly person: {
            readonly id: string;
            readonly defaultScenario: {
                readonly assets: {
                    readonly " $fragmentRefs": FragmentRefs<"FinancialAssetsUpdate">;
                };
                readonly " $fragmentRefs": FragmentRefs<"ScenarioUpdate">;
            };
        };
    };
};
export type AssumptionsStepQuery = {
    readonly response: AssumptionsStepQueryResponse;
    readonly variables: AssumptionsStepQueryVariables;
};



/*
query AssumptionsStepQuery {
  me {
    person {
      id
      defaultScenario {
        ...ScenarioUpdate
        assets {
          ...FinancialAssetsUpdate
          id
        }
        id
      }
    }
    id
  }
}

fragment FinancialAssetsUpdate on Assets {
  id
  financialAssets {
    id
    description
    type
    amount
    cost
    percentCash
    rorCash
    percentFixedIncome
    rorFixedIncome
    percentEquity
    rorEquity
  }
}

fragment ScenarioUpdate on Scenario {
  id
  description
  startYear
  retirementAgeValue
  retirementAgeType
  inflationGeneralRate
  inflationCppRate
  inflationOasRate
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AssumptionsStepQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Scenario",
                "kind": "LinkedField",
                "name": "defaultScenario",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Assets",
                    "kind": "LinkedField",
                    "name": "assets",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "FinancialAssetsUpdate"
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ScenarioUpdate"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AssumptionsStepQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Scenario",
                "kind": "LinkedField",
                "name": "defaultScenario",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startYear",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "retirementAgeValue",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "retirementAgeType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "inflationGeneralRate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "inflationCppRate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "inflationOasRate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Assets",
                    "kind": "LinkedField",
                    "name": "assets",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "FinancialAsset",
                        "kind": "LinkedField",
                        "name": "financialAssets",
                        "plural": true,
                        "selections": [
                          (v0/*: any*/),
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "type",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "amount",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cost",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "percentCash",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "rorCash",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "percentFixedIncome",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "rorFixedIncome",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "percentEquity",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "rorEquity",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "99b202c0c7de77ca47cbbdf6548cadb4",
    "id": null,
    "metadata": {},
    "name": "AssumptionsStepQuery",
    "operationKind": "query",
    "text": "query AssumptionsStepQuery {\n  me {\n    person {\n      id\n      defaultScenario {\n        ...ScenarioUpdate\n        assets {\n          ...FinancialAssetsUpdate\n          id\n        }\n        id\n      }\n    }\n    id\n  }\n}\n\nfragment FinancialAssetsUpdate on Assets {\n  id\n  financialAssets {\n    id\n    description\n    type\n    amount\n    cost\n    percentCash\n    rorCash\n    percentFixedIncome\n    rorFixedIncome\n    percentEquity\n    rorEquity\n  }\n}\n\nfragment ScenarioUpdate on Scenario {\n  id\n  description\n  startYear\n  retirementAgeValue\n  retirementAgeType\n  inflationGeneralRate\n  inflationCppRate\n  inflationOasRate\n}\n"
  }
};
})();
(node as any).hash = 'effcdf205d91193fce2c0a745c567489';
export default node;
