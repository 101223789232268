import {graphql} from "babel-plugin-relay/macro";
import {useMutation} from "react-relay";
import {
    useScenarioUpdateMutation,
    useScenarioUpdateMutationResponse
} from "./__generated__/useScenarioUpdateMutation.graphql";
import {useCallback} from "react";
import {handleMutation} from "../HandleMutation";

const mutation = graphql`
    mutation useScenarioUpdateMutation($scenarioInput: ScenarioInput!){
        scenarioUpdate(scenarioInput: $scenarioInput){
            id
            description
            startYear
            retirementAgeValue
            retirementAgeType
            inflationGeneralRate
            inflationCppRate
            inflationOasRate
            benefits {
                ...BenefitsUpdate
            }
            result {
                ...ScenarioResultsDataResult
            }
            ...ScenarioDetailedSummary
        }
    }`;

export interface ScenarioInputType {
    id: string
    description: string
    startYear: number,
    retirementAgeValue: number,
    retirementAgeType: "Age" | "FinAssetValue" | "WithdrawalRate" | "%future added value",
    inflationGeneralRate: number,
    inflationOasRate: number
    inflationCppRate: number
}

export default function useScenarioUpdate():[(value: ScenarioInputType, onComplete?: (response: useScenarioUpdateMutationResponse) => void) => void, boolean]{
    const [commit, committing] = useMutation<useScenarioUpdateMutation>(mutation);
    const callback = useCallback((value: ScenarioInputType, onComplete?: (response: useScenarioUpdateMutationResponse) => void) => {
        handleMutation(value.id, value.description, "scenario", "update",
            commit,{
                variables: {scenarioInput: {
                        id: value.id,
                        description: value.description,
                        startYear: value.startYear,
                        retirementAgeValue: value.retirementAgeValue,
                        retirementAgeType: value.retirementAgeType,
                        inflationGeneralRate: value.inflationGeneralRate,
                        inflationCppRate: value.inflationCppRate,
                        inflationOasRate: value.inflationOasRate
                    }},
                onCompleted: response =>  {
                    if(onComplete)
                        onComplete(response);
                },
            });
    },[commit])

    return [callback, committing];
}