/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ScenarioTableSider = {
    readonly income: {
        readonly currentIncome: ReadonlyArray<{
            readonly id: string;
            readonly description: string;
            readonly amount: number;
        }>;
    };
    readonly expenses: {
        readonly currentExpenses: ReadonlyArray<{
            readonly id: string;
            readonly description: string;
            readonly amount: number;
        }>;
    };
    readonly assets: {
        readonly financialAssets: ReadonlyArray<{
            readonly id: string;
            readonly description: string;
            readonly amount: number;
        }>;
        readonly currentRealAssets: ReadonlyArray<{
            readonly id: string;
            readonly description: string;
            readonly currentValue: number;
        }>;
    };
    readonly debts: {
        readonly loans: ReadonlyArray<{
            readonly id: string;
            readonly description: string;
            readonly balance: number;
            readonly monthlyPayment: number;
        }>;
    };
    readonly benefits: {
        readonly rrspRoom: number;
        readonly tfsaRoom: number;
        readonly cppAge: number;
        readonly oasYearsInCanada: number;
        readonly oasMonthsDelayed: number;
    };
    readonly result: {
        readonly id: string;
        readonly retirementAge: number;
        readonly firstStep: {
            readonly tax: {
                readonly cpp: number;
                readonly ei: number;
                readonly provincialTax: number;
                readonly federalTax: number;
                readonly total: number;
            };
        };
        readonly retirementStep: {
            readonly financialAssetsTotal: number;
        };
        readonly lastStep: {
            readonly age: number;
            readonly estate: {
                readonly beforeTax: number;
                readonly afterTax: number;
                readonly afterTaxReal: number;
            };
        };
    };
    readonly " $refType": "ScenarioTableSider";
};
export type ScenarioTableSider$data = ScenarioTableSider;
export type ScenarioTableSider$key = {
    readonly " $data"?: ScenarioTableSider$data;
    readonly " $fragmentRefs": FragmentRefs<"ScenarioTableSider">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "type",
    "value": "None"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "amount",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScenarioTableSider",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Income",
      "kind": "LinkedField",
      "name": "income",
      "plural": false,
      "selections": [
        {
          "alias": "currentIncome",
          "args": (v0/*: any*/),
          "concreteType": "IncomeSource",
          "kind": "LinkedField",
          "name": "incomeSources",
          "plural": true,
          "selections": (v3/*: any*/),
          "storageKey": "incomeSources(type:\"None\")"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Expenses",
      "kind": "LinkedField",
      "name": "expenses",
      "plural": false,
      "selections": [
        {
          "alias": "currentExpenses",
          "args": (v0/*: any*/),
          "concreteType": "ExpenseSource",
          "kind": "LinkedField",
          "name": "expenseSources",
          "plural": true,
          "selections": (v3/*: any*/),
          "storageKey": "expenseSources(type:\"None\")"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Assets",
      "kind": "LinkedField",
      "name": "assets",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FinancialAsset",
          "kind": "LinkedField",
          "name": "financialAssets",
          "plural": true,
          "selections": (v3/*: any*/),
          "storageKey": null
        },
        {
          "alias": "currentRealAssets",
          "args": [
            {
              "kind": "Literal",
              "name": "current",
              "value": true
            }
          ],
          "concreteType": "RealAsset",
          "kind": "LinkedField",
          "name": "realAssets",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "currentValue",
              "storageKey": null
            }
          ],
          "storageKey": "realAssets(current:true)"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Debts",
      "kind": "LinkedField",
      "name": "debts",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Loan",
          "kind": "LinkedField",
          "name": "loans",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "balance",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "monthlyPayment",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Benefits",
      "kind": "LinkedField",
      "name": "benefits",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rrspRoom",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tfsaRoom",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cppAge",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "oasYearsInCanada",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "oasMonthsDelayed",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Result",
      "kind": "LinkedField",
      "name": "result",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "retirementAge",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ResultStep",
          "kind": "LinkedField",
          "name": "firstStep",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TaxResult",
              "kind": "LinkedField",
              "name": "tax",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cpp",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ei",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "provincialTax",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "federalTax",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "total",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ResultStep",
          "kind": "LinkedField",
          "name": "retirementStep",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "financialAssetsTotal",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ResultStep",
          "kind": "LinkedField",
          "name": "lastStep",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "age",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "EstateResult",
              "kind": "LinkedField",
              "name": "estate",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "beforeTax",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "afterTax",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "afterTaxReal",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Scenario",
  "abstractKey": null
};
})();
(node as any).hash = 'bc02f2d3f659de9f1c9a061706e8b051';
export default node;
