/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type HomePersonQueryVariables = {};
export type HomePersonQueryResponse = {
    readonly me: {
        readonly person: {
            readonly id: string;
            readonly firstName: string;
            readonly lastName: string;
            readonly scenarios: ReadonlyArray<{
                readonly id: string;
                readonly startYear: number;
                readonly description: string;
            }>;
        };
    };
};
export type HomePersonQuery = {
    readonly response: HomePersonQueryResponse;
    readonly variables: HomePersonQueryVariables;
};



/*
query HomePersonQuery {
  me {
    person {
      id
      firstName
      lastName
      scenarios {
        id
        startYear
        description
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Person",
  "kind": "LinkedField",
  "name": "person",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Scenario",
      "kind": "LinkedField",
      "name": "scenarios",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startYear",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "HomePersonQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "HomePersonQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3f76b558cfde2e0df812897f39b72e43",
    "id": null,
    "metadata": {},
    "name": "HomePersonQuery",
    "operationKind": "query",
    "text": "query HomePersonQuery {\n  me {\n    person {\n      id\n      firstName\n      lastName\n      scenarios {\n        id\n        startYear\n        description\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'bd3a834a6cee7e5e40c97772ba666f7e';
export default node;
