import {useCallback} from "react";
import {graphql} from "babel-plugin-relay/macro";
import {useMutation} from "react-relay/hooks";
import {handleMutation} from "../HandleMutation";
import { useSetScenarioCommentMutation } from "./__generated__/useSetScenarioCommentMutation.graphql";

const mutation = graphql`
    mutation useSetScenarioCommentMutation($scenarioId: ID!, $comment: String!){
        setScenarioComment(scenarioId: $scenarioId, comment: $comment){
            id
            comment
        }
    }`;

export default function useSetScenarioComment(): [(id: string, comment: string, onCompleted?: () => void) => void, boolean]{
    const [commit, committing] = useMutation<useSetScenarioCommentMutation>(mutation);
    const callback = useCallback((id: string, comment: string, onCompleted?: () => void) => {
        handleMutation("", comment, "scenario", "update", commit, {
            variables: {
                scenarioId: id,
                comment: comment,
            },
            onCompleted: () => {
                if(onCompleted)
                    onCompleted();
            }
        })
    },[commit]);

    return [callback, committing];
}