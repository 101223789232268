import React, {useContext, useEffect, useState} from "react";
import {ResultRecord} from "../Domain/ResultTypes";
import {AgGridReact} from 'ag-grid-react';
import {GridApi, GridReadyEvent, ValueSetterParams} from "ag-grid-community";
import {graphql} from "babel-plugin-relay/macro";
import {handleMutation} from "../HandleMutation";
import {useMutation} from "react-relay/hooks";
import { ForecastGridOverrideMutation } from "./__generated__/ForecastGridOverrideMutation.graphql";
import AppContext from "../../AppContext";
import { ForecastGridOverrideRemoveMutation } from "./__generated__/ForecastGridOverrideRemoveMutation.graphql";
import {columnTypes} from "../ScenarioEditor/Grids/ColumnTypes";
import {Button, Space, Tooltip} from "antd";
import {FileExcelOutlined} from "@ant-design/icons";
//import {useUserConfig} from "../UserConfigContext";


const overrideMutation = graphql`
    mutation ForecastGridOverrideMutation($scenarioId: ID!, $overrideId: ID!, $overrideType: OverrideType!, $year: Int!, $typeId: ID!, $amount: Int!){
        overrideUpdate(scenarioId: $scenarioId, overrideId: $overrideId, overrideType: $overrideType, year: $year, typeId: $typeId, amount: $amount){
            result {
                ...ScenarioResultsDataResult
            }
            ...ScenarioDetailedSummary
        }
    } 
`;

const overrideRemoveMutation = graphql`
    mutation ForecastGridOverrideRemoveMutation($scenarioId: ID!, $overrideId: ID!){
        overrideRemove(scenarioId: $scenarioId, overrideId: $overrideId){
            result {
                ...ScenarioResultsDataResult
            }
            ...ScenarioDetailedSummary
        }
    }
`;

interface DataGridAgProps {
    dataId: string
    dataSource: ResultRecord[]
}

export default function ForecastGrid (props: DataGridAgProps) {
    //const [userConfig, setUserConfig] = useUserConfig();
    const app = useContext(AppContext);

    const [overrideMutationCommit] = useMutation<ForecastGridOverrideMutation>(overrideMutation);
    const [overrideRemoveMutationCommit] = useMutation<ForecastGridOverrideRemoveMutation>(overrideRemoveMutation);
    const [gridApi, setGridApi] = useState<GridApi>();
    //const [columnApi, setColumnApi] = useState<ColumnApi>();

    const gridState = {
        columnDefs: [
            { headerName: "Year", field: "year", pinned: 'left', width: 85, suppressSizeToFit: true, type: 'rightAligned'},
            { headerName: "Age", field: "age", pinned: 'left', width: 75, suppressSizeToFit: true, type: 'rightAligned'},
            { headerName: "Surplus", field: "cash", type: 'currencyColumn'},
            {
                children: [
                    {
                        headerName: "Total Expenses",
                        width: 130,
                        field: "expensesTotal",
                        columnGroupShow: "closed",
                        type: ['currencyColumn', 'importantColumn']
                    },
                    ...props.dataSource[0].expenses.map((v, i) => {
                    return {
                        headerName: v.name,
                        columnGroupShow: "open",
                        children: [
                            {
                                headerName: "Nominal",
                                editable: true,
                                cellStyle: (params: any) => {
                                    const baseStyle = {textAlign: "right", color: "#1890ff"};
                                    if(params.data.expenses[i]?.isOverride)
                                        return {fontStyle: "italic", backgroundColor: "#d9d9d9", ...baseStyle}
                                    return baseStyle;
                                },
                                valueSetter: (params:ValueSetterParams) => {
                                    const year = params.data.year;
                                    const id = `${params.data.expenses[i]?.sourceRecordId}-${year}`;
                                    const sourceRecordId =  params.data.expenses[i]?.sourceRecordId;
                                    const name = `${params.data.expenses[i]?.name} [${year}]`;

                                    if(!params.newValue || params.newValue === ""){
                                        handleMutation(id, name, "expense override", "remove", overrideRemoveMutationCommit, {
                                            variables: {
                                                overrideId: params.data.expenses[i]?.id,
                                                scenarioId: app.scenario.id,
                                            }
                                        });
                                        return true;
                                    }

                                    handleMutation(id, name, "expense override", "update", overrideMutationCommit, {
                                       variables: {
                                           overrideId: "00000000-0000-0000-0000-000000000000",
                                           amount: parseInt(params.newValue),
                                           overrideType: "Expense",
                                           scenarioId: app.scenario.id,
                                           typeId: sourceRecordId,
                                           year: params.data.year
                                       }
                                    });

                                    return true;
                                },
                                valueGetter: (params: any) => params.data.expenses[i]?.amountNom,
                                type: ['currencyColumn', 'importantColumn'],
                            },
                            {
                                headerName: "Real",
                                valueGetter: (params: any) => params.data.expenses[i]?.amount,
                                type: ['currencyColumn'],
                            }
                        ]
                    }
                })]
            },
            {
                children: [
                    {
                        headerName: "Total Income",
                        width: 130,
                        field: "incomeTotal",
                        columnGroupShow: "closed",
                        type: ['currencyColumn', 'importantColumn']
                    },
                    ...props.dataSource[0].income.map((v, i) => { return {
                        headerName: v.name,
                        columnGroupShow: "open",
                        valueGetter: (params:any) => params.data.income[i]?.amount,
                        type: ['currencyColumn']}
                    })
                ]
            },
            {
              children: [
                  {
                      headerName: "Total CPP/OAS",
                      width: 130,
                      field: "cppOasTotal",
                      columnGroupShow: "closed",
                      type: ['currencyColumn', 'importantColumn']
                  },
                  {
                      headerName: "CPP",
                      columnGroupShow: "open",
                      children: [
                          {headerName: "Payment", field: "cpp.payment", type: ['currencyColumn']}
                      ]
                  },
                  {
                      headerName: "OAS",
                      columnGroupShow: "open",
                      children: [
                          {headerName: "Payment", field: "oas.payment", type: ['currencyColumn']},
                          {headerName: "Clawback", field: "oas.recovery", type: ['currencyColumn']},
                      ]
                  }
                  ]
            },
            {
                headerName: "TFSA Room",
                field: "tfsaRoom",
                type: ['currencyColumn']
            },
            {
                headerName: "RRSP Room",
                headerTooltip: "test",
                field: "rrspRoom",
                type: ['currencyColumn']
            },
            {
                headerName: "Financial Assets",
                children: [
                    {
                        headerName: "Contribution",
                        width: 100,
                        field: "financialAssetsContribTotal",
                        columnGroupShow: "closed",
                        type: ['currencyColumn', 'importantColumn']
                    },
                    {
                        headerName: "Total",
                        width: 100,
                        field: "financialAssetsTotal",
                        columnGroupShow: "closed",
                        type: ['currencyColumn', 'importantColumn']
                    },
                    {
                        headerName: "RoR",
                        width: 100,
                        field: "financialAssetsRorTotal",
                        columnGroupShow: "closed",
                        type: ['percentageColumn', 'importantColumn']
                    },
                    ...props.dataSource[0].financialAssets.map((v, i) => {
                    return {
                        headerName: v.name,
                        columnGroupShow: "open",
                        children: [
                            {headerName: "Contribution",valueGetter: (params: any) => params.data.financialAssets[i]?.contribution, type: ['currencyColumn']},
                            {headerName: "Value", valueGetter: (params: any) => params.data.financialAssets[i]?.value, type: 'currencyColumn'},
                            {headerName: "RoR", valueGetter: (params: any) => params.data.financialAssets[i]?.rate, type: 'percentageColumn'}
                        ]
                    }
                })
                ]
            },
            {
                headerName: "Real Assets",
                children: [
                    {
                        headerName: "Total",
                        width: 130,
                        field: "realAssetsTotal",
                        columnGroupShow: "closed",
                        type: ['currencyColumn', 'importantColumn']
                    },
                    ...props.dataSource[0].realAssets.map((v, i) => {
                    return {
                        headerName: v.name,
                        columnGroupShow: "open",
                        children: [
                            {headerName: "Value.", valueGetter: (params: any) => params.data.realAssets[i]?.value, type: 'currencyColumn'},
                            {headerName: "RoR.", valueGetter: (params: any) => params.data.realAssets[i]?.rate, type: 'percentageColumn'}
                        ]
                    }
                })
                ]
            },
            {
                headerName: "Debt",
                children: [
                    {
                        headerName: "Total Owing",
                        width: 130,
                        valueGetter: (params: any) => -params.data.debtsOwingTotal,
                        columnGroupShow: "closed",
                        type: ['currencyColumn', 'importantColumn']
                    },
                    {
                        headerName: "Total Paid",
                        width: 130,
                        valueGetter: (params: any) => -params.data.debtsPaidTotal,
                        columnGroupShow: "closed",
                        type: ['currencyColumn', 'importantColumn']
                    },
                    ...props.dataSource[0].debts.map((v, i) => {
                    return {
                        headerName: v.name,
                        columnGroupShow: "open",
                        children: [
                            {headerName: "Owing",valueGetter: (params: any) => -params.data.debts[i]?.owing, type: 'currencyColumn'},
                            {headerName: "Paid", valueGetter: (params: any) => -params.data.debts[i]?.paid, type: ['currencyColumn']},
                        ]
                    }
                })
                ]
            },
            {
                headerName: "Tax",
                children: [
                    { headerName: "Total", field: "tax.total", columnGroupShow: "closed", type: ['currencyColumn', 'importantColumn'] },
                    { headerName: "CPP" , field: "tax.cpp", columnGroupShow: "open", type: ['currencyColumn'] },
                    { headerName: "EI" , field: "tax.ei", columnGroupShow: "open", type: ['currencyColumn'] },
                    { headerName: "Provincial", field: "tax.provincial", columnGroupShow: "open", type: ['currencyColumn'] },
                    { headerName: "Federal", field: "tax.federal", columnGroupShow: "open", type: ['currencyColumn'] },
                    { headerName: "Marginal", field: "tax.marginalRate", columnGroupShow: "open", type: 'percentageColumn'},
                    { headerName: "Effective", field: "tax.effectiveRate", columnGroupShow: "open", type: 'percentageColumn'},
                ]
            },
            {
                headerName: "Estate",
                children: [
                    { headerName: "Before Tax" , field: "estate.beforeTax", type: 'currencyColumn' },
                    { headerName: "After Tax", field: "estate.afterTax", type: 'currencyColumn' },
                    { headerName: "ATax (real)", field: "estate.afterTaxReal", type: 'currencyColumn'},
                    { headerName: "Tax rate", field: "estate.taxRate", type: 'percentageColumn'},
                ]
            },
            {
                headerName: "Metrics",
                children: [
                    { headerName: "Savings Rate", field: "savingsRate", type: 'percentageColumn'},
                    { headerName: "Withdrawal Rate", field: "withdrawalRate", type: 'percentageColumn'},
                    { headerName: "Debt-to-income Ratio", field: "dtiRatio", type: 'percentageColumn'},
                ]
            },
        ],
        columnTypes: columnTypes,
        defaultColDef: {
            resizable: true,
        },
    };

    function onGridReady(event: GridReadyEvent) {
        event.api.setColumnDefs(gridState.columnDefs);
        // event.columnApi.setColumnGroupState(userConfig.forecast.columnGroupState);
        // event.columnApi.applyColumnState({state: userConfig.forecast.columnState, applyOrder: true});
        setGridApi(event.api);
        // setColumnApi(event.columnApi);
    }

    // function setColumnState() {
    //     const colState =  columnApi?.getColumnState() ?? userConfig.forecast.columnState;
    //     const columnGroupState =  columnApi?.getColumnGroupState() ?? userConfig.forecast.columnGroupState;
    //     const columnState = colState && colState.length !== 0 ? colState : userConfig.forecast.columnState;
    //
    //     setUserConfig({...userConfig, forecast: {columnState, columnGroupState}});
    // }

    useEffect(() => {
        gridApi?.setRowData(props.dataSource);
    }, [gridApi, props.dataSource]);

    return (
        <Space direction={"vertical"} style={{width: "100%"}} >
            <Tooltip title={"Export to Excel"} mouseEnterDelay={0} mouseLeaveDelay={0}>
                <Button size={"large"} type={"link"} style={{float: "right"}} onClick={() => gridApi?.exportDataAsCsv({fileName: app.scenario.name ,allColumns: true})} icon={<FileExcelOutlined />} />
            </Tooltip>
            <div className="ag-theme-balham" style={{height: "calc(100vh - 125px)"}}>
                    <AgGridReact
                        onGridReady={onGridReady}
                        columnTypes={gridState.columnTypes}
                        defaultColDef={gridState.defaultColDef}
                        // onDragStopped={setColumnState}
                        // onColumnResized={setColumnState}
                        // onColumnGroupOpened={setColumnState}
                    />
            </div>
        </Space>
    )
}