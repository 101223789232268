import {graphql} from "babel-plugin-relay/macro";
import {useMutation} from "react-relay";
import { useLoanRemoveMutation } from "./__generated__/useLoanRemoveMutation.graphql";
import {useCallback} from "react";
import {handleMutation} from "../HandleMutation";

const removeMutation = graphql`
    mutation useLoanRemoveMutation($scenarioId: ID!, $loanId: String!){
        loanRemove(scenarioId: $scenarioId, loanId: $loanId){
            debts {
                ...DebtsUpdate
            }
            result {
                ...ScenarioResultsDataResult
            }
            ...ScenarioDetailedSummary
            ...ScenarioHierarchicalMenu
        }
    }`;

export default function useLoanRemove():[(scenarioId: string, id: string, description: string) => void, boolean]{
    const [commit, committing] = useMutation<useLoanRemoveMutation>(removeMutation);
    const callback = useCallback((scenarioId: string, id: string, description: string) => {
        handleMutation(id, description, "loan", "remove", commit, {
            variables: {scenarioId: scenarioId, loanId: id}
        })
    }, [commit]);

    return [callback, committing];
}