import React, {useEffect, useState} from "react";
import {AgGridReact} from "ag-grid-react";
import {GridReadyEvent} from "ag-grid-community/dist/lib/events";
import {GridApi, ICellRendererParams, ValueSetterParams} from "ag-grid-community";
import {columnTypes, columnTypesComponents} from "../../Grids/ColumnTypes";
import {DeleteColumnDef} from "../../Grids/ColumnDefs";
import {onDragStopped, updateIfDiff} from "../../Grids/Utilities";
import {RealAsset} from "./RealAsset";

export default function RealAssetsGrid (props: {
    data: RealAsset[]
    update: (value: RealAsset) => boolean
    updateOrdinals: (ids: string[]) => void
}) {
    const [gridApi, setGridApi] = useState<GridApi>();

    function onGridReady(event: GridReadyEvent) {
        setGridApi(event.api);
    }

    const [gridState] = useState({
        columnDefs: [
            {
                headerName: "Description",
                field: "description",
                editable: true,
                rowDrag: true,
                valueSetter: (params: ValueSetterParams) => updateIfDiff(params, props.update, {description: params.newValue})
            },
            {
                headerName: "Current Value",
                field: "currentValue",
                type: ['currencyColumn', 'importantColumn'],
                width: 140,
                editable: true,
                valueSetter: (params: ValueSetterParams) => updateIfDiff(params, props.update, {currentValue: params.newValue ?? 0})
            },
            {
                headerName: "Original Value",
                field: "originalValue",
                type: ['currencyColumn', 'importantColumn'],
                width: 140,
                editable: true,
                valueSetter: (params: ValueSetterParams) => updateIfDiff(params, props.update, {originalValue: params.newValue ?? 0})
            },
            {
                headerName: "Appreciation",
                field: "appreciationRate",
                type: ['percentageColumn'],
                width: 140,
                editable: true,
                valueSetter: (params: ValueSetterParams) => updateIfDiff(params, props.update, {appreciationRate: params.newValue ?? 0})
            },
            {
                headerName: "Taxable Gains",
                field: "taxableGains",
                type: "selectColumn",
                width: 140,
                editable: true,
                valueSetter: (params: ValueSetterParams) => updateIfDiff(params, props.update, {taxableGains: params.newValue ?? 0}) as boolean,
                cellRenderer: (params: ICellRendererParams) => params.data.taxableGains ? "Yes" : "No",
                cellEditorParams: {values: [
                        { key: true, value: "Yes" },
                        { key: false, value: "No" },
                    ]},
            },
            {
                headerName: "Future Purchase",
                field: "futurePurchaseDate",
                type: "dateColumn",
                width: 140,
                editable: true,
                valueSetter: (params: ValueSetterParams) => updateIfDiff<RealAsset>(params, props.update, {futurePurchaseDate: params.newValue}),
                cellEditorParams: { allowClear: true}
            },
            {
                headerName: "Future Sale",
                field: "futureSaleDate",
                type: "dateColumn",
                editable: true,
                width: 140,
                valueSetter: (params: ValueSetterParams) => updateIfDiff<RealAsset>(params, props.update, {futureSaleDate: params.newValue}),
                cellEditorParams: { allowClear: true}
            },
            DeleteColumnDef(),
        ],
        columnTypes: columnTypes,
        components: columnTypesComponents,
        defaultColDef: {
            resizable: true,
        },
    });

    useEffect(() => {
        gridApi?.setRowData(props.data)
    }, [gridApi, props.data])

    return <div className="ag-theme-material">
        <AgGridReact
            stopEditingWhenGridLosesFocus={true}
            rowData={props.data}
            domLayout={"autoHeight"}
            singleClickEdit={true}
            onDragStopped={event => onDragStopped(event, props.updateOrdinals)}
            suppressMoveWhenRowDragging={true}
            rowDragManaged={true}
            onGridReady={onGridReady}
            frameworkComponents={gridState.components}
            columnDefs={gridState.columnDefs}
            columnTypes={gridState.columnTypes}
            defaultColDef={gridState.defaultColDef}
            suppressNoRowsOverlay={true}
        />
    </div>
}