import {Card, Result} from "antd";
import React, {useContext} from "react";
import {useLazyLoadQuery} from "react-relay/hooks";
import {graphql} from "babel-plugin-relay/macro";
import { ForecastWorkspaceQuery } from "./__generated__/ForecastWorkspaceQuery.graphql";
import AppContext from "../../AppContext";
import Results from "../ScenarioEditor/ScenarioResultsData";
import ForecastGrid from "./ForecastGrid";
import ScenarioMenu from "../ScenarioMenu";

const query = graphql`query ForecastWorkspaceQuery($scenarioId: ID!) {
    me {
        person {
            scenarios(ids: [$scenarioId]) {
                id
                description
                startYear
                result {
                    id
                    ...ScenarioResultsDataResult
                }

            }
        }
    }
}`;

export default function ForecastWorkspace() {
    const app = useContext(AppContext);
    const data = useLazyLoadQuery<ForecastWorkspaceQuery>(query, {scenarioId: app.scenario.id});

    const scenarioData = data.me.person.scenarios[0];

    if(!scenarioData)
        return <Result
            status="warning"
            title="Please select a plan"
            subTitle= {<ScenarioMenu />}
        />;

    return (
        <Card bodyStyle={{height: "100%"}}>
            <Results key={"res" + scenarioData.result.id} results={scenarioData.result}>
                {(dataProps: any) => (<ForecastGrid key={`grid${dataProps.dataId}`} {...dataProps} />)}
            </Results>
        </Card>
    )
}