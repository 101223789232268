import {Disposable, MutationParameters} from "relay-runtime";
import {message, notification} from "antd";
import { UseMutationConfig } from "react-relay/hooks";

//const {Text} = Typography;

export function handleMutation<T extends MutationParameters>(id: string, description: string, type: string, operation: "remove" | "update", mutation: (config: UseMutationConfig<T>) => Disposable, config: UseMutationConfig<T>){

    const inProgress = operation === "remove" ? "removing" : operation === "update" ? "updating":"";
    //const completed = operation === "remove" ? "removed" : operation === "update" ? "updated":"";

    const key = `${type}-${id}`;
    //message.loading({content: <><Text strong>{description}</Text> {type} {inProgress}...</>, key, duration: 0});
    mutation({
        ...config,
        onCompleted: (response, errors) => {
            if(errors) {
                errors?.map(x => notification['error']({
                    message: `${description}`,
                    description: x.message,
                    duration: 0
                }));
                //message.loading({content: <><Text strong>{description}</Text> {type} {completed}!</>, key, duration: -1});
            }
            else
                //message.success({content: <><Text strong>{description}</Text> {type} {completed}!</>, key, duration: 2});
            if(config.onCompleted)
                config.onCompleted(response, errors);
        },
        onError: (error) => {
            message.error({content: `Error ${inProgress} ${description}!`, key});
            if(config.onError)
                config.onError(error);
        }
    });
}