import React, {ReactChild, useContext, useEffect, useState} from "react";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay/hooks";
import {DebtsUpdate$key} from "./__generated__/DebtsUpdate.graphql";
import AppContext from "../../../AppContext";
import LoansGrid, {Loan} from "./LoansGrid";
import EditorLayout from "../../EditorLayout";
import {Button, Space, Typography} from "antd";
import {AuditOutlined} from '@ant-design/icons';
import useLoanUpdate from "../../Mutations/useLoanUpdate";
import useLoanRemove from "../../Mutations/useLoanRemove";
import useLoanUpdateOrdinals from "../../Mutations/useLoanUpdateOrdinals";

const {Text} = Typography;

const query =  graphql`
    fragment DebtsUpdate on Debts {
        id
        loans {
            id
            description
            balance
            interestRate
            futureStartDate
            monthlyPayment
            taxDeductible
        }
    }
`;

export default function DebtsUpdate (props: { debts: DebtsUpdate$key, chart: ReactChild }) {
    const app = useContext(AppContext);
    const data = useFragment(query, props.debts);
    const [dataList, setDataList] = useState<Loan[]>([]);

    const [commitUpdate, committingUpdate] = useLoanUpdate();
    const [commitRemove, committingRemove] = useLoanRemove();
    const [commitUpdateOrdinal, committingUpdateOrdinal] = useLoanUpdateOrdinals();

    const defaultValues = {
        id: "00000000-0000-0000-0000-000000000000",
        description: "Loan #" + (data.loans.length+1),
        balance: 10000,
        interestRate: 2,
        futureStartDate: null,
        monthlyPayment: 100,
        taxDeductible: false,
        onRemove: () => {}
    };

    useEffect(() => {
        setDataList(data.loans.map(x => {return {
            id: x.id,
            description: x.description,
            balance: x.balance,
            interestRate: x.interestRate,
            monthlyPayment: x.monthlyPayment,
            taxDeductible: x.taxDeductible,
            futureStartDate: x.futureStartDate,
            onRemove: () => commitRemove(app.scenario.id, x.id, x.description)
        }}));
    }, [data, commitRemove, app.scenario.id]);

    const onUpdate = (value: Loan) => {
        commitUpdate(app.scenario.id, value);
        return true;
    }

    const onUpdateOrdinals = (order: string[]) => {
        commitUpdateOrdinal(app.scenario.id, data.id, order);
    }

    const onAdd = () => {
        onUpdate(defaultValues);
    }
    return (
    <EditorLayout
        controls={
            <Space align="center">
                <Button type={"primary"} onClick={onAdd} disabled={committingUpdate || committingRemove || committingUpdateOrdinal}><AuditOutlined /> Add Loan</Button>
                <Text type={"secondary"}>Loans can be used to model debt like mortgages and car loans.</Text>
            </Space>
        }
        editor={<LoansGrid data={dataList} update={onUpdate} updateOrdinals={onUpdateOrdinals} />}
        chart={props.chart}
    />
    )
}