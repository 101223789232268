import {
    Button,
    Divider, Dropdown,
    Layout,
    Menu,
    Space, Spin,
    Tooltip,
    Typography
} from "antd";
import {AppMenu} from "../AppMenu";
import React, {Suspense, useContext, useState} from "react";
import Icon, {LoadingOutlined, UserOutlined, DownOutlined, ControlOutlined, CheckOutlined, LogoutOutlined} from '@ant-design/icons'
import ScenarioMenu from "./ScenarioMenu";
import {useUserConfig} from "./UserConfigContext";
import PlannerContent from "./PlannerContent";
import {Route, Switch} from "react-router-dom";
import Home from "./Home";
import PersonalUpdate from "./Personal/PersonalUpdateLayout";
import AppContext from "../AppContext";
import PlanPopover from "./PlanPopover";
import LogoutButton from "./LogoutButton";
import Profile from "./Profile";

const {Content, Header, Sider} = Layout;
const {Text} = Typography;


export default function Planner() {

    const [userConfig, setUserConfig] = useUserConfig();
    const [isSiderCollapsed, setIsSiderCollapsed] = useState(false);
    const app = useContext(AppContext);

    const scenarioMenu = <Suspense fallback={null}><ScenarioMenu toggleMenu={() => setIsSiderCollapsed(!isSiderCollapsed)} /></Suspense>;

    const userMenu = (
        <Menu theme={"light"} selectable={false}>
            <Profile />
            <Menu.Item style={{color: "#000"}} icon={<LogoutOutlined />}>
                <LogoutButton type={"text"} />
            </Menu.Item>
            <Menu.Item style={{color: "#000"}} >
                <Text>v0</Text>
            </Menu.Item>
        </Menu>);

    const viewOptionsMenu = (
            <Menu theme={"light"} selectable={false} >
                <Menu.ItemGroup  title={<Text type={"secondary"}>Side Panels</Text>}>
                    <Menu.Item style={{color: "#000"}} icon={userConfig.siders.leftIsVisible ? <CheckOutlined /> : <Icon /> } onClick={() => setUserConfig({...userConfig, siders: {...userConfig.siders, leftIsVisible: !userConfig.siders.leftIsVisible}})}>Financial Statements</Menu.Item>
                    <Menu.Item style={{color: "#000"}} icon={userConfig.siders.rightIsVisible ? <CheckOutlined /> : <Icon />} onClick={() => setUserConfig({...userConfig, siders: {...userConfig.siders, rightIsVisible: !userConfig.siders.rightIsVisible}})}>Assumptions</Menu.Item>
                </Menu.ItemGroup>
            </Menu>
    );

    return (
        <Layout style={{ minHeight: '100vh'}}>
            <Sider trigger={null} collapsible theme={"dark"} collapsed={isSiderCollapsed} style={{height: "100vh", overflowY: "auto", overflowX: "hidden"}}>
                {scenarioMenu}
            </Sider>
            <Layout>
                <Header style={{marginBottom: "4px", paddingRight: "8px"}}>
                    <div style={{display: "flex", flexFlow: "row"}}>
                        <div style={{flex: "1 1 33%"}}>
                            <AppMenu />
                        </div>
                        <div style={{textAlign: "center", flex: "1 1 34%"}}>
                            {app.scenario.id === "00000000-0000-0000-0000-000000000000" ? "" :
                                <PlanPopover>
                                    <Button type={"ghost"}><Text style={{width: "200px"}} strong ellipsis={{tooltip: app.scenario.name}}>{app.scenario.name}</Text> <DownOutlined/></Button>
                                </PlanPopover>
                            }
                        </div>
                        <div style={{flex: "1 1 33%"}}>
                            <Space direction={"horizontal"} style={{float: "right"}}>
                                <Space>
                                    <Dropdown arrow={true} overlay={viewOptionsMenu} trigger={["click"]}>
                                        <Tooltip title="View Options" mouseEnterDelay={0} mouseLeaveDelay={0}>
                                            <Button size={"large"} type={"text"} icon={<ControlOutlined style={{color: "rgba(0, 0, 0, 0.45)"}} />} />
                                        </Tooltip>
                                    </Dropdown>
                                </Space>
                                <Divider type={"vertical"} style={{backgroundColor: "#000"}} />
                                <Dropdown arrow={true} overlay={userMenu} trigger={["click"]}>
                                    <Button type={"text"}>
                                        <UserOutlined />
                                        <Text>
                                            {/*{(keycloak.idTokenParsed as {preferred_username: string})?.preferred_username}*/}
                                        </Text>
                                        <DownOutlined />
                                    </Button>
                                </Dropdown>
                            </Space>
                        </div>
                    </div>
                </Header>
                <Content>
                    <Suspense fallback={
                        <div style={{height: "100vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <Spin tip={`Loading plan...`} indicator={<LoadingOutlined spin style={{ fontSize: 24 }} />} />
                        </div>
                    }>
                        <Switch>
                            <Route path={"/"} exact={true}>
                                <Home />
                            </Route>
                            <Route path="/personal" >
                                <PersonalUpdate />
                            </Route>
                            <Route path={"*"}>
                                <PlannerContent />
                            </Route>
                        </Switch>
                    </Suspense>
                </Content>
            </Layout>
        </Layout>
    )
}