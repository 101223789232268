import React from "react";
import {Result} from "antd";


export default function FinishStep() {

    return <Result
        status={"success"}
        title={"On-boarding complete!"}
    />
}
