/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type IncomeSourceType = "CapitalGains" | "EligibleDividends" | "Employment" | "IneligibleDividends" | "NonTaxable" | "Other" | "SelfEmployment" | "%future added value";
export type TimingType = "Date" | "None" | "Retirement" | "%future added value";
export type IncomeUpdate = {
    readonly id: string;
    readonly incomeSources: ReadonlyArray<{
        readonly id: string;
        readonly description: string;
        readonly amount: number;
        readonly annualIncrease: number;
        readonly type: IncomeSourceType;
        readonly start: {
            readonly type: TimingType;
            readonly date: unknown | null;
        };
        readonly end: {
            readonly type: TimingType;
            readonly date: unknown | null;
        };
    }>;
    readonly " $refType": "IncomeUpdate";
};
export type IncomeUpdate$data = IncomeUpdate;
export type IncomeUpdate$key = {
    readonly " $data"?: IncomeUpdate$data;
    readonly " $fragmentRefs": FragmentRefs<"IncomeUpdate">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "date",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "IncomeUpdate",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "IncomeSource",
      "kind": "LinkedField",
      "name": "incomeSources",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "annualIncrease",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Timing",
          "kind": "LinkedField",
          "name": "start",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Timing",
          "kind": "LinkedField",
          "name": "end",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Income",
  "abstractKey": null
};
})();
(node as any).hash = '86d5b6cc0734604910c4e9a781ba50b5';
export default node;
