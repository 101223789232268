import {
    authMiddleware,
    cacheMiddleware,
    RelayNetworkLayer,
    retryMiddleware,
    urlMiddleware
} from "react-relay-network-modern";
import {
    Environment,
    RecordSource,
    Store,
} from "relay-runtime";
import AppConfig from "./AppConfig";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";

export default function environment(getToken: () => Promise<string>):RelayModernEnvironment {
    const network = new RelayNetworkLayer(
        [
            cacheMiddleware({size: 1000, ttl: 20000}),
            urlMiddleware({
                url: () => Promise.resolve(AppConfig.apiUrl),
            }),
            retryMiddleware({
                fetchTimeout: 15000,
                retryDelays: (attempt) => Math.pow(2, attempt + 4) * 100,
                beforeRetry: ({abort, attempt}) => {
                    if (attempt > 10) abort();
                },
                statusCodes: [500, 503, 504],
            }),
            authMiddleware({
                token: getToken(),
            }),
        ], {
            //subscribeFn: (operation, variables) => setupSubscription(operation, variables)
            noThrow: true,
        }
    );

    const store = new Store(new RecordSource(), {});

    return new Environment({network, store});
};
