const winEnv = (window as any).env;

const auth = {
    Authority: winEnv?.REACT_APP_AUTH_AUTHORITY ?? process.env.REACT_APP_AUTH_AUTHORITY,
    Audience: winEnv?.REACT_APP_AUTH_AUDIENCE ?? process.env.REACT_APP_AUTH_AUDIENCE,
    ClientId: winEnv?.REACT_APP_AUTH_CLIENT_ID ?? process.env.REACT_APP_AUTH_CLIENT_ID,
    Realm: winEnv?.REACT_APP_AUTH_REALM ?? process.env.REACT_APP_AUTH_REALM
}

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
    Auth: auth,
    apiUrl: winEnv?.REACT_APP_API_URL ?? process.env.REACT_APP_API_URL,
    buildNumber: winEnv?.REACT_APP_BUILD_NUMBER ?? process.env.REACT_APP_BUILD_NUMBER
}