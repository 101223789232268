import React from "react";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay/hooks";
import {DebtsChartResult$key} from "./__generated__/DebtsChartResult.graphql";
import {Tooltip} from "../Charts/Tooltip";
import {ChartTheme} from "../Charts/ChartTheme";
import {Chart} from "../../Chart/Chart";
import ChartSubject from "../../Chart/ChartSubject";

const query =  graphql`
    fragment DebtsChartResult on Result {
        retirementAge
        steps {
            age
            year
            debts {
                name
                owing
            }
        }
    }
`;

export default function DebtsChart (props: {data: DebtsChartResult$key, subject?: ChartSubject}) {
    const data = useFragment(query, props.data);

    if(data.steps.length === 0 || data.steps.reduce((pv, cv) => pv + cv.debts.length , 0) === 0)
        return null;


    const xAxis = [
        [...data.steps.map(x => x.age)],
        [...data.steps.map(x => x.year)],
    ]
    const source = [
        ...data.steps[0].debts.map((x, i) => [...data.steps.map(y => y.debts[i].owing)])
    ]

    const option = {
        ...ChartTheme,
        title: {
            text: "Debts"
        },
        backgroundColor: "#FFFFFF",
        grid: [
            {
                right: '2%',
                left: '60px',
            }
        ],
        legend: {
            top: "bottom",
        },
        tooltip: Tooltip(xAxis, "Debts", 1000),
        xAxis: [
            {type: 'category',
            data: xAxis[0]
            },
            {type: 'category',
            data: xAxis[1]
            },
        ],
        yAxis: [
            {
                scale: true,
                gridIndex: 0,
                position: "left",
                type: "value",
                splitNumber: 5,
                min: 0,
                name: "Thousands  $",
                nameLocation: "center",
                nameGap: 35,
                axisLabel: {
                    formatter: (value:number) => value / 1000
                }
            },
        ],
        series: [...data.steps[0].debts.map((x, i) => {
            return {
                type: "bar",
                stack: "income",
                symbol: "none",
                seriesLayoutBy: "row",
                name: x.name,
                data: source[i]
            }
        }),
            {
                type: "line",
                markArea: {
                    silent: true,
                    itemStyle: { color: "rgba(226,226,226,0.4)" },
                    data: [
                        [{
                            name: "Retired",
                            label: {
                                distance: 30,
                                color: "#989898"
                            },
                            xAxis: `${data.retirementAge}`,
                        },
                            {
                                xAxis: "100"
                            }]
                    ]
                },
            }]
    };

    return <Chart style={{height: "100%"}} option={option} subject={props.subject} />
}