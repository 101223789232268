import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react'
import { DatePicker } from 'antd'
import moment from 'moment'
import {ICellEditorParams} from "ag-grid-community";


export default forwardRef((props: ICellEditorParams, ref) => {
    const inputRef = useRef<any>();
    const calRef = useRef<any>();
    const [valueMoment] = useState(moment(props.value ?? moment(), "yyyy-MM"));
    const [open, setOpen] = useState(false);
    const [isDoneEdit, setIsDoneEdit] = useState(false);

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return inputRef.current.value === "undefined" ? null : inputRef.current.value;
            },
            afterGuiAttached: () => {
                setOpen(true);
                calRef.current.focus();
            },
            isCancelAfterEnd: () => {
                setOpen(false);
                props.api?.setFocusedCell(props.rowIndex, props.column);
            }
        };
    });

    useEffect(() => {
        if(isDoneEdit)
            props.api?.stopEditing();
    }, [isDoneEdit, props.api])

    return <div>
        <input ref={inputRef} defaultValue={props.value} hidden />
        <DatePicker ref={calRef} picker={"month"} value={valueMoment} open={open}
                    allowClear={props.colDef.cellEditorParams?.allowClear ?? false}
                    inputReadOnly={false}
                    onChange={(value) => {
                        inputRef.current.value = value?.format("yyyy-MM-DD");
                        setIsDoneEdit(true);
                    }}
                    onPanelChange={(value, mode) => {
                        if(mode === "date") {
                            inputRef.current.value = value.format("yyyy-MM-DD");
                            setIsDoneEdit(true);
                        }
                    }}
       />
    </div>;
});