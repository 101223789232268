import {graphql} from "babel-plugin-relay/macro";
import {useMutation} from "react-relay";
import { useLoanUpdateMutation } from "./__generated__/useLoanUpdateMutation.graphql";
import {useCallback} from "react";
import {handleMutation} from "../HandleMutation";

const updateMutation = graphql`
    mutation useLoanUpdateMutation($scenarioId: ID!, $loanInput: LoanInput!){
        loanUpdate(scenarioId: $scenarioId, loanInput: $loanInput){
            debts {
                ...DebtsUpdate
            }
            result {
                ...ScenarioResultsDataResult
            }
            ...ScenarioDetailedSummary
            ...ScenarioHierarchicalMenu
        }
    }
`;

export interface LoanInputType {
    id: string
    description: string
    balance: number
    interestRate: number
    monthlyPayment: number
    taxDeductible: boolean
    futureStartDate: unknown
}

export default function useLoanUpdate():[(scenarioId: string, value: LoanInputType) => void, boolean]{
    const [commit, committing] = useMutation<useLoanUpdateMutation>(updateMutation);
    const callback = useCallback((scenarioId: string, value: LoanInputType) => {
        handleMutation(value.id, value.description, "financial asset", "update", commit,{
            variables: {
                scenarioId: scenarioId,
                loanInput: {
                    id: value.id,
                    description: value.description,
                    balance: value.balance,
                    interestRate: value.interestRate,
                    monthlyPayment: value.monthlyPayment,
                    taxDeductible: value.taxDeductible,
                    futureStartDate: value.futureStartDate,
                }
            }
        });
    }, [commit]);

    return [callback, committing];
}