/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type useSetScenarioCommentMutationVariables = {
    scenarioId: string;
    comment: string;
};
export type useSetScenarioCommentMutationResponse = {
    readonly setScenarioComment: {
        readonly id: string;
        readonly comment: string | null;
    };
};
export type useSetScenarioCommentMutation = {
    readonly response: useSetScenarioCommentMutationResponse;
    readonly variables: useSetScenarioCommentMutationVariables;
};



/*
mutation useSetScenarioCommentMutation(
  $scenarioId: ID!
  $comment: String!
) {
  setScenarioComment(scenarioId: $scenarioId, comment: $comment) {
    id
    comment
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "comment"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "scenarioId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "comment",
        "variableName": "comment"
      },
      {
        "kind": "Variable",
        "name": "scenarioId",
        "variableName": "scenarioId"
      }
    ],
    "concreteType": "Scenario",
    "kind": "LinkedField",
    "name": "setScenarioComment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "comment",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useSetScenarioCommentMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useSetScenarioCommentMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "28217a0234e346374e86d9aa7e10717a",
    "id": null,
    "metadata": {},
    "name": "useSetScenarioCommentMutation",
    "operationKind": "mutation",
    "text": "mutation useSetScenarioCommentMutation(\n  $scenarioId: ID!\n  $comment: String!\n) {\n  setScenarioComment(scenarioId: $scenarioId, comment: $comment) {\n    id\n    comment\n  }\n}\n"
  }
};
})();
(node as any).hash = 'fcccb77391e18e7f30ceb9064943107e';
export default node;
