import React, {ReactChild, useContext, useEffect, useRef, useState} from "react";
import {Button, Card, Form, Input, Modal, Popover, Spin, Typography} from "antd";
import {DeleteOutlined, CopyOutlined, LoadingOutlined} from '@ant-design/icons'
import AppContext from "../AppContext";
import useScenarioAdd from "./Mutations/useScenarioAdd";
import useScenarioRemove from "./Mutations/useScenarioRemove";
import useSetScenarioDescription from "./Mutations/useSetScenarioDescription";

const {Text} = Typography;


const PlanPopover = (props: {children: ReactChild}) => {
    const app = useContext(AppContext);
    const [visible, setVisible] = useState<boolean>();
    const [commitDescription, committingDescription] = useSetScenarioDescription();
    const [newName, setNewName] = useState<string>("");

    return (
        <Popover content={<PlanCard setVisible={setVisible} setNewName={setNewName} />} trigger={["click"]} visible={visible} onVisibleChange={visible1 => {
            if(visible1)
                setNewName(app.scenario.name);
            setVisible(visible1)
            if(!visible1 && newName !== app.scenario.name && newName !== ""){
                commitDescription(app.scenario.id, newName);
            }
        }}>
            {props.children} {committingDescription ? <Text type={"secondary"}>Saving...</Text>:""}
        </Popover>
    )
}


function PlanCard(props: {setVisible: (value: boolean) => void, setNewName: (value: string) => void}){
    const app = useContext(AppContext);
    const [form] = Form.useForm();
    const newDescrRef = useRef(null);
    const [commitAdd] = useScenarioAdd();
    const commitRemove = useScenarioRemove();

    useEffect(() => {
        form.setFieldsValue(app.scenario);
    }, [app.scenario, form])

    const handleRemoveScenario = (id: string, description: string) => {
        props.setVisible(false);
        const result = Modal.confirm({
            title: 'Confirm',
            content: <div><Text type={"secondary"}>Delete</Text> <Text strong type={"danger"}>{description}</Text>?</div>,
            okText: 'Delete',
            okButtonProps: {danger: true},
            autoFocusButton: "cancel",
        });

        result.update({onOk: () => commitRemove(id, description)});
    }

    const handleCloneScenario = (id: string, description: string) => {
        props.setVisible(false);
        const modal = Modal.confirm({
            title: <div><Text type={"secondary"}>Copy</Text> <Text>{description}</Text></div>,
            content: <div>
                <Text>Description</Text>
                <Input ref={newDescrRef} placeholder={"Description"} defaultValue={`${description} - Copy`} />
            </div>,
            okText: "Copy",
            onOk: close => {
                modal.update(prevConfig => {
                    return {...prevConfig, okText: <div><Spin indicator={<LoadingOutlined />} /> <Text>Copying...</Text></div>, okButtonProps: {disabled: true}}
                })
                //@ts-ignore
                commitAdd(app.person.id, id, newDescrRef.current?.state.value, () => close());
            }
        });
    }
    return (
        <Card
            size={"small"}
            bordered={false}
            actions={[
                <Button type={"text"} onClick={() => handleCloneScenario(app.scenario.id, app.scenario.name)}><CopyOutlined /> Copy</Button>,
                <Button type={"text"} onClick={() => handleRemoveScenario(app.scenario.id, app.scenario.name)} danger ><DeleteOutlined /> Delete</Button>
            ]}
        >
            <Form size={"small"} layout={"vertical"}
                  form={form}
                  initialValues={app.scenario}
            >
                <Form.Item name={"name"} label={"Plan Name"} >
                    <Input onChange={event => props.setNewName(event.target.value)} />
                </Form.Item>
            </Form>
        </Card>
    );
}


export default PlanPopover;
