import React, {ReactChild, useContext} from "react";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay/hooks";
import {BenefitsUpdate$key} from "./__generated__/BenefitsUpdate.graphql";
import BenefitsUpdatePanel from "./BenefitsUpdatePanel";
import {Tabs, Typography} from "antd";
import UpeGrid, {Upe} from "./UpeGrid";
import AppContext from "../../../AppContext";
import useUpeUpdate from "../../Mutations/useUpeUpdate";

const {Title, Link, Text} = Typography;

const query =  graphql`
    fragment BenefitsUpdate on Benefits {
        id
        ...BenefitsUpdatePanel
        upe {
            year
            amount
        }
    }
`;

export default function BenefitsUpdate (props: { data: BenefitsUpdate$key, chart?: ReactChild }) {
    const app = useContext(AppContext);
    const data = useFragment(query, props.data);
    const [commitUpdate] = useUpeUpdate();

    const onUpeUpdate = (upe: Upe) => {
        commitUpdate(app.scenario.id, upe.year, upe.amount);
        return true;
    };

    const upeData = data.upe.map(x => {return {...x}})
        .sort((a, b) => a.year < b.year ? 1 : a.year > b.year ? -1 : 0 )

    return (
        <Tabs defaultActiveKey={"general"} size={"small"} style={{height: "100%"}}>
            <Tabs.TabPane tab={"General"} key={"general"}>
                <BenefitsUpdatePanel data={data} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={"UPE"} key={"upe"} style={{height: "100%"}}>

                <Title level={4}>Unadjusted Pensionable Earnings</Title>
                <Text type={"secondary"}>Can be collected from the
                    <Link href={"https://www.canada.ca/en/services/benefits/publicpensions/cpp/statement-contributions.html"} target={"_blank"}>
                        Government of Canada website
                    </Link>
                </Text>
                <div style={{height: "calc(100vh - 380px)"}}>
                    <UpeGrid data={upeData} update={onUpeUpdate} />
                </div>
            </Tabs.TabPane>
        </Tabs>
    )
}