/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CompareDataProviderRefetchQueryVariables = {
    scenarioId: Array<string>;
    scenarioIds?: Array<string> | null;
};
export type CompareDataProviderRefetchQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"CompareDataProviderQuery">;
};
export type CompareDataProviderRefetchQuery = {
    readonly response: CompareDataProviderRefetchQueryResponse;
    readonly variables: CompareDataProviderRefetchQueryVariables;
};



/*
query CompareDataProviderRefetchQuery(
  $scenarioIds: [ID!]
) {
  ...CompareDataProviderQuery_447Pm6
}

fragment CompareDataProviderQuery_447Pm6 on Query {
  me {
    person {
      compare: scenarios(ids: $scenarioIds) {
        id
        description
        result {
          id
          retirementAge
          steps {
            age
            year
            incomeTotal
            expensesTotal
            estate {
              beforeTax
              afterTax
              afterTaxReal
              taxRate
              tax
            }
            tax {
              marginalRate
            }
            financialAssetsTotal
            financialAssetsContribTotal
            savingsRate
            dtiRatio
            withdrawalRate
          }
        }
      }
      id
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "scenarioId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "scenarioIds"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompareDataProviderRefetchQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "scenarioId",
            "variableName": "scenarioId"
          }
        ],
        "kind": "FragmentSpread",
        "name": "CompareDataProviderQuery"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompareDataProviderRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              {
                "alias": "compare",
                "args": [
                  {
                    "kind": "Variable",
                    "name": "ids",
                    "variableName": "scenarioIds"
                  }
                ],
                "concreteType": "Scenario",
                "kind": "LinkedField",
                "name": "scenarios",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Result",
                    "kind": "LinkedField",
                    "name": "result",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "retirementAge",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ResultStep",
                        "kind": "LinkedField",
                        "name": "steps",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "age",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "year",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "incomeTotal",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "expensesTotal",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EstateResult",
                            "kind": "LinkedField",
                            "name": "estate",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "beforeTax",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "afterTax",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "afterTaxReal",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "taxRate",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "tax",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TaxResult",
                            "kind": "LinkedField",
                            "name": "tax",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "marginalRate",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "financialAssetsTotal",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "financialAssetsContribTotal",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "savingsRate",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "dtiRatio",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "withdrawalRate",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "baa830bc568edfdf6f1d69be9bf20a19",
    "id": null,
    "metadata": {},
    "name": "CompareDataProviderRefetchQuery",
    "operationKind": "query",
    "text": "query CompareDataProviderRefetchQuery(\n  $scenarioIds: [ID!]\n) {\n  ...CompareDataProviderQuery_447Pm6\n}\n\nfragment CompareDataProviderQuery_447Pm6 on Query {\n  me {\n    person {\n      compare: scenarios(ids: $scenarioIds) {\n        id\n        description\n        result {\n          id\n          retirementAge\n          steps {\n            age\n            year\n            incomeTotal\n            expensesTotal\n            estate {\n              beforeTax\n              afterTax\n              afterTaxReal\n              taxRate\n              tax\n            }\n            tax {\n              marginalRate\n            }\n            financialAssetsTotal\n            financialAssetsContribTotal\n            savingsRate\n            dtiRatio\n            withdrawalRate\n          }\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'b56f6941211a796de0dfaab970134855';
export default node;
