import {graphql} from "babel-plugin-relay/macro";
import {useMutation} from "react-relay";
import {InputType, useAmountUpdateMutation} from "./__generated__/useAmountUpdateMutation.graphql";
import {useCallback} from "react";
import {handleMutation} from "../HandleMutation";

const mutation = graphql`
    mutation useAmountUpdateMutation($scenarioId: ID!, $inputType: InputType!, $id: ID!, $amount: Int!){
        amountUpdate(id: $id, amount: $amount, scenarioId: $scenarioId, inputType: $inputType){
            income {
                ...IncomeUpdate
            }
            result {
                ...ScenarioResultsDataResult
            }
            ...ScenarioDetailedSummary
            ...ScenarioHierarchicalMenu
        }
    }
`;

export default function useAmountUpdate():[(scenarioId: string, inputType: InputType, id: string, amount: number, description: string) => void, boolean] {
    const [commit, committing] = useMutation<useAmountUpdateMutation>(mutation);
    const callback = useCallback((scenarioId: string, inputType: InputType, id: string, amount: number, description: string) => {
        return handleMutation(id, description, inputType, "update", commit, {
            variables: {
                scenarioId: scenarioId,
                inputType: inputType,
                id: id,
                amount: amount
            }
        })
    }, [commit])

    return [callback, committing];
}