/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FinancialAssetType = "NonRegistered" | "Rrsp" | "Tfsa" | "%future added value";
export type NetCashFlowChartResult = {
    readonly retirementAge: number;
    readonly steps: ReadonlyArray<{
        readonly age: number;
        readonly year: number;
        readonly expensesTotal: number;
        readonly debtsPaidTotal: number;
        readonly tax: {
            readonly total: number;
        };
        readonly income: ReadonlyArray<{
            readonly name: string;
            readonly amount: number;
        }>;
        readonly oas: {
            readonly payment: number;
        };
        readonly cpp: {
            readonly payment: number;
        };
        readonly financialAssetsContribTotal: number;
        readonly financialAssets: ReadonlyArray<{
            readonly name: string;
            readonly contribution: number;
            readonly type: FinancialAssetType | null;
        }>;
        readonly realAssets: ReadonlyArray<{
            readonly cash: number;
        }>;
    }>;
    readonly " $refType": "NetCashFlowChartResult";
};
export type NetCashFlowChartResult$data = NetCashFlowChartResult;
export type NetCashFlowChartResult$key = {
    readonly " $data"?: NetCashFlowChartResult$data;
    readonly " $fragmentRefs": FragmentRefs<"NetCashFlowChartResult">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "payment",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NetCashFlowChartResult",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "retirementAge",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ResultStep",
      "kind": "LinkedField",
      "name": "steps",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "age",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "year",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "expensesTotal",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "debtsPaidTotal",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TaxResult",
          "kind": "LinkedField",
          "name": "tax",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "total",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "IncomeResult",
          "kind": "LinkedField",
          "name": "income",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "amount",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OASResults",
          "kind": "LinkedField",
          "name": "oas",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CPPResult",
          "kind": "LinkedField",
          "name": "cpp",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "financialAssetsContribTotal",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AssetResult",
          "kind": "LinkedField",
          "name": "financialAssets",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "contribution",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "RealAssetResult",
          "kind": "LinkedField",
          "name": "realAssets",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cash",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Result",
  "abstractKey": null
};
})();
(node as any).hash = '6aa75ddaaa74e13e932a78d1aed156a5';
export default node;
