/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ScenarioDetailedSummary = {
    readonly id: string;
    readonly description: string;
    readonly comment: string | null;
    readonly income: {
        readonly incomeSources: ReadonlyArray<{
            readonly id: string;
            readonly description: string;
            readonly amount: number;
        }>;
    };
    readonly expenses: {
        readonly expenseSources: ReadonlyArray<{
            readonly id: string;
            readonly description: string;
            readonly amount: number;
        }>;
    };
    readonly assets: {
        readonly financialAssets: ReadonlyArray<{
            readonly id: string;
            readonly description: string;
            readonly amount: number;
        }>;
        readonly realAssets: ReadonlyArray<{
            readonly id: string;
            readonly description: string;
            readonly amount: number;
        }>;
    };
    readonly debts: {
        readonly loans: ReadonlyArray<{
            readonly id: string;
            readonly description: string;
            readonly amount: number;
        }>;
    };
    readonly result: {
        readonly retirementAge: number;
        readonly retirementStep: {
            readonly financialAssetsTotal: number;
            readonly withdrawalRate: number;
        };
        readonly firstStep: {
            readonly age: number;
            readonly financialAssetsTotal: number;
        };
        readonly lastStep: {
            readonly age: number;
            readonly estate: {
                readonly beforeTax: number;
            };
        };
        readonly " $fragmentRefs": FragmentRefs<"NetCashFlowChartResult" | "ExpensesChartResult" | "FinancialAssetsChartResult" | "RealAssetsChartResult" | "DebtsChartResult" | "BenefitsChartResult" | "EstateChartResult">;
    };
    readonly " $refType": "ScenarioDetailedSummary";
};
export type ScenarioDetailedSummary$data = ScenarioDetailedSummary;
export type ScenarioDetailedSummary$key = {
    readonly " $data"?: ScenarioDetailedSummary$data;
    readonly " $fragmentRefs": FragmentRefs<"ScenarioDetailedSummary">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "amount",
    "storageKey": null
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "financialAssetsTotal",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "age",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScenarioDetailedSummary",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "comment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Income",
      "kind": "LinkedField",
      "name": "income",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "IncomeSource",
          "kind": "LinkedField",
          "name": "incomeSources",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Expenses",
      "kind": "LinkedField",
      "name": "expenses",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ExpenseSource",
          "kind": "LinkedField",
          "name": "expenseSources",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Assets",
      "kind": "LinkedField",
      "name": "assets",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FinancialAsset",
          "kind": "LinkedField",
          "name": "financialAssets",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "RealAsset",
          "kind": "LinkedField",
          "name": "realAssets",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": "amount",
              "args": null,
              "kind": "ScalarField",
              "name": "currentValue",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Debts",
      "kind": "LinkedField",
      "name": "debts",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Loan",
          "kind": "LinkedField",
          "name": "loans",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": "amount",
              "args": null,
              "kind": "ScalarField",
              "name": "balance",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Result",
      "kind": "LinkedField",
      "name": "result",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "retirementAge",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ResultStep",
          "kind": "LinkedField",
          "name": "retirementStep",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "withdrawalRate",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ResultStep",
          "kind": "LinkedField",
          "name": "firstStep",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            (v3/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ResultStep",
          "kind": "LinkedField",
          "name": "lastStep",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "EstateResult",
              "kind": "LinkedField",
              "name": "estate",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "beforeTax",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "NetCashFlowChartResult"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ExpensesChartResult"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FinancialAssetsChartResult"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RealAssetsChartResult"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DebtsChartResult"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BenefitsChartResult"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EstateChartResult"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Scenario",
  "abstractKey": null
};
})();
(node as any).hash = '35b201aa765984b39cd49565add63580';
export default node;
