import React, {Suspense, useState} from "react";
import {Button, Card, Layout, Space, Spin, Steps} from "antd";
import {LoadingOutlined} from '@ant-design/icons';
import {CSSTransition, SwitchTransition} from "react-transition-group";

import "../../../fade.css";

const {Header, Content, Sider} = Layout;
const { Step } = Steps;

export interface IWizardStep {
    icon: React.ReactNode
    title: React.ReactNode
    content: React.ReactNode | IWizardProps
    onBefore?: () => boolean
    onNext?: () => boolean
    onPrevious?: () => boolean
}

interface IWizardProps {
    key: string
    steps: IWizardStep[]
    current?: number
    direction?: "horizontal" | "vertical" | undefined
    finishedText?: string
    onFinish?: () => void
    onBack?: () => void
    finishedComponent?: React.ReactNode;
}

export default function Wizard(props: IWizardProps) {
    const [currentStep, setCurrentStep] = useState(props.current ?? 0);
    const [isFinished, setIsFinished] = useState(false);

    if(isFinished && props.finishedComponent)
        return <div>{props.finishedComponent}</div>
    if(currentStep >= props.steps.length)
        return <div>Step out of range.</div>;

    const handleOnNext = () => {
        const currentStepData = props.steps[currentStep];
        let result = true;
        if (currentStepData.onNext !== undefined)
            result = currentStepData.onNext();

        if (result && currentStep + 1 < props.steps.length)
            setCurrentStep(currentStep + 1)
    };

    const handleOnPrevious = () => {
        if(currentStep === 0 && props.onBack)
            props.onBack();
        else
            setCurrentStep(currentStep-1)
    };

    const handleOnFinished = () => {
        const currentStepData = props.steps[currentStep];
        if (currentStepData.onNext !== undefined)
            currentStepData.onNext();

        if(props.onFinish)
            props.onFinish();

        setIsFinished(true);
    };

    const steps = (
        <Card bordered={false}>
            <Steps direction={props.direction} current={currentStep} size={"small"}>
                {props.steps.map((x, i) => <Step key={i} icon={x.icon} title={x.title} />)}
            </Steps>
        </Card>
    );

    const stepsParent = props.direction === "vertical" ? <Sider theme={"light"}>{steps}</Sider> : <Header>{steps}</Header>;
    const isContentWizard = !React.isValidElement(props.steps[currentStep].content);
    const stepContent = isContentWizard
        ? <Wizard {...props.steps[currentStep].content as IWizardProps} onFinish={handleOnNext} onBack={currentStep === 0 ? undefined : handleOnPrevious} finishedText={"Next"} />
        : <Content style={{display: "flex", flexFlow: "column"}} >
            <Card style={{height: "100%", overflowY: "auto", flex: "1 1 100%" }} bodyStyle={{height: "100%"}} bordered={false}>
                <SwitchTransition>
                    <CSSTransition
                        key={currentStep}
                        timeout={300}
                        classNames="fade"
                        unmountOnExit
                    >
                        <div style={{height: "100%", display: "flex", flexFlow: "column"}}>
                            <Suspense fallback={
                                <div style={{height: "100vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <Spin tip={`Initializing...`} indicator={<LoadingOutlined spin style={{ fontSize: 24 }} />} />
                                </div>
                            }>
                                {props.steps[currentStep].content}
                            </Suspense>
                        </div>
                    </CSSTransition>
                </SwitchTransition>
            </Card>
            <Card style={{textAlign: "right", flex: "0 0 50px"}} bordered={false}>
                <Space>
                    <Button disabled={currentStep === 0 && !props.onBack} onClick={handleOnPrevious}>Previous</Button>

                    {props.steps.length !== currentStep + 1
                        ? <Button onClick={handleOnNext} type={"primary"}>Next</Button>
                        : <Button onClick={handleOnFinished} type={"primary"}>{props.finishedText ? props.finishedText : "Finish"}</Button>
                    }
                </Space>
            </Card>
        </Content>;

    return (
        <Layout style={{height: "100%"}}>
            {stepsParent}
            <SwitchTransition>
                <CSSTransition
                    key={currentStep}
                    timeout={300}
                    classNames="fade"
                    unmountOnExit
                >
                    {stepContent}
                </CSSTransition>
            </SwitchTransition>
        </Layout>
    );
}
