import {graphql} from "babel-plugin-relay/macro";
import {useMutation} from "react-relay";
import {useCallback} from "react";
import { useUpeUpdateMutation } from "./__generated__/useUpeUpdateMutation.graphql";
import {handleMutation} from "../HandleMutation";

const updateUpeMutation = graphql`
    mutation useUpeUpdateMutation($scenarioId: ID!, $year: Int!, $amount: Int!) {
        upeUpdate(scenarioId: $scenarioId, year: $year, amount: $amount){
            benefits {
                ...BenefitsUpdate
            }
            result {
                ...ScenarioResultsDataResult
            }
            ...ScenarioDetailedSummary
            ...ScenarioHierarchicalMenu
        }
    }
`;

export default function useUpeUpdate():[(scenarioId: string, year: number, amount: number) => void, boolean]{
    const [commit, committing] = useMutation<useUpeUpdateMutation>(updateUpeMutation);
    const callback = useCallback((scenarioId: string, year: number, amount: number) => {
        handleMutation(`Upe ${year}`, year.toString(), "UPE", "update", commit, {
            variables: {
                scenarioId: scenarioId,
                year: year,
                amount: amount
            }
        })
    }, [commit]);

    return [callback, committing];
}