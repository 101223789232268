/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type BenefitsUpdatePanel = {
    readonly id: string;
    readonly rrspRoom: number;
    readonly tfsaRoom: number;
    readonly cppAge: number;
    readonly oasYearsInCanada: number;
    readonly oasMonthsDelayed: number;
    readonly " $refType": "BenefitsUpdatePanel";
};
export type BenefitsUpdatePanel$data = BenefitsUpdatePanel;
export type BenefitsUpdatePanel$key = {
    readonly " $data"?: BenefitsUpdatePanel$data;
    readonly " $fragmentRefs": FragmentRefs<"BenefitsUpdatePanel">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BenefitsUpdatePanel",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rrspRoom",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tfsaRoom",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cppAge",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "oasYearsInCanada",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "oasMonthsDelayed",
      "storageKey": null
    }
  ],
  "type": "Benefits",
  "abstractKey": null
};
(node as any).hash = '29f394bb48c4b1d1cdc1696e325f0acd';
export default node;
