import React, {useEffect, useState} from "react";
import {CompareData, CompareDataStep} from "./CompareData";
import {Card, Col, Row, Dropdown, Menu, Space, Button, Typography} from "antd";
import {DownOutlined} from '@ant-design/icons'
import CompareChart from "./CompareChart";
import {useUserConfig} from "../UserConfigContext";

const {Text} = Typography;

export default function CompareTable(props: {name: string, data: CompareData, selectedChartIndex: number, showLegend?: boolean}) {
    const [userConfig, setUserConfig] = useUserConfig();
    const [selectedChart, setSelectedChart] = useState(userConfig?.compare.chartOptions[props.name]?.options ?? props.selectedChartIndex);

    useEffect(() => {
        if(userConfig.compare.chartOptions[props.name]?.options === selectedChart)
            return;

        const newUserConfig = {...userConfig};
        newUserConfig.compare.chartOptions[props.name] = {options: selectedChart};
        setUserConfig(newUserConfig)
    }, [selectedChart, props.name, setUserConfig, userConfig]);

    useEffect(() => {
        setSelectedChart(userConfig?.compare.chartOptions[props.name]?.options ?? props.selectedChartIndex);
    }, [userConfig, props.name, props.selectedChartIndex])

    if(props.data.series.length === 0)
        return null;

    function makeArray(start: number, end: number){
        let array:number[] = [];
        const count = end - start;

        for(let i = 0; i < count; i++){
            array.push(start+i);
        }

        return array;
    }

    function makeEmptyArray(count: number){
        let array:(number|null)[] = [];

        for(let i = 0; i < count; i++){
            array.push(null);
        }

        return array;
    }

    const minAge = Math.min(...props.data.series.map(x => x.steps[0].age));
    const maxAge = Math.max(...props.data.series.map(x => x.steps[x.steps.length - 1].age));

    const minYear = Math.min(...props.data.series.map(x => x.steps[0].year));
    const maxYear = Math.max(...props.data.series.map(x => x.steps[x.steps.length - 1].year));

    const xAxisAge = makeArray(minAge, maxAge);
    const xAxisYear = makeArray(minYear, maxYear);

    const indexOffsets = props.data.series.map(x => x.steps[0].age - minAge);

    const charts: {title: string, field: (x: CompareDataStep) => number, type: string, dataType: ("none" | "dollar" | "percent")}[] = [
        {
            title: "Estate Before Tax",
            field: (x:CompareDataStep) => x.btax,
            type: "line",
            dataType: "dollar"
        },
        {
            title: "Estate After Tax",
            field: (x:CompareDataStep) => x.atax,
            type: "line",
            dataType: "dollar"
        },
        {
            title: "Estate After Tax Real",
            field: (x:CompareDataStep) => x.ataxReal,
            type: "line",
            dataType: "dollar"
        },
        {
            title: "Estate Tax Rate",
            field: (x:CompareDataStep) => x.estateTaxRate,
            type: "line",
            dataType: "percent"
        },
        {
            title: "Debt-to-income Ratio",
            field: (x:CompareDataStep) => x.dtiRatio,
            type: "line",
            dataType: "none"
        },
        {
            title: "Savings Rate",
            field: (x:CompareDataStep) => x.savingsRate,
            type: "line",
            dataType: "percent"
        },
        {
            title: "Withdrawal Rate",
            field: (x:CompareDataStep) => x.withdrawalRate,
            type: "line",
            dataType: "percent"
        },
        {
            title: "Financial Assets Total",
            field: (x:CompareDataStep) => x.financialAssetsTotal,
            type: "line",
            dataType: "dollar"
        },
        {
            title: "Financial Assets Contrib Total",
            field: (x:CompareDataStep) => x.financialAssetsContribTotal,
            type: "bar",
            dataType: "dollar"
        },
        {
            title: "Tax Marginal Rate",
            field: (x:CompareDataStep) => x.taxMarginalRate,
            type: "line",
            dataType: "percent"
        },
        {
            title: "Income",
            field: (x:CompareDataStep) => x.incomeTotal,
            type: "line",
            dataType: "dollar"
        },
        {
            title: "Expenses",
            field: (x:CompareDataStep) => x.expenseTotal,
            type: "line",
            dataType: "dollar"
        },
    ]


    const data = [
        xAxisAge,
        xAxisYear,
        ...props.data.series.map((s,i) => { return [s.description, ...makeEmptyArray(indexOffsets[i]), ...s.steps.map(charts[selectedChart].field)] })
    ]


    const height = "calc(50vh - 120px)";

    const retirementAge = props.data.series.map(x => x.retirementAge);

    const menu = (
        <Menu>
            {charts.map((x, i) => <Menu.Item onClick={() => setSelectedChart(i)}>{x.title}</Menu.Item>)}
        </Menu>
    );



    return <div>
        <Row gutter={[4,4]}>
            <Col span={24}>
                <Card>
                    <Space direction={"vertical"} style={{width: "100%"}} >
                        <Dropdown overlay={menu}>
                            <Button type={"default"} onClick={e => e.preventDefault()}>
                                <Text strong>{charts[selectedChart].title}</Text> <DownOutlined />
                            </Button>
                        </Dropdown>
                        <div style={{height: height}}>
                            <CompareChart title={charts[selectedChart].title} dataSource={data} retirementAge={retirementAge} type={charts[selectedChart].type} dataType={charts[selectedChart].dataType} showLegend={props.showLegend} />
                        </div>
                    </Space>
                </Card>
            </Col>
        </Row>
    </div>
}