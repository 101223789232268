import React from "react";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay/hooks";
import {FinancialAssetsChartResult$key, FinancialAssetType} from "./__generated__/FinancialAssetsChartResult.graphql";
import {Tooltip} from "../../Charts/Tooltip";
import {ChartTheme} from "../../Charts/ChartTheme";
import {Chart} from "../../../Chart/Chart";
import ChartSubject from "../../../Chart/ChartSubject";

const query =  graphql`
    fragment FinancialAssetsChartResult on Result {
        retirementAge
        steps {
            age
            year
            financialAssets {
                name
                value
                type
            }
            
        }
    }
`;

export default function FinancialAssetsChart(props: {data: FinancialAssetsChartResult$key, subject?: ChartSubject, hideTitle?: boolean, hideTypes?: boolean}) {
    const data = useFragment(query, props.data);

    if(data.steps.length === 0)
        return null;


    const xAxis = [
        [...data.steps.map(x => x.age)],
        [...data.steps.map(x => x.year)],
    ]
    const source = [
        ...data.steps[0].financialAssets.map((x, i) => [...data.steps.map(y => y.financialAssets[i].value)])
    ]

    function getName(x: { readonly name: string; readonly value: number; readonly type: FinancialAssetType | null }) {
        if(props.hideTypes)
            return x.name;

        return`${x.name} (${x.type})`;
    }

    const option = {
        ...ChartTheme,
        title: {
            text: props.hideTitle ? undefined: "Financial Assets"
        },
        backgroundColor: "#FFFFFF",
        grid: [
            {
                right: '2%',
                left: '60px',
            }
        ],
        tooltip: Tooltip(xAxis, "Financial Assets", 1000000),
        xAxis: [
            {type: 'category',
            data: xAxis[0]
            },
            {type: 'category',
            data: xAxis[1]
            },
        ],
        yAxis: [
            {
                scale: true,
                gridIndex: 0,
                position: "left",
                type: "value",
                splitNumber: 5,
                min: 0,
                name: "Millions  $",
                nameLocation: "center",
                nameGap: 35,
                axisLabel: {
                    formatter: (value:number) => value / 1000000
                }
            },
        ],
        series: [ ...data.steps[0].financialAssets.map((x, i) => {
            return {
                type: "bar",
                stack: "income",
                symbol: "none",
                seriesLayoutBy: "row",
                name: getName(x),
                data: source[i]
            }
        }),
            {
                type: "line",
                markArea: {
                    silent: true,
                    itemStyle: { color: "rgba(226,226,226,0.4)" },
                    data: [
                        [{
                            name: "Retired",
                            label: {
                                distance: 30,
                                color: "#989898"
                            },
                            xAxis: `${data.retirementAge}`,
                        },
                            {
                                xAxis: "100"
                            }]
                    ]
                },
            }
        ]
    };

    return <Chart style={{height: "100%"}} option={option} subject={props.subject} />
}