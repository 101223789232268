import {useFragment} from "react-relay/hooks";
import {ScenarioDetailedSummary$key} from "./__generated__/ScenarioDetailedSummary.graphql";
import {graphql} from "babel-plugin-relay/macro";
import React, {ReactNode} from "react";
import {Typography} from 'antd';
import useSetScenarioComment from "../Mutations/useSetScenarioComment";

const {Paragraph, Text} = Typography;

export interface ScenarioSummaryProps {
    scenario: ScenarioDetailedSummary$key
    chart: ReactNode
}

const query =  graphql`
    fragment ScenarioDetailedSummary on Scenario {
        id
        description
        comment
        income {
            incomeSources {
                id
                description
                amount
            }
        }
        expenses {
            expenseSources {
                id
                description
                amount
            }
        }
        assets {
            financialAssets {
                id
                description
                amount
            }
            realAssets {
                id
                description
                amount: currentValue
            }
        }
        debts {
            loans {
                id
                description
                amount: balance
            }
        }
        result{
            ...NetCashFlowChartResult
            ...ExpensesChartResult
            ...FinancialAssetsChartResult
            ...RealAssetsChartResult
            ...DebtsChartResult
            ...BenefitsChartResult
            ...EstateChartResult
            retirementAge
            retirementStep {
                financialAssetsTotal
                withdrawalRate
            }
            firstStep {
                age
                financialAssetsTotal
            }
            lastStep {
                age
                estate {
                    beforeTax
                }
            }
        }
    }
`;
export default function ScenarioDetailedSummary (props: ScenarioSummaryProps) {
    const data = useFragment(query, props.scenario);
    const [commitComment, committingComment] = useSetScenarioComment();

    return (
        <div style={{height: "100%", display: "flex", flexFlow: "column"}}>
            <div style={{flex: "0 0 50px", paddingRight: 12}}>
                <Paragraph key={data.id} editable={{maxLength: 2000, onChange: value => {commitComment(data.id, value, () => {})}}}>
                    {committingComment ? "Saving..." : data.comment ? data.comment : <Text type={"secondary"}>Comment to describe what your plan is modeling.</Text>}
                </Paragraph>
            </div>
            {props.chart}
        </div>
    );
};