import {useFragment} from "react-relay/hooks";
import {graphql} from "babel-plugin-relay/macro";
import React, {ReactChild, useContext, useEffect, useState} from "react";
import {RealAssetsUpdate$key} from "./__generated__/RealAssetsUpdate.graphql";
import AppContext from "../../../../AppContext";
import {RealAsset} from "./RealAsset";
import EditorLayout from "../../../EditorLayout";
import {Button, Space, Typography} from "antd";
import { HomeOutlined } from '@ant-design/icons';
import RealAssetsGrid from "./RealAssetsGrid";
import useRealAssetUpdate from "../../../Mutations/useRealAssetUpdate";
import useRealAssetRemove from "../../../Mutations/useRealAssetRemove";
import useRealAssetsUpdateOrdinals from "../../../Mutations/useRealAssetUpdateOrdinals";

const {Text} = Typography;

const query =  graphql`
    fragment RealAssetsUpdate on Assets {
        id
        realAssets {
            id
            description
            currentValue
            originalValue
            appreciationRate
            futurePurchaseDate
            futureSaleDate
            taxableGains
        }
    }
`;

export interface AssetsUpdateProps {
    data: RealAssetsUpdate$key
    chart: ReactChild
}

export default function RealAssetsUpdate (props: AssetsUpdateProps) {
    const app = useContext(AppContext);
    const data = useFragment(query, props.data);
    const [dataList, setDataList] = useState<RealAsset[]>([]);

    const [commitUpdate, committingUpdate] = useRealAssetUpdate();
    const [commitRemove, committingRemove] = useRealAssetRemove();
    const [commitUpdateOrdinals, committingUpdateOrdinals] = useRealAssetsUpdateOrdinals();

    const defaultValues:RealAsset = {
        id: "00000000-0000-0000-0000-000000000000",
        description: "Real Asset #" + (data.realAssets.length + 1),
        currentValue: 0,
        originalValue: 0,
        appreciationRate: 2,
        futurePurchaseDate: null,
        futureSaleDate: null,
        taxableGains: false,
        onRemove: () => {}
    };

    useEffect(() => {
        setDataList(data.realAssets.map(x => { return {
            id: x.id,
            description: x.description,
            appreciationRate: x.appreciationRate,
            currentValue: x.currentValue,
            originalValue: x.originalValue,
            taxableGains: x.taxableGains,
            futurePurchaseDate: x.futurePurchaseDate,
            futureSaleDate: x.futureSaleDate,
            onRemove: () => commitRemove(app.scenario.id, x.id, x.description)
        }}));
    }, [data, commitRemove, app.scenario.id]);

    const onUpdate = (value: RealAsset) => {
        commitUpdate(app.scenario.id, value);
        return true;
    }

    const onUpdateOrdinals = (order: string[]) => {
        commitUpdateOrdinals(app.scenario.id, data.id, order);
    }

    const onAdd = () => {
        onUpdate(defaultValues);
    }
    return (
        <EditorLayout
            controls={
                <Space align="center">
                    <Button type={"primary"} onClick={onAdd} disabled={committingUpdate || committingRemove || committingUpdateOrdinals}><HomeOutlined /> Add Real Asset</Button>
                    <Text type={"secondary"}>Real assets are physical property like real estate or vehicles.</Text>
                </Space>
            }
            editor={<RealAssetsGrid data={dataList} update={onUpdate} updateOrdinals={onUpdateOrdinals} />}
            chart={props.chart} />
    )
};