/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ScenarioResultsDataResult = {
    readonly id: string;
    readonly retirementAge: number;
    readonly steps: ReadonlyArray<{
        readonly year: number;
        readonly age: number;
        readonly expenses: ReadonlyArray<{
            readonly id: string;
            readonly sourceRecordId: string;
            readonly name: string;
            readonly amount: number;
            readonly amountNom: number;
            readonly isOverride: boolean;
        }>;
        readonly income: ReadonlyArray<{
            readonly name: string;
            readonly amount: number;
        }>;
        readonly cash: number;
        readonly financialAssets: ReadonlyArray<{
            readonly name: string;
            readonly rate: number;
            readonly value: number;
            readonly contribution: number;
        }>;
        readonly realAssets: ReadonlyArray<{
            readonly name: string;
            readonly rate: number;
            readonly value: number;
        }>;
        readonly debts: ReadonlyArray<{
            readonly name: string;
            readonly owing: number;
            readonly paid: number;
        }>;
        readonly estate: {
            readonly beforeTax: number;
            readonly afterTax: number;
            readonly afterTaxReal: number;
            readonly tax: number;
            readonly taxRate: number;
        };
        readonly tax: {
            readonly marginalRate: number;
            readonly effectiveRate: number;
            readonly provincialTax: number;
            readonly federalTax: number;
            readonly total: number;
            readonly cpp: number;
            readonly ei: number;
        };
        readonly oas: {
            readonly payment: number;
            readonly recovery: number;
        };
        readonly cpp: {
            readonly payment: number;
        };
        readonly expensesTotal: number;
        readonly incomeTotal: number;
        readonly cppOasTotal: number;
        readonly debtsOwingTotal: number;
        readonly debtsPaidTotal: number;
        readonly financialAssetsTotal: number;
        readonly financialAssetsContribTotal: number;
        readonly financialAssetsRorTotal: number;
        readonly realAssetsTotal: number;
        readonly savingsRate: number;
        readonly withdrawalRate: number;
        readonly dtiRatio: number;
        readonly tfsaRoom: number;
        readonly rrspRoom: number;
    }>;
    readonly " $refType": "ScenarioResultsDataResult";
};
export type ScenarioResultsDataResult$data = ScenarioResultsDataResult;
export type ScenarioResultsDataResult$key = {
    readonly " $data"?: ScenarioResultsDataResult$data;
    readonly " $fragmentRefs": FragmentRefs<"ScenarioResultsDataResult">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rate",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "payment",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScenarioResultsDataResult",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "retirementAge",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ResultStep",
      "kind": "LinkedField",
      "name": "steps",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "year",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "age",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ExpenseResult",
          "kind": "LinkedField",
          "name": "expenses",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sourceRecordId",
              "storageKey": null
            },
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "amountNom",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isOverride",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "IncomeResult",
          "kind": "LinkedField",
          "name": "income",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cash",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AssetResult",
          "kind": "LinkedField",
          "name": "financialAssets",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "contribution",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "RealAssetResult",
          "kind": "LinkedField",
          "name": "realAssets",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DebtResult",
          "kind": "LinkedField",
          "name": "debts",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "owing",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "paid",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EstateResult",
          "kind": "LinkedField",
          "name": "estate",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "beforeTax",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "afterTax",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "afterTaxReal",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tax",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "taxRate",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TaxResult",
          "kind": "LinkedField",
          "name": "tax",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "marginalRate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "effectiveRate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "provincialTax",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "federalTax",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "total",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cpp",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ei",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OASResults",
          "kind": "LinkedField",
          "name": "oas",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "recovery",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CPPResult",
          "kind": "LinkedField",
          "name": "cpp",
          "plural": false,
          "selections": [
            (v5/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "expensesTotal",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "incomeTotal",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cppOasTotal",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "debtsOwingTotal",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "debtsPaidTotal",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "financialAssetsTotal",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "financialAssetsContribTotal",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "financialAssetsRorTotal",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "realAssetsTotal",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "savingsRate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "withdrawalRate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dtiRatio",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tfsaRoom",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rrspRoom",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Result",
  "abstractKey": null
};
})();
(node as any).hash = 'c3802715314247f1face4ed47eff736a';
export default node;
