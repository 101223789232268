/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type RealAssetsUpdate = {
    readonly id: string;
    readonly realAssets: ReadonlyArray<{
        readonly id: string;
        readonly description: string;
        readonly currentValue: number;
        readonly originalValue: number;
        readonly appreciationRate: number;
        readonly futurePurchaseDate: unknown | null;
        readonly futureSaleDate: unknown | null;
        readonly taxableGains: boolean;
    }>;
    readonly " $refType": "RealAssetsUpdate";
};
export type RealAssetsUpdate$data = RealAssetsUpdate;
export type RealAssetsUpdate$key = {
    readonly " $data"?: RealAssetsUpdate$data;
    readonly " $fragmentRefs": FragmentRefs<"RealAssetsUpdate">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RealAssetsUpdate",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "RealAsset",
      "kind": "LinkedField",
      "name": "realAssets",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currentValue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "originalValue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "appreciationRate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "futurePurchaseDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "futureSaleDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "taxableGains",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Assets",
  "abstractKey": null
};
})();
(node as any).hash = '2fcd3eecbf7d42999c7b71b1344d9b84';
export default node;
