import React, {useEffect} from "react";
import {useRefetchableFragment} from "react-relay/hooks";
import {graphql} from "babel-plugin-relay/macro";
import {CompareDataProviderRefetchQuery} from "./__generated__/CompareDataProviderRefetchQuery.graphql";
import {CompareDataProviderQuery$key} from "./__generated__/CompareDataProviderQuery.graphql";
import {CompareData} from "./CompareData";

export default function CompareDataProvider(props: {scenarios: CompareDataProviderQuery$key, scenarioIds: string[], setNewData: (data: CompareData) => void}) {
    const [data, refetch] = useRefetchableFragment<CompareDataProviderRefetchQuery, CompareDataProviderQuery$key>(
        graphql`fragment CompareDataProviderQuery on Query
        @argumentDefinitions(scenarioId: {type: "[ID!]!"})
        @refetchable(queryName: "CompareDataProviderRefetchQuery")
        {
            me {
                person {
                    compare: scenarios(ids: $scenarioIds){
                        id
                        description
                        result {
                            id
                            retirementAge
                            steps {
                                age
                                year
                                incomeTotal
                                expensesTotal
                                estate {
                                    beforeTax
                                    afterTax
                                    afterTaxReal
                                    taxRate
                                    tax
                                }
                                tax {
                                    marginalRate
                                }
                                financialAssetsTotal
                                financialAssetsContribTotal
                                savingsRate
                                dtiRatio
                                withdrawalRate
                            }
                        }
                    }
                }
            }
        }`, props.scenarios);

    const setNewData = props.setNewData;
    useEffect(() => {
        refetch({scenarioIds: props.scenarioIds});
    }, [props.scenarioIds, refetch])

    useEffect(() => {
        const compareData:CompareData = {
            series: data.me.person.compare.map(s => {
                return {
                    description: s.description,
                    retirementAge: s.result.retirementAge,
                    steps: s.result.steps.map(x => {
                        return {
                            age: x.age,
                            year: x.year,
                            incomeTotal: x.incomeTotal,
                            expenseTotal: x.expensesTotal,
                            estateTax: x.estate.tax,
                            atax: x.estate.afterTax,
                            btax: x.estate.beforeTax,
                            ataxReal: x.estate.afterTaxReal,
                            estateTaxRate: x.estate.taxRate,
                            savingsRate: x.savingsRate,
                            dtiRatio: x.dtiRatio,
                            withdrawalRate: x.withdrawalRate,
                            financialAssetsTotal: x.financialAssetsTotal,
                            financialAssetsContribTotal: x.financialAssetsContribTotal,
                            taxMarginalRate: x.tax.marginalRate
                        }
                    })}
            })
        };
        setNewData(compareData);
    }, [data, setNewData]);

    return <></>
}