import {ECBasicOption} from "echarts/types/dist/shared";
import React, {useEffect, useRef, useState} from "react";
import * as echarts from "echarts";
import ChartSubject from "./ChartSubject";
import {Payload} from "echarts/types/src/util/types";
import useResizeObserver from "@react-hook/resize-observer";

const baseOptions:ECBasicOption = {
    //dataZoom: [{filterMode: 'filter', height: 10, moveHandleSize: 0, brushSelect: false, handleSize: "300%", throttle: 0, animation: false}],
    // toolbox: {
    //     feature: {
    //         saveAsImage: {
    //             title: "Save as image"
    //         },
    //         dataZoom: {
    //             title: {zoom: "Zoom", back: "Restore zoom"},
    //             yAxisIndex: "none",
    //         },
    //     }
    // },
    legend: {top: "bottom"},
    animation: true,
    animationDuration: () => 0
}

export function Chart(props: {option: ECBasicOption, style?: React.CSSProperties | undefined, subject?: ChartSubject | undefined }) {
    const [echartsInstance, setEchartsInstance] = useState<echarts.ECharts | null>();
    const [resizeTimeout, setResizeTimeout] = useState<any>();
    const chartDivRef = useRef<HTMLDivElement>(null);
    const subject = props.subject;

    useResizeObserver(chartDivRef, () => {
        if(resizeTimeout)
            clearTimeout(resizeTimeout);
        setResizeTimeout(setTimeout(() => {
            try {
                echartsInstance?.resize({
                    silent: true
                })
            }catch{}
        }, 200));
    });

    useEffect(() => {
        echartsInstance?.resize({
            silent: true
        })
    })

    useEffect(() => {
        if(chartDivRef?.current === null) return;
        const chart = echarts.init(chartDivRef.current);

        chart?.on('showTip', (params: any) => {subject?.notify(chart?.id, {type: "showTip", seriesIndex: 0, dataIndex: params.dataIndex})})
        .on('hideTip', () => {subject?.notify(chart?.id, {type: "hideTip"})})
        .on('dataZoom', function(param: any){
            const value = param.batch && param.batch.length === 1
                ? {start: param.batch[0].start, end: param.batch[0].end, dataZoomId: param.batch[0].dataZoomId}
                : {start: param.start, end: param.end, dataZoomId: param.dataZoomId};
            subject?.notify(chart?.id, {type: "dataZoom", ...value});
        });
        function onUpdate(chartId: string, event: Payload){
            if(chartId === chart?.id)
                return;

            chart?.dispatchAction(event,{silent: true} );
        }
        subject?.attach(onUpdate);

        setEchartsInstance(chart);

        return () => {
            setEchartsInstance(null);
            subject?.detach(onUpdate);
            chart.dispose();
        }
    }, [chartDivRef, subject])

    useEffect(() => {
        const option = echartsInstance?.getOption();
        echartsInstance?.setOption({...baseOptions, ...option, ...props.option}, {notMerge: true, silent: true, lazyUpdate: true});
    }, [echartsInstance, props.option])

    return <div ref={chartDivRef} style={props.style} />;
}