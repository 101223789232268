import {useCallback} from "react";
import {graphql} from "babel-plugin-relay/macro";
import {useMutation} from "react-relay/hooks";
import {handleMutation} from "../HandleMutation";
import {useOnBoardingCompletedMutation} from "./__generated__/useOnBoardingCompletedMutation.graphql";

const mutation = graphql`
    mutation useOnBoardingCompletedMutation{
        onBoardingCompleted {
            id
            status
        }
    }`;

export default function useOnBoardingCompleted(): [(onCompleted?: () => void) => void, boolean]{
    const [commit, committing] = useMutation<useOnBoardingCompletedMutation>(mutation);
    const callback = useCallback((onCompleted?: () => void) => {
        handleMutation("", "Update user", "user", "update", commit, {
            variables: {},
            onCompleted: () => {
                if(onCompleted)
                    onCompleted();
            }
        })
    },[commit])

    return [callback, committing];
}