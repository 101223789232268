import React, {useEffect, useState} from "react";
import {AgGridReact} from "ag-grid-react";
import {GridReadyEvent} from "ag-grid-community/dist/lib/events";
import {GridApi, ICellRendererParams, ValueSetterParams} from "ag-grid-community";
import {columnTypes, columnTypesComponents} from "../../Grids/ColumnTypes";
import {DeleteColumnDef} from "../../Grids/ColumnDefs";
import {onDragStopped, updateIfDiff} from "../../Grids/Utilities";
import {FinancialAsset} from "./FinancialAsset";

export default function FinancialAssetsGrid (props: {
    data: FinancialAsset[]
    update: (financialAsset: FinancialAsset) => boolean
    updateOrdinals: (ids: string[]) => void
    simple?: boolean
}) {
    const [gridApi, setGridApi] = useState<GridApi>();

    function onGridReady(event: GridReadyEvent) {
        setGridApi(event.api);
    }

    const accountTypes = [
        {key: "NonRegistered", value: "Non-registered"},
        {key: "Tfsa", value: "TFSA"},
        {key: "Rrsp", value: "RRSP / LIRA"},
        ];

    const advancedColumns = [
        {
        headerName: "Cash",
        children: [
            {
                headerName: "%",
                field: "percentCash",
                type: ['percentageColumn', 'importantColumn'],
                editable: true,
                valueSetter: (params: ValueSetterParams) => {
                    updateIfDiff(params, () => {
                        const percentCash = Math.max(0, Math.min(100, params.newValue ?? 0));
                        const percentFixedIncome = Math.max(0, params.data.percentFixedIncome + (100 - (percentCash + params.data.percentFixedIncome + params.data.percentEquity)));
                        const percentEquity = Math.max(0, params.data.percentEquity + (100 - (percentCash + percentFixedIncome + params.data.percentEquity)));
                        return props.update({
                            ...params.data,
                            percentCash: percentCash,
                            percentFixedIncome: percentFixedIncome,
                            percentEquity: percentEquity
                        })
                    }, {});
                }
            },
            {
                headerName: "RoR",
                field: "rorCash",
                type: ['percentageColumn'],
                editable: true,
                valueSetter: (params: ValueSetterParams) => updateIfDiff(params, props.update, {rorCash: params.newValue ?? 0})
            },
        ]
    },
        {
            headerName: "Fixed Income",
            children: [
                {
                    headerName: "%",
                    field: "percentFixedIncome",
                    type: ['percentageColumn', 'importantColumn'],
                    editable: true,
                    valueSetter: (params: ValueSetterParams) => {
                        updateIfDiff(params, () => {
                            const percentFixedIncome = Math.max(0, Math.min(100, params.newValue ?? 0));
                            const percentEquity = Math.max(0, params.data.percentEquity + (100 - (percentFixedIncome + params.data.percentCash + params.data.percentEquity)));
                            const percentCash = Math.max(0, params.data.percentCash + (100 - (percentFixedIncome + percentEquity + params.data.percentCash)));
                            return props.update({
                                ...params.data,
                                percentCash: percentCash,
                                percentFixedIncome: percentFixedIncome,
                                percentEquity: percentEquity
                            })
                        },{});
                    }
                },
                {
                    headerName: "RoR",
                    field: "rorFixedIncome",
                    type: ['percentageColumn'],
                    editable: true,
                    valueSetter: (params: ValueSetterParams) => updateIfDiff(params, props.update, {rorFixedIncome: params.newValue ?? 0})
                },
            ]
        },
        {
            headerName: "Equity",
            children: [
                {
                    headerName: "%",
                    field: "percentEquity",
                    type: ['percentageColumn', 'importantColumn'],
                    editable: true,
                    valueSetter: (params: ValueSetterParams) => {
                        updateIfDiff(params, () => {
                            const percentEquity = Math.max(0, Math.min(100, params.newValue ?? 0));
                            const percentCash = Math.max(0, params.data.percentCash + (100 - (percentEquity + params.data.percentFixedIncome + params.data.percentCash)));
                            const percentFixedIncome = Math.max(0, params.data.percentFixedIncome + (100 - (percentCash + percentEquity + params.data.percentFixedIncome)));
                            return props.update({
                                ...params.data,
                                percentCash: percentCash,
                                percentFixedIncome: percentFixedIncome,
                                percentEquity: percentEquity
                            })
                        }, {});
                    }
                },
                {
                    headerName: "RoR",
                    field: "rorEquity",
                    type: ['percentageColumn'],
                    editable: true,
                    valueSetter: (params: ValueSetterParams) => updateIfDiff(params, props.update, {rorEquity: params.newValue ?? 0})
                },
            ]
        },
        DeleteColumnDef()
    ];


    function getAdvancedColumns() {
        if(props.simple)
            return [];
        return advancedColumns;
    }

    const initialState = {
        columnDefs: [
            {
                headerName: "Description",
                field: "description",
                editable: !props.simple,
                rowDrag: !props.simple,
                valueSetter: (params: ValueSetterParams) => updateIfDiff(params, props.update, {description: params.newValue})
            },
            {
                headerName: "Type",
                field: "type",
                type: "selectColumn",
                editable: !props.simple,
                width: 150,
                cellRenderer: (params: ICellRendererParams) => accountTypes.filter(x => x.key === params.data.type)[0].value,
                valueSetter: (params: ValueSetterParams) => updateIfDiff(params, props.update,{...params.data, type: params.newValue}),
                cellEditorParams: {
                    values: accountTypes
                },
            },
            {
                headerName: "Amount",
                field: "amount",
                type: ['currencyColumn', 'importantColumn'],
                editable: true,
                valueSetter: (params: ValueSetterParams) => updateIfDiff(params, props.update,{...params.data, amount: params.newValue === "" ? 0 : params.newValue})
            },
            {
                headerName: "Cost",
                field: "cost",
                type: ['currencyColumn', 'importantColumn'],
                editable: true,
                valueSetter: (params: ValueSetterParams) => updateIfDiff(params, props.update,{...params.data, cost: params.newValue === "" ? 0 : params.newValue})
            },
            ...getAdvancedColumns()
        ],
        columnTypes: columnTypes,
        components: columnTypesComponents,
        defaultColDef: {
            resizable: true,
        },
    };

    const [gridState] = useState(initialState);

    useEffect(() => {
        gridApi?.setRowData(props.data)
    }, [gridApi, props.data])

    return <div className="ag-theme-material" style={{height: "100%", overflow: "hidden"}}>
        <AgGridReact
            stopEditingWhenGridLosesFocus={true}
            rowData={props.data}
            singleClickEdit={true}
            onDragStopped={event => onDragStopped(event, props.updateOrdinals)}
            suppressMoveWhenRowDragging={true}
            rowDragManaged={true}
            onGridReady={onGridReady}
            frameworkComponents={gridState.components}
            columnDefs={gridState.columnDefs}
            columnTypes={gridState.columnTypes}
            defaultColDef={gridState.defaultColDef}
            suppressNoRowsOverlay={true}
        />
    </div>
}