import {graphql} from "babel-plugin-relay/macro";
import {useMutation} from "react-relay/hooks";
import {useCallback} from "react";
import {handleMutation} from "../HandleMutation";
import { useFinancialAssetRemoveMutation } from "./__generated__/useFinancialAssetRemoveMutation.graphql";

const removeMutation = graphql`
    mutation useFinancialAssetRemoveMutation($scenarioId: ID!, $financialAssetId: String!){
        financialAssetRemove(scenarioId: $scenarioId, financialAssetId: $financialAssetId){
            assets {
                ...FinancialAssetsUpdate
            }
            result {
                ...ScenarioResultsDataResult
            }
            ...ScenarioDetailedSummary
            ...ScenarioHierarchicalMenu
        }
    }`;

export default function useFinancialAssetRemove():[(scenarioId: string, id: string, description: string) => void, boolean]{
    const [commit, committing] = useMutation<useFinancialAssetRemoveMutation>(removeMutation);
    const callback = useCallback((scenarioId: string, id: string, description: string) => {
        handleMutation(id, description, "financial asset", "remove", commit, {
            variables: {scenarioId: scenarioId, financialAssetId: id}
        })
    }, [commit]);

    return [callback, committing];
}