import React, {useEffect, useState} from "react";
import {AgGridReact} from "ag-grid-react";
import {GridReadyEvent} from "ag-grid-community/dist/lib/events";
import {GridApi, ValueSetterParams} from "ag-grid-community";
import {columnTypes, columnTypesComponents} from "../Grids/ColumnTypes";
import {updateIfDiff} from "../Grids/Utilities";

export interface Upe {
    year: number
    amount: number
}

export default function UpeGrid (props: {
    data: Upe[]
    update: (value: Upe) => boolean
})
{
    const [gridApi, setGridApi] = useState<GridApi>();

    function onGridReady(event: GridReadyEvent) {
        setGridApi(event.api);
    }

    const [gridState] = useState({
        columnDefs: [
            {   headerName: "Year",
                field: "year",
                width: 100,
                editable: false
            },
            {   headerName: "Amount",
                field: "amount",
                type: ['currencyColumn','importantColumn'],
                editable: true,
                valueSetter:  (params:ValueSetterParams) =>  updateIfDiff(params, props.update, {amount: params.newValue !== "" ? params.newValue ?? 0 : 0})
            },
        ],
        columnTypes: columnTypes,
        components: columnTypesComponents,
        defaultColDef: {},
    });

    useEffect(() => {
        gridApi?.setRowData(props.data)
    }, [gridApi, props.data])

    return <div className="ag-theme-material" style={{height: "100%"}}>
        <AgGridReact
            stopEditingWhenGridLosesFocus={true}
            singleClickEdit={true}
            enterMovesDown={true}
            enterMovesDownAfterEdit={true}
            onGridReady={onGridReady}
            frameworkComponents={gridState.components}
            columnDefs={gridState.columnDefs}
            columnTypes={gridState.columnTypes}
            defaultColDef={gridState.defaultColDef} />
    </div>
}