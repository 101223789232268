import React from "react";
import PersonUpdate from "../Personal/PersonUpdate";
import {FormInstance, Typography} from "antd";

const {Title} = Typography;

export default function AboutYouStep(props: {form: FormInstance}) {
    return (
        <div>
            <Title>About You</Title>
            <PersonUpdate formProps={{form: props.form}} tailProps={{hidden: false}} />
        </div>
    );
}