import React from "react";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay/hooks";
import {NetCashFlowChartResult$key} from "./__generated__/NetCashFlowChartResult.graphql";
import {Tooltip} from "../Charts/Tooltip";
import {ChartTheme} from "../Charts/ChartTheme";
import {Chart} from "../../Chart/Chart";
import ChartSubject from "../../Chart/ChartSubject";

const query =  graphql`
    fragment NetCashFlowChartResult on Result {
        retirementAge
        steps {
            age
            year
            expensesTotal
            debtsPaidTotal
            tax {
                total
            }
            income {
                name
                amount
            }
            oas {
                payment
            }
            cpp {
                payment
            }
            financialAssetsContribTotal
            financialAssets {
                name
                contribution
                type
            }
            realAssets {
                cash
            }
        }
    }
`;

export default function NetCashFlowChart (props: {data: NetCashFlowChartResult$key, subject?: ChartSubject}) {
    const data = useFragment(query, props.data);

    if(data.steps.length === 0)
        return null;

    const steps = data.steps.map(x => {return {...x}})

    const xAxis = [
        [...steps.map(x => x.age)],
        [...steps.map(x => x.year)],
    ]

    const source = [
        ...steps[0].income.map((x, i) => [...steps.map(y => y.income[i].amount)])
    ]

    const assets = steps.map(x => {
        const rrsp = x.financialAssets.filter(x => x.type === "Rrsp" && x.contribution < 0).map(x => -x.contribution).reduce((pv, cv) => pv + cv, 0);
        const tfsa = x.financialAssets.filter(x => x.type === "Tfsa" && x.contribution < 0).map(x => -x.contribution).reduce((pv, cv) => pv + cv, 0);
        const nts = x.financialAssets.filter(x => x.type === "NonRegistered" && x.contribution < 0).map(x => -x.contribution).reduce((pv, cv) => pv + cv, 0);

        return {rrsp: rrsp, tfsa: tfsa, nts: nts }
    });

    const realAssets = steps.map(x => {

        const purchase = x.realAssets.filter(y => y.cash < 0).reduce((pv, cv) => pv + cv.cash, 0);
        const sale = x.realAssets.filter(y => y.cash > 0).reduce((pv, cv) => pv + cv.cash, 0);

        return {purchase: purchase ?? 0, sale: sale ?? 0}
    })

    const title = "Net Cash Flow";

    const option = {
        ...ChartTheme,
        title: {
            text: title
        },
        backgroundColor: "#FFFFFF",
        grid: [
            {
                right: '2%',
                left: '60px',
            }
        ],
        tooltip: Tooltip(xAxis, title, 1000),
        xAxis: [
            {type: 'category',
            data: xAxis[0]
            },
            {type: 'category',
            data: xAxis[1]
            },
        ],
        yAxis: [
            {
                scale: true,
                gridIndex: 0,
                position: "left",
                type: "value",
                splitNumber: 5,
                min: 0,
                name: "Thousands  $",
                nameLocation: "center",
                nameGap: 35,
                axisLabel: {
                    formatter: (value:number) => value / 1000
                }
            },
        ],
        series: [
            {
                type: "bar",
                stack: "income",
                symbol: "none",
                seriesLayoutBy: "row",
                name: "CPP & OAS",
                itemStyle: {
                    borderWidth: 1,
                    borderColor: "#000"
                },
                data: steps.map(x => x.cpp.payment + x.oas.payment)
            },
            ...data.steps[0].income.map((x, i) => {
                return {
                    type: "bar",
                    stack: "income",
                    symbol: "none",
                    seriesLayoutBy: "row",
                    name: x.name,
                    data: source[i]
                }
            }),
            {
                type: "bar",
                stack: "income",
                symbol: "none",
                seriesLayoutBy: "row",
                name: "NTS",
                data: assets.map(x => x.nts)
            },
            {
                type: "bar",
                stack: "income",
                symbol: "none",
                seriesLayoutBy: "row",
                name: "TFSA",
                data: assets.map(x => x.tfsa)
            },
            {
                type: "bar",
                stack: "income",
                symbol: "none",
                seriesLayoutBy: "row",
                name: "RRSP",
                data: assets.map(x => x.rrsp)
            },
            {
                type: "bar",
                stack: "income",
                symbol: "none",
                seriesLayoutBy: "row",
                name: "Property Sale",
                data: realAssets.map(x => x.sale)
            },
            {
                type: "line",
                color: "#777777",
                stack: "expenses",
                symbol: "none",
                seriesLayoutBy: "row",
                name: "Property Purchase",
                data: realAssets.map(x => -x.purchase),
                lineStyle: {
                    width: 0,
                },
            },
            {
                type: "line",
                color: "#555555",
                stack: "expenses",
                symbol: "none",
                seriesLayoutBy: "row",
                name: "Tax",
                data: steps.map(x => x.tax.total),
                lineStyle: {
                    type: "solid"
                },
            },
            {
                type: "line",
                stack: "expenses",
                symbol: "none",
                seriesLayoutBy: "row",
                name: "Expenses",
                data: steps.map((x) => x.expensesTotal + x.debtsPaidTotal),
                lineStyle: {
                    type: "solid",
                },
            },
            {
                type: "line",
                stack: "income",
                silent: true,
                markPoint: {
                    data: [
                        {
                            symbol: "diamond",
                            symbolSize: 10,
                            value: "RRIF min withdrawal",
                            label: {
                                position: "top"
                            },
                            xAxis: "71",
                            y: 100
                        }
                    ]
                },
                markArea: {
                    silent: true,
                    itemStyle: {
                        color: "rgba(226,226,226,0.4)"
                    },
                    data: [
                        [{
                            name: "Retired",
                            label: {
                                distance: 30,
                                color: "#989898"
                            },
                            xAxis: `${data.retirementAge}`,
                        },
                        {
                            xAxis: "100"
                        }]
                    ]
                },
            }
        ]
    };

    return <Chart style={{height: "100%"}} option={option} subject={props.subject} />
}