import React, {useContext, useState} from "react";
import {graphql} from "babel-plugin-relay/macro";
import {useLazyLoadQuery} from "react-relay/hooks";
import {Button, DatePicker, Divider, Form, FormItemProps, FormProps, Select, Space} from "antd";
import { PersonUpdateQuery } from "./__generated__/PersonUpdateQuery.graphql";
import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons'
import usePersonUpdate from "../Mutations/usePersonUpdate";
import AppContext from "../../AppContext";

const {Option} = Select;
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
};

const tailLayout = {
    wrapperCol: { offset: 8, span: 8 },
};

const query =  graphql`
    query PersonUpdateQuery {
        me {
            person {
                id
                firstName
                lastName
                dob
                province {
                    id
                }
            }
        }
        provinces {
            id
            name
        }
    }`;


export default function PersonUpdate(props: {formProps?: FormProps, tailProps?: FormItemProps}) {
    const app = useContext(AppContext);
    const data = useLazyLoadQuery<PersonUpdateQuery>(query, {});
    const [commit, isInFlight] = usePersonUpdate();
    const [isUpdated, setIsUpdated] = useState(false);

    const onFinish = (values:any) => {
        commit(app.scenario.id,
            {
                id: data.me.person.id,
                firstName: "",
                lastName: "",
                dob: (values.dob as moment.Moment).format("yyyy-MM-DD"),
                provinceId: values.provinceId
            },
            () => {
                setIsUpdated(false)
            });
    }

    const onChange = () => {
        setIsUpdated(true);
    }

    function disabledDate(current: moment.Moment) {
        return current && (current > moment().subtract(13, "years") || current < moment("1900-01-01"));
    }

    return (
        <Form {...layout}
              {...props.formProps}
             initialValues={{
                 dob: moment(data.me.person.dob as string, 'YYYY-MM-DD'),
                 provinceId: data.me.person.province.id
             }}
              onFinish={onFinish}
              onChange={onChange}
        >
             <Form.Item
                label="Date of birth"
                name="dob"
                extra={"Used in calculating items that relate to age such as RRIF minimum withdrawals, CPP and OAS benefits start date."}
                rules={[{ required: true, message: 'Please input your date of birth.' }]}>
                <DatePicker disabledDate={disabledDate} picker={"month"} onChange={() => setIsUpdated(true)}/>
            </Form.Item>
            <Form.Item
                label="Province"
                name="provinceId"
                extra={"Used in estimating provincial taxes."}
                rules={[{ required: true, message: 'Please input your province.' }]}>
                <Select onChange={() => setIsUpdated(true)}>
                    {data.provinces.map(x => <Option key={x?.id} value={x?.id ?? ""}  >{x?.name}</Option>)}
                </Select>
            </Form.Item>
            <Divider />
            <Form.Item {...tailLayout} {...props.tailProps}>
                <Space>
                    <Button type="primary" htmlType="submit" disabled={isInFlight || !isUpdated}>
                        {isInFlight ? <LoadingOutlined /> : "Apply"}
                    </Button>
                    <div>
                  </div>
                </Space>
            </Form.Item>
        </Form>)
};