import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Auth0Provider} from "@auth0/auth0-react";
import AppConfig from './AppConfig';

const providerConfig = {
    domain: AppConfig.Auth.Authority,
    clientId: AppConfig.Auth.ClientId,
    audience: AppConfig.Auth.Audience,
    scope: AppConfig.Auth.Realm,
    redirectUri: window.location.origin
};

ReactDOM.render(
  <React.StrictMode>
      <Auth0Provider {...providerConfig}>
        <App />
      </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
