import React from "react";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay/hooks";
import {EstateChartResult$key} from "./__generated__/EstateChartResult.graphql";
import {Tooltip} from "./Tooltip";
import {ChartTheme} from "./ChartTheme";
import {Chart} from "../../Chart/Chart";
import ChartSubject from "../../Chart/ChartSubject";

const query =  graphql`
    fragment EstateChartResult on Result {
        retirementAge
        steps {
            age
            year
            cash
            debtsOwingTotal
            financialAssetsTotal
            financialAssets {
                type
                value
            }
            realAssetsTotal
            estate {
                afterTax
                beforeTax
                afterTaxReal
            }
        }
    }
`;

export default function EstateChart(props: {data: EstateChartResult$key, subject?: ChartSubject}) {
    const data = useFragment(query, props.data);

    if(data.steps.length === 0)
        return null;


    const xAxis = [
        [...data.steps.map(x => x.age)],
        [...data.steps.map(x => x.year)],
    ]
    const source = [
        [...data.steps.map(y => y.financialAssetsTotal)],
        [...data.steps.map(y => y.realAssetsTotal)],
        [...data.steps.map(y => -y.debtsOwingTotal)],
        [...data.steps.map(y => y.estate.afterTax)],
        [...data.steps.map(y => y.cash)],
        [...data.steps.map(y => y.financialAssets.filter(x => x.type === "NonRegistered").reduce((pv, cv) => pv + cv.value, 0))],
        [...data.steps.map(y => y.financialAssets.filter(x => x.type === "Tfsa").reduce((pv, cv) => pv + cv.value, 0))],
        [...data.steps.map(y => y.financialAssets.filter(x => x.type === "Rrsp").reduce((pv, cv) => pv + cv.value, 0))],
    ]

    const title = "Net Worth";

    const option = {
        ...ChartTheme,
        title: {
            text: title
        },
        backgroundColor: "#FFFFFF",
        grid: [
            {
                right: '2%',
                left: '60px',
            }
        ],
        tooltip: Tooltip(xAxis, "Net Worth", 1000000),
        xAxis: [
            {type: 'category',
                data: xAxis[0]
            },
            {type: 'category',
                data: xAxis[1]
            },
        ],
        yAxis: [
            {
                scale: true,
                gridIndex: 0,
                position: "left",
                type: "value",
                splitNumber: 5,
                min: 0,
                name: "Millions  $",
                nameLocation: "center",
                nameGap: 35,
                axisLabel: {
                    formatter: (value:number) => value / 1000000
                }
            },
        ],
        series: [
            {
                type: "bar",
                stack: "networth",
                symbol: "none",
                seriesLayoutBy: "row",
                name: `Property`,
                data: source[1]
            },
            {
                type: "bar",
                stack: "networth",
                symbol: "none",
                seriesLayoutBy: "row",
                name: `RRSP`,
                data: source[7]
            },
            {
                type: "bar",
                stack: "networth",
                symbol: "none",
                seriesLayoutBy: "row",
                name: `TFSA`,
                data: source[6]
            },
            {
                type: "bar",
                stack: "networth",
                symbol: "none",
                seriesLayoutBy: "row",
                name: `NTS`,
                data: source[5]
            },
            {
                type: "bar",
                stack: "networth",
                symbol: "none",
                seriesLayoutBy: "row",
                name: `Debt`,
                data: source[2]
            },
            {
                type: "bar",
                stack: "networth",
                symbol: "none",
                seriesLayoutBy: "row",
                name: `Cash`,
                data: source[4]
            },
            {
                type: "line",
                color: "#555555",
                symbol: "none",
                seriesLayoutBy: "row",
                name: `Estate after tax`,
                data: source[3],
                lineStyle: {
                    type: "solid",
                },
            },
            {
                type: "line",
                symbol: "none",
                lineStyle: {
                    width: 0
                },
                markPoint: {
                    data: [
                        {
                            symbol: "diamond",
                            symbolSize: 10,
                            value: "RRIF min withdrawal",
                            name: "Testing",
                            label: {
                                position: "top"
                            },
                            xAxis: "71",
                            y: 100
                        }
                    ]
                },
                markArea: {
                    silent: true,
                    itemStyle: {color: "rgba(226,226,226,0.4)"},
                    data: [
                        [{
                            name: "Retired",
                            label: {
                                distance: 30,
                                color: "#989898"
                            },
                            xAxis: `${data.retirementAge}`,
                        },
                            {
                                xAxis: "100"
                            }]
                    ]
                },
            }
        ]
        };

    return <Chart style={{height: "100%"}} option={option} subject={props.subject} />
}