import React, {ReactChild, useContext, useEffect, useState} from "react";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay/hooks";
import {FinancialAssetsUpdate$key} from "./__generated__/FinancialAssetsUpdate.graphql";
import AppContext from "../../../../AppContext";
import {FinancialAsset} from "./FinancialAsset";
import {Button, Space, Typography} from "antd";
import { StockOutlined } from '@ant-design/icons';
import FinancialAssetsGrid from "./FinancialAssetsGrid";
import EditorLayout from "../../../EditorLayout";
import useFinancialAssetUpdate from "../../../Mutations/useFinancialAssetUpdate";
import useFinancialAssetRemove from "../../../Mutations/useFinancialAssetRemove";
import useFinancialAssetUpdateOrdinals from "../../../Mutations/useFinancialAssetUpdateOrdinals";

const {Text} = Typography;

const query =  graphql`
    fragment FinancialAssetsUpdate on Assets {
        id
        financialAssets {
            id
            description
            type
            amount
            cost
            percentCash
            rorCash
            percentFixedIncome
            rorFixedIncome
            percentEquity
            rorEquity
        }
    }
`;

export interface AssetsUpdateProps {
    data: FinancialAssetsUpdate$key
    simple?: boolean
    chart?: ReactChild
}

export default function FinancialAssetsUpdate (props: AssetsUpdateProps) {
    const app = useContext(AppContext);
    const data = useFragment(query, props.data);
    const [dataList, setDataList] = useState<FinancialAsset[]>([]);

    const [commitUpdate, committingUpdate] = useFinancialAssetUpdate();
    const [commitRemove, committingRemove] = useFinancialAssetRemove();
    const [commitUpdateOrdinals, committingUpdateOrdinals] = useFinancialAssetUpdateOrdinals();

    const defaultValues:FinancialAsset = {
        id: "00000000-0000-0000-0000-000000000000",
        description: "Financial Asset #" + (data.financialAssets.length+1),
        type: "NonRegistered",
        amount: 0,
        cost: 0,
        percentCash: 10,
        percentEquity: 70,
        percentFixedIncome: 20,
        rorCash: 1,
        rorEquity: 6.5,
        rorFixedIncome: 2.5,
        onRemove: () => {}
    };

    useEffect(() => {
        setDataList(data.financialAssets.map(x => { return {
            id: x.id,
            description: x.description,
            type: x.type,
            amount: x.amount,
            cost: x.cost,
            percentCash: x.percentCash,
            rorCash: x.rorCash,
            percentFixedIncome: x.percentFixedIncome,
            rorFixedIncome: x.rorFixedIncome,
            percentEquity: x.percentEquity,
            rorEquity: x.rorEquity,
            onRemove: () => commitRemove(app.scenario.id, x.id, x.description)
        }}));
    }, [data, commitRemove, app.scenario.id]);

    const onUpdate = (value: FinancialAsset) => {
        commitUpdate(app.scenario.id, value);
        return true;
    }

    const onUpdateOrdinals = (order: string[]) => {
        commitUpdateOrdinals(app.scenario.id, data.id, order);
    }

    const onAdd = () => {
        onUpdate(defaultValues);
    }

    return (
        <EditorLayout
            controls={props.simple ? undefined :
                <Space align="center">
                    <Button type={"primary"} onClick={onAdd} disabled={committingUpdate || committingRemove || committingUpdateOrdinals}><StockOutlined /> Add Financial Asset</Button>
                    <Text type={"secondary"}>Financial assets are non-physical assets such as stocks or bonds.</Text>
                </Space>
            }
            editor={<FinancialAssetsGrid data={dataList} update={onUpdate} updateOrdinals={onUpdateOrdinals} simple={props.simple}/>}
            chart={props.chart} />
    )
};