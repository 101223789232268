/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type useScenarioAddMutationVariables = {
    personId: string;
    description: string;
    sourceScenarioId?: string | null;
};
export type useScenarioAddMutationResponse = {
    readonly scenarioAdd: {
        readonly scenarios: ReadonlyArray<{
            readonly id: string;
            readonly description: string;
        }>;
    };
};
export type useScenarioAddMutation = {
    readonly response: useScenarioAddMutationResponse;
    readonly variables: useScenarioAddMutationVariables;
};



/*
mutation useScenarioAddMutation(
  $personId: ID!
  $description: String!
  $sourceScenarioId: ID
) {
  scenarioAdd(personId: $personId, description: $description, sourceScenarioId: $sourceScenarioId) {
    scenarios {
      id
      description
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "description"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "personId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sourceScenarioId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "description",
    "variableName": "description"
  },
  {
    "kind": "Variable",
    "name": "personId",
    "variableName": "personId"
  },
  {
    "kind": "Variable",
    "name": "sourceScenarioId",
    "variableName": "sourceScenarioId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Scenario",
  "kind": "LinkedField",
  "name": "scenarios",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useScenarioAddMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Person",
        "kind": "LinkedField",
        "name": "scenarioAdd",
        "plural": false,
        "selections": [
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "useScenarioAddMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Person",
        "kind": "LinkedField",
        "name": "scenarioAdd",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "06e005aca808cda5c6551f176ac087a4",
    "id": null,
    "metadata": {},
    "name": "useScenarioAddMutation",
    "operationKind": "mutation",
    "text": "mutation useScenarioAddMutation(\n  $personId: ID!\n  $description: String!\n  $sourceScenarioId: ID\n) {\n  scenarioAdd(personId: $personId, description: $description, sourceScenarioId: $sourceScenarioId) {\n    scenarios {\n      id\n      description\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e635a5aa46c647dcbda89dc4b3dcc0a8';
export default node;
