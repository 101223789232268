/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FinancialAssetType = "NonRegistered" | "Rrsp" | "Tfsa" | "%future added value";
export type FinancialAssetsChartResult = {
    readonly retirementAge: number;
    readonly steps: ReadonlyArray<{
        readonly age: number;
        readonly year: number;
        readonly financialAssets: ReadonlyArray<{
            readonly name: string;
            readonly value: number;
            readonly type: FinancialAssetType | null;
        }>;
    }>;
    readonly " $refType": "FinancialAssetsChartResult";
};
export type FinancialAssetsChartResult$data = FinancialAssetsChartResult;
export type FinancialAssetsChartResult$key = {
    readonly " $data"?: FinancialAssetsChartResult$data;
    readonly " $fragmentRefs": FragmentRefs<"FinancialAssetsChartResult">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FinancialAssetsChartResult",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "retirementAge",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ResultStep",
      "kind": "LinkedField",
      "name": "steps",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "age",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "year",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AssetResult",
          "kind": "LinkedField",
          "name": "financialAssets",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Result",
  "abstractKey": null
};
(node as any).hash = '52710868874a398c21d3e210cf682cec';
export default node;
