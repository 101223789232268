import {graphql} from "babel-plugin-relay/macro";
import {useMutation} from "react-relay";
import { useRealAssetUpdateOrdinalsMutation } from "./__generated__/useRealAssetUpdateOrdinalsMutation.graphql";
import {useCallback} from "react";
import {handleMutation} from "../HandleMutation";

const updateOrdinalMutation = graphql`
    mutation useRealAssetUpdateOrdinalsMutation($scenarioId: ID!, $inputType: InputType!, $ids: [String!]!){
        ordinalUpdate(scenarioId: $scenarioId, inputType: $inputType, ids: $ids ){
            assets {
                ...RealAssetsUpdate
            }
            result {
                ...ScenarioResultsDataResult
            }
            ...ScenarioDetailedSummary
            ...ScenarioHierarchicalMenu
        }
    }
`;

export default function useRealAssetsUpdateOrdinals():[(scenarioId: string, id: string, ids: string[]) => void, boolean]{
    const [commit, committing] = useMutation<useRealAssetUpdateOrdinalsMutation>(updateOrdinalMutation);
    const callback = useCallback((scenarioId: string, id: string, ids: string[]) => {
        handleMutation(id, "Financial Asset", "order", "update", commit, {
            variables: {scenarioId: scenarioId, inputType: "RealAsset", ids: ids}
        })
    }, [commit]);

    return [callback, committing];
}