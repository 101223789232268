import React, {useContext, useEffect} from "react";
import {useLazyLoadQuery} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {Card, Layout, Form} from 'antd';
import { CrownOutlined, ShopOutlined, DollarOutlined, ReadOutlined, EnvironmentOutlined, QuestionOutlined, FlagOutlined, SmileOutlined, BankOutlined, RiseOutlined, HomeOutlined, CreditCardOutlined} from '@ant-design/icons';

import AboutYouStep from "./AboutYouStep";
import AssumptionsStep from "./AssumptionsStep";
import FinishStep from "./FinishStep";
import useOnBoardingCompleted from "../Mutations/useOnBoardingCompleted";
import AppContext from "../../AppContext";
import InvestmentsStep from "./InvestmentsStep";
import WelcomeStep from "./WelcomeStep";
import Wizard, {IWizardStep} from "./Wizard/Wizard";

import {OnBoardingQuery} from "./__generated__/OnBoardingQuery.graphql";
import { Redirect } from "react-router-dom";
import LogoutButton from "../LogoutButton";

const {Header, Content} = Layout;

const query = graphql`
    query OnBoardingQuery {
        me {
            person {
                id
                defaultScenario {
                    id
                    description
                    result {
                        ...NetCashFlowChartResult
                        ...EstateChartResult
                        ...FinancialAssetsChartResult
                    }
                    assets {
                        ...FinancialAssetsUpdate
                    }
                }
            }
        }
    }`;

export default function OnBoarding() {
    const app = useContext(AppContext);
    const data = useLazyLoadQuery<OnBoardingQuery>(query, {});
    const [completeOnBoarding] = useOnBoardingCompleted();
    const [aboutYouForm] = Form.useForm();
    const [assumptionsForm] = Form.useForm();

    useEffect(() => {
        if(app.scenario.id === "00000000-0000-0000-0000-000000000000"){
            const scenario = data.me.person.defaultScenario;
            app.setScenario({id: scenario.id, name: scenario.description});
        }
    }, [data, app])

    const steps:IWizardStep[] = [
        {
            icon:   <EnvironmentOutlined />,
            title: "Welcome",
            content: <WelcomeStep />
        },
        {
            icon: <SmileOutlined />,
            title: "About You",
            content: <AboutYouStep form={aboutYouForm} />,
            onNext: () => {
                const errors = aboutYouForm.getFieldsError().map(x => x.errors).reduce((previousValue, currentValue) => previousValue + currentValue.length, 0);

                if(errors > 0)
                    return false;

                aboutYouForm.submit();
                return true;
            },
        },
        {
            icon: <QuestionOutlined />,
            title: "Assumptions",
            content: <AssumptionsStep form={assumptionsForm} />,
            onNext: () => {
                const errors = assumptionsForm.getFieldsError().map(x => x.errors).reduce((previousValue, currentValue) => previousValue + currentValue.length, 0);

                if(errors > 0)
                    return false;

                assumptionsForm.submit();
                return true;
            },
        },
        {
            icon: <BankOutlined />,
            title: "Net Worth",
            content: {
                key: "1",
                direction: "vertical",
                steps: [
                    {
                        icon: <RiseOutlined />,
                        title: "Investments",
                        content: <InvestmentsStep gridData={data.me.person.defaultScenario.assets} chartData={data.me.person.defaultScenario.result} />
                    },
                    {
                        icon: <HomeOutlined />,
                        title: "Property",
                        content: <div>Property</div>
                    },
                    {
                        icon: <CreditCardOutlined />,
                        title: "Debt",
                        content: <div>Debt</div>
                    },
                    {
                        icon: <ReadOutlined />,
                        title: "Review",
                        content: <div>Net Worth Review</div>
                    },
                ]
            },
        },
        {
            icon: <DollarOutlined />,
            title: "Net Cash Flow",
            content: {
                key: "2",
                direction: "vertical",
                steps: [
                    {
                        icon: <ShopOutlined />,
                        title: "Expenses",
                        content: <div>Expenses</div>
                    },
                    {
                        icon: <DollarOutlined />,
                        title: "Income",
                        content: <div>Income</div>
                    },
                    {
                        icon: <ReadOutlined />,
                        title: "Review",
                        content: <div>Cash Flow Review</div>
                    },
                ]
            },
        },
        {
            icon: <CrownOutlined />,
            title: "Benefits",
            content: {
                direction: "vertical",
                steps: [
                    {
                        icon: <ShopOutlined />,
                        title: "CPP",
                        content: <div>Canadian Pension Plan</div>
                    },
                    {
                        icon: <DollarOutlined />,
                        title: "OAS",
                        content: <div>Old age security</div>
                    },
                    {
                        icon: <ReadOutlined />,
                        title: "Review",
                        content: <div>Cash Flow Review</div>
                    },
                ]
            },
        },
        {
            icon: <FlagOutlined />,
            title: "Finish",
            content: <FinishStep />,
            onNext: () => {
                completeOnBoarding();
                return true;
            },
        }
    ];

    return (
        <Layout style={{height: "100%", display: "flex", flexFlow: "column"}}>
            <Header style={{flex: "0 0 55px"}}>
                <Card bordered={false}>
                    <div style={{display: "flex", flexFlow: "row"}}>
                        <div style={{flex: "1 1 50%"}}>
                            Wealth.li
                        </div>
                        <div style={{textAlign: "right", flex: "1 1 50%"}}>
                            <LogoutButton />
                        </div>
                    </div>
                </Card>
            </Header>
            <Content style={{flex: "1 1 100%"}}>
                <Wizard key={"0"} direction={"horizontal"} steps={steps} finishedComponent={<Redirect to={"/scenario/edit"} />}/>
            </Content>
        </Layout>
    )
};