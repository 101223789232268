import {useCallback, useContext} from "react";
import {graphql} from "babel-plugin-relay/macro";
import {useMutation} from "react-relay/hooks";
import {useScenarioAddMutation} from "./__generated__/useScenarioAddMutation.graphql";
import {handleMutation} from "../HandleMutation";
import AppContext from "../../AppContext";

const mutation = graphql`
    mutation useScenarioAddMutation($personId: ID!, $description: String!, $sourceScenarioId: ID){
        scenarioAdd(personId: $personId, description: $description, sourceScenarioId: $sourceScenarioId){
            scenarios {
                id
                description
            }
        }
    }`;

export default function useScenarioAdd(): [(personId: string, id: (string | null), description: string, onCompleted?: () => void) => void, boolean]{
    const app = useContext(AppContext);
    const [commit, committing] = useMutation<useScenarioAddMutation>(mutation);
    const callback = useCallback((personId: string, id: string | null, description: string, onCompleted?: () => void) => {
        handleMutation("", "New scenario", "scenario", "update", commit, {
            variables: {
                personId: personId,
                description: description,
                sourceScenarioId: id
            },
            onCompleted: response => {
                const length = response.scenarioAdd.scenarios.length;
                const scenario = response.scenarioAdd.scenarios[length-1];
                app.setScenario({id: scenario.id, name: scenario.description});
                if(onCompleted)
                    onCompleted();
            }
        })
    }, [commit, app]);

    return [callback, committing];
}