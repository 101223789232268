import React, {ReactChild} from "react";
import {useFragment} from "react-relay/hooks";
import {ScenarioEditorQuery$key} from "./__generated__/ScenarioEditorQuery.graphql";
import {graphql} from "babel-plugin-relay/macro";
import {Route, Switch, useLocation} from "react-router-dom";
import IncomeUpdate from "./Income/IncomeUpdate";
import ExpensesUpdate from "./Expenses/ExpensesUpdate";
import DebtsUpdate from "./Debts/DebtsUpdate";
import ScenarioDetailedSummary from "./ScenarioDetailedSummary";
import NetCashFlowChart from "./Income/NetCashFlowChart";
import FinancialAssetsUpdate from "./Assets/FinancialAssets/FinancialAssetsUpdate";
import RealAssetsUpdate from "./Assets/RealAssets/RealAssetsUpdate";
import RealAssetsChart from "./Assets/RealAssets/RealAssetsChart";
import {Card, Layout} from "antd";
import {CSSTransition, SwitchTransition} from "react-transition-group";
import ChartSubject from "../Chart/ChartSubject";
import ScenarioEditorMenu from "./ScenarioEditorMenu";
import FinancialAssetsChart from "./Assets/FinancialAssets/FinancialAssetsChart";
import ExpensesChart from "./Expenses/ExpensesChart";
import EstateChart from "./Charts/EstateChart";
import DebtsChart from "./Debts/DebtsChart";

const {Content} = Layout;

export interface ScenarioProps {
    scenario: ScenarioEditorQuery$key
}

const subject = new ChartSubject();
const query = graphql`fragment ScenarioEditorQuery on Scenario
{
    id
    description
    ...ScenarioDetailedSummary
    ...ScenarioSummary
    ...ScenarioUpdate
    income {
        id
        ...IncomeUpdate
    }
    expenses {
        id
        ...ExpensesUpdate
    }
    assets {
        id
        ...RealAssetsUpdate
        ...FinancialAssetsUpdate
    }
    debts {
        id
        ...DebtsUpdate
    }
    benefits {
        id
        ...BenefitsUpdate
    }
    result {
        id
        ...ScenarioResultsDataResult
        ...NetCashFlowChartResult
        ...ExpensesChartResult
        ...FinancialAssetsChartResult
        ...RealAssetsChartResult
        ...DebtsChartResult
        ...EstateChartResult
    }
    ...ScenarioDetailedSummary
}`;

export function ScenarioEditor(props: ScenarioProps) {
    const loc = useLocation();
    const data = useFragment<ScenarioEditorQuery$key>(query, props.scenario);

    const charts = (
        <div style={{height: "100%", display: "flex", flexDirection: "row"}}>
            <div style={{height: "100%", width: "50%"}}>
                <NetCashFlowChart data={data.result} subject={subject} />
            </div>
            <div style={{height: "100%", width: "50%"}}>
                <EstateChart data={data.result} subject={subject} />
            </div>
        </div>
    );

    const chartsHorizontal = (
        <div style={{flex: "1 1 auto"}}>
            <div style={{height: "50%"}}>
                <NetCashFlowChart data={data.result} subject={subject} />
            </div>
            <div style={{height: "50%"}}>
                <EstateChart data={data.result} subject={subject} />
            </div>
        </div>);

    const ExtraCharts = (props: {children: ReactChild}) => (
        <div style={{height: "100%", display: "flex", flexDirection: "row"}}>
            <div style={{height: "100%", width: "50%"}}>
                {props.children}
            </div>
            <div style={{height: "100%", width: "50%"}}>
                <div style={{height: "100%", display: "flex", flexDirection: "column"}}>
                    <div style={{height: "100%"}}>
                        <NetCashFlowChart data={data.result} subject={subject} />
                    </div>
                    <div style={{height: "100%"}}>
                        <EstateChart data={data.result} subject={subject} />
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <Layout style={{height: "100%"}}>
            <Content>
                <Card
                    style={{height: "100%", display: "flex", flexFlow: "column"}}
                    headStyle={{flex: "0 0 84px", border: 0}}
                    bodyStyle={{flex: "1 1 auto"}}
                    title={<ScenarioEditorMenu />}
                      >
                    <SwitchTransition mode={"out-in"}>
                            <CSSTransition
                            key={loc.pathname}
                            timeout={200}
                            classNames={"fade"}
                        >
                            <Switch location={loc}>
                                <Route path="/scenario/edit/income" children={<IncomeUpdate key={data.income.id} income={data.income}>{charts}</IncomeUpdate>} />
                                <Route path="/scenario/edit/expenses" children={<ExpensesUpdate key={data.expenses.id} income={data.expenses} chart={<ExtraCharts><ExpensesChart expenseChartResult={data.result} subject={subject} /></ExtraCharts>} />} />
                                <Route path="/scenario/edit/assets-financial" children={<FinancialAssetsUpdate key={data.assets.id} data={data.assets} chart={<ExtraCharts><FinancialAssetsChart data={data.result} subject={subject} /></ExtraCharts>}  />} />
                                <Route path="/scenario/edit/assets-real" children={<RealAssetsUpdate key={data.assets.id} data={data.assets} chart={<ExtraCharts><RealAssetsChart data={data.result} subject={subject} /></ExtraCharts>} />} />
                                <Route path="/scenario/edit/debts" children={<DebtsUpdate key={data.debts.id} debts={data.debts} chart={<ExtraCharts><DebtsChart data={data.result} subject={subject} /></ExtraCharts>} />} />
                                <Route path="*" children={<ScenarioDetailedSummary key={data.id} scenario={data} chart={chartsHorizontal} />} />
                            </Switch>
                        </CSSTransition>
                    </SwitchTransition>
                </Card>
            </Content>
        </Layout>
    );
}