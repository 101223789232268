import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react'
import {Select} from 'antd'
import {ICellEditorParams} from "ag-grid-community";

const {Option} = Select;
export default forwardRef((props: ICellEditorParams, ref) => {
    const inputRef = useRef<any>();
    const selectRef = useRef<any>();
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(props.value);

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return inputRef.current.value;
            },
            afterGuiAttached: () => {
                setOpen(true);
                selectRef.current.focus();
            },
            isCancelAfterEnd: () => {
                setOpen(false);
                props.api?.setFocusedCell(props.rowIndex, props.column);
            }
        };
    });

    return <div>
        <input ref={inputRef} defaultValue={props.value} hidden />
        <Select ref={selectRef}
                style={{width: props.column.getActualWidth() - 17}}
                value={value}
                open={open}
                onChange={value => {
                    setValue(value);
                    inputRef.current.value = value;
                    props.api?.stopEditing();
                }}>
            {props.colDef.cellEditorParams.values.map((x:any) => <Option value={x.key}>{x.value}</Option>)}
        </Select>
    </div>;
});