

export function Tooltip(xAxis: any[][], title?: string, scale?:number):any {
    return {
        trigger: 'axis',
        show: true,
        transitionDuration: 0,
        backgroundColor: "rgba(255, 255, 255, 0.9)",
        extraCssText: 'box-shadow: 0 0 8px rgba(0, 0, 0, 0.65);',
        textStyle: {
            color: "rgba(0, 0, 0, 0.865)",
            fontSize: "12",
            fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'"
        },
        borderWidth: 0,
        borderRadius: 4,
        padding: 12,
        formatter: (params: {componentSubType: string, dataIndex: number, value: number, seriesName: string}[]) => {

            const header = `<table>
                        <theader><th colspan="3" style="text-align: center">${title ?? ""}</th></theader>
                        <tr><td style="width: 140px">Year</td><td>:</td><td style="text-align: right; font-weight: bold">${xAxis[1][params[0].dataIndex]}</td></tr>
                        <tr><td>Age</td><td>:</td><td style="text-align: right; font-weight: bold">${xAxis[0][params[0].dataIndex]}</td></tr>
                        <tr><td>&nbsp;</td><td></td><td></td></tr>`;

            const getFormattedValue = (value:number) => {
                let scaledValue = value;
                if(scale)
                    scaledValue = (value / scale);

                return scaledValue.toFixed(scale ? 3: 0).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }

            const getTable = (params: {componentSubType: string, dataIndex: number, value: number, seriesName: string}[]) => {
                const total = params.map((x: any) => x.value).reduce((a: number, b: number) => a + b, 0);

                return [total, `${params
                    .filter(x => x.value)
                    .map(x => `<tr>
                                <td>${x.seriesName}</td>
                                <td>$</td>
                                <td style="color: ${x.value < 0 ? "red" : "black"}; text-align: right; font-weight: bold">${getFormattedValue(x.value)}</td>
                            </tr>`).join("")}
                            <tr style="border-top: black 1px solid"><td>Total</td><td>$</td><td style="text-align: right; font-weight: bold">${getFormattedValue(total)}</td></tr>`];
            }

            const barTotal = getTable(params.filter(x => x !== undefined && x.componentSubType === "bar"));
            const lineData = params.filter(x => x !== undefined && x.componentSubType === "line");
            const lineTotal = getTable(lineData);

            return header
                + barTotal[1]
                + `<tr><td>&nbsp;</td><td></td><td></td></tr>`
                + (lineData.length === 0 ? "" :
                    lineTotal[1]
                + `<tr><td>&nbsp;</td><td></td><td></td></tr>`
                + `<tr><td>Difference</td><td>$</td><td style="text-align: right; font-weight: bold">${getFormattedValue(barTotal[0] - lineTotal[0])}</td></tr>`)
                + `</table>`;
        },
        axisPointer: {
            type: 'shadow',
            label: {
                backgroundColor: '#6a7985'
            }
        }
    }
}

export function TooltipCompare(xAxis: any[][], dataType: "none" | "dollar" | "percent"):any {
    return {
        trigger: 'axis',
        show: true,
        transitionDuration: 0,
        backgroundColor: "rgba(255, 255, 255, 0.9)",
        extraCssText: 'box-shadow: 0 0 8px rgba(0, 0, 0, 0.65);',
        textStyle: {
            color: "rgba(0, 0, 0, 0.865)",
            fontSize: "12",
            fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'"
        },
        borderWidth: 0,
        borderRadius: 4,
        padding: 12,
        formatter: (params: any) => {
            const compareValue = params[0].value;
            return `
                <table>
                    <tr style="border-bottom: black 1px solid"><td colspan="2">Year: ${xAxis[1][params[0].dataIndex]}, Age: ${xAxis[0][params[0].dataIndex]}</td><td></td><td style="width:50px; text-align: right">Amount</td><td></td><td style="width:50px; text-align: right">Diff</td></tr>
                    ${params
                    .filter((x:any) => x.value)
                    .map( (x: any, i: number) => {
                        const diff = x.value - compareValue;
                        const color = diff < 0 ? "red" : "";
                        const sDiff = diff.toFixed(dataType === "percent" ? 2 : 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        const prefix = dataType === "dollar" ? "$" : "";
                        const postfix = dataType === "percent" ? "%" : "";
                        
                        return `<tr>
                                    <td style="width: 12px; background-color: ${x.color}; margin: 12px"></td>
                                    <td style="padding: 0 8px 0 8px; font-weight: bold">${x.seriesName}</td>
                                    <td>${prefix}</td>
                                    <td style="text-align: right">${x.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${postfix}</td>
                                    <td>${i === 0 ? "" : prefix}</td>
                                    <td style="text-align: right; font-weight: bold; color: ${color}">${i === 0 ? "" : `${sDiff} ${postfix}`}</td>
                                </tr>`}
                        ).join("")}
                    </table>`;
        },
        axisPointer: {
            type: 'line'
        }
    };
}