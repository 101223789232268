import React, {ReactChild, useContext, useEffect, useState} from "react";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay/hooks";
import {Button, Space, Typography} from 'antd';
import {DollarOutlined} from '@ant-design/icons'
import moment from "moment";
import AppContext from "../../../AppContext";
import IncomeGrid, {Income} from "./IncomeGrid";
import {IncomeUpdate$key} from "./__generated__/IncomeUpdate.graphql";
import EditorLayout from "../../EditorLayout";
import useIncomeUpdate from "../../Mutations/useIncomeUpdate";
import useIncomeRemove from "../../Mutations/useIncomeRemove";
import useIncomeUpdateOrdinals from "../../Mutations/useIncomeUpdateOrdinals";

const {Text} = Typography;

const query =  graphql`
    fragment IncomeUpdate on Income {
        id
        incomeSources {
            id
            description
            amount
            annualIncrease
            type
            start {
                type
                date
            }
            end {
                type
                date
            }
        }
    }
`;

export interface IncomeUpdateProps {
    income: IncomeUpdate$key
    children: ReactChild
}

export default function IncomeUpdate (props: IncomeUpdateProps) {
    const app = useContext(AppContext);
    const data = useFragment(query, props.income);

    const [incomeList, setIncomeList] = useState<Income[]>([]);
    const [commitUpdate, isUpdating] = useIncomeUpdate();
    const [commitRemove, isRemoving] = useIncomeRemove();
    const [commitUpdateOrdinal, isUpdatingOrdinal] = useIncomeUpdateOrdinals();

    useEffect(() => {
        setIncomeList(data.incomeSources.map(x => {
            return {
                id: x.id,
                amount: x.amount,
                description: x.description,
                annualIncrease: x.annualIncrease,
                type: x.type,
                startType: x.start.type === "None" ? "Now" : x.start.type,
                startDate: x.start.date,
                endType: x.end.type === "None" ? "Never" : x.end.type,
                endDate: x.end.date,
                onRemove: () => commitRemove(app.scenario.id, x.id, x.description)
            }
        }));
    }, [data.incomeSources, app.scenario.id, setIncomeList, commitRemove]);

    const handleOnAdd = () => {
        const description = "Income #" + (data.incomeSources.length + 1);

        commitUpdate(app.scenario.id,
            {
                id: "00000000-0000-0000-0000-000000000000",
                amount: 0,
                annualIncrease: 0,
                description: description,
                type: "Employment",
                startType: "Now",
                startDate: moment().format("yyyy-MM-DD"),
                endType: "Retirement",
                endDate: moment().format("yyyy-MM-DD")
            }
        );

        return true;
    }

    const handleOnUpdate = (income: Income) => {
        commitUpdate(
            app.scenario.id,
            {
                id: income.id,
                description: income.description,
                amount: income.amount,
                annualIncrease: income.annualIncrease,
                type: income.type,
                startType: income.startType,
                startDate: income.startDate !== null && income.startDate !== undefined ? income.startDate : null,
                endType: income.endType,
                endDate: income.endDate !== null && income.endDate !== undefined ? income.endDate : null
            }
        );

        return true;
    };

    const onUpdateOrdinals = (order: string[]) => {
        commitUpdateOrdinal(app.scenario.id, data.id, order);
    }



    return (
        <EditorLayout
            controls={
                <Space align="center">
                    <Button onClick={handleOnAdd} type={"primary"} disabled={isUpdating || isRemoving || isUpdatingOrdinal} ><DollarOutlined /> Add Income</Button>
                    <Text type={"secondary"}>The type of income determines how taxes are handled.</Text>
                </Space>}
            editor={<IncomeGrid data={incomeList} update={handleOnUpdate} updateOrdinals={onUpdateOrdinals} />}
            chart={props.children} />
    )
};