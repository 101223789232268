/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FinancialAssetType = "NonRegistered" | "Rrsp" | "Tfsa" | "%future added value";
export type FinancialAssetsUpdate = {
    readonly id: string;
    readonly financialAssets: ReadonlyArray<{
        readonly id: string;
        readonly description: string;
        readonly type: FinancialAssetType;
        readonly amount: number;
        readonly cost: number;
        readonly percentCash: number;
        readonly rorCash: number;
        readonly percentFixedIncome: number;
        readonly rorFixedIncome: number;
        readonly percentEquity: number;
        readonly rorEquity: number;
    }>;
    readonly " $refType": "FinancialAssetsUpdate";
};
export type FinancialAssetsUpdate$data = FinancialAssetsUpdate;
export type FinancialAssetsUpdate$key = {
    readonly " $data"?: FinancialAssetsUpdate$data;
    readonly " $fragmentRefs": FragmentRefs<"FinancialAssetsUpdate">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FinancialAssetsUpdate",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "FinancialAsset",
      "kind": "LinkedField",
      "name": "financialAssets",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cost",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "percentCash",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rorCash",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "percentFixedIncome",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rorFixedIncome",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "percentEquity",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rorEquity",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Assets",
  "abstractKey": null
};
})();
(node as any).hash = 'a57bc8e326406043409d88b5e5acd782';
export default node;
