import React, {useContext, useState} from "react";
import {graphql} from "babel-plugin-relay/macro";
import {BenefitsUpdatePanel$key} from "./__generated__/BenefitsUpdatePanel.graphql";
import {Space, Form, InputNumber, Button, Divider} from "antd";
import {useFragment} from "react-relay/hooks";
import AppContext from "../../../AppContext";
import useBenefitsUpdate from "../../Mutations/useBenefitsUpdate";

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const tailLayout = {
    wrapperCol: { offset: 0, span: 24 },
};

const query =  graphql`
    fragment BenefitsUpdatePanel on Benefits {
        id
        rrspRoom
        tfsaRoom
        cppAge
        oasYearsInCanada
        oasMonthsDelayed
    }
`;

export default function BenefitsUpdatePanel(props: { data: BenefitsUpdatePanel$key }) {
    const app = useContext(AppContext);
    const data = useFragment(query, props.data);
    const [commitUpdate, committingUpdate] = useBenefitsUpdate();
    const [isChanged, setIsChanged] = useState(false);

    const onFinish = (value: {rrspRoom: number , tfsaRoom: number, cppAge: number, oasYearsInCanada: number, oasMonthsDelayed: number}) => {
        commitUpdate({
                scenarioId: app.scenario.id,
                rrspRoom: value.rrspRoom,
                tfsaRoom: value.tfsaRoom,
                cppAge: value.cppAge,
                oasYearsInCanada: value.oasYearsInCanada,
                oasMonthsDelayed: value.oasMonthsDelayed
            },
            () => setIsChanged(false))
    }

    const onChange = () => {
        setIsChanged(true);
    }

    return (
        <div  style={{height: "calc(100% - 10px)", margin: 1}}>
            <Form
                {...layout}
                initialValues={{
                    rrspRoom: data.rrspRoom,
                    tfsaRoom: data.tfsaRoom,
                    cppAge: data.cppAge,
                    oasYearsInCanada: data.oasYearsInCanada,
                    oasMonthsDelayed: data.oasMonthsDelayed
                }}
                onFinish={onFinish}
                onChange={onChange}
                layout={"vertical"}
            >
                <Divider orientation={"left"}>Contribution Room</Divider>
                <Form.Item
                    label="RRSP"
                    name="rrspRoom"
                    help={"Amount of RRSP contribution room available for the first forecast year."}
                    rules={[{ required: true, message: 'Field required.' }]}>
                    <InputNumber
                        min={0}
                        max={100000}
                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value:any) => value.replace(/\$\s?|(,*)/g, '')}
                        onChange={onChange}
                    />
                </Form.Item>
                <Form.Item
                    label="TFSA"
                    name="tfsaRoom"
                    help={"Amount of RRSP contribution room available for the first forecast year."}
                    rules={[{ required: true, message: 'Field required.' }]}>
                    <InputNumber
                        min={0}
                        max={100000}
                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value:any) => value.replace(/\$\s?|(,*)/g, '')}
                        onChange={onChange}
                    />
                </Form.Item>
                <Divider orientation={"left"}>Canadian Pension Plan</Divider>
                <Form.Item
                    label="CPP age"
                    name="cppAge"
                    help={"Age you will start receiving CPP. Must be between 60 and 70 years."}
                    rules={[{ required: true, message: 'Field required.' }]}>
                    <InputNumber min={60} max={70} onChange={onChange} />
                </Form.Item>
                <Divider orientation={"left"}>Old age security</Divider>
                <Form.Item
                    label="Years in canada"
                    name="oasYearsInCanada"
                    help={"If you lived in Canada for less than 40 years (after age 18) you will receive a partial payment amount."}
                    rules={[{ required: true, message: 'Field required.' }]}>
                    <InputNumber min={0} max={70} onChange={onChange} />
                </Form.Item>
                <Form.Item
                    label="Months delayed"
                    name="oasMonthsDelayed"
                    help={<div>
                        <p>You can delay up to 60 months (5 years) for an enhanced benefit.</p>
                        <p>
                            For more information: <a target={"_blank"} rel="noopener noreferrer" href={"https://www.canada.ca/en/services/benefits/publicpensions/cpp/old-age-security/benefit-amount.html"}>Old Age Security: How much you could  receive</a>
                        </p>
                    </div>}
                    rules={[{ required: true, message: 'Field required.' }]}>
                    <InputNumber min={0} max={60} onChange={onChange} />
                </Form.Item>
                <Divider />
                <Form.Item
                    {...tailLayout}
                >
                    <Space>
                        <Button type="primary" htmlType="submit" disabled={!isChanged || committingUpdate}>
                            Save
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </div>
    )
}