import {useCallback} from "react";
import {graphql} from "babel-plugin-relay/macro";
import {handleMutation} from "../HandleMutation";
import {useMutation} from "react-relay/hooks";
import {IncomeSourceType, useIncomeUpdateMutation} from "./__generated__/useIncomeUpdateMutation.graphql";

const updateMutation = graphql`
    mutation useIncomeUpdateMutation($scenarioId: ID!, $incomeSourceInput: IncomeSourceInput!){
        incomeSourceUpdate(scenarioId: $scenarioId, incomeSourceInput: $incomeSourceInput){
            income {
                ...IncomeUpdate
            }
            result {
                ...ScenarioResultsDataResult
            }
            ...ScenarioDetailedSummary
            ...ScenarioHierarchicalMenu
        }
    }`;

export interface IncomeInputType {
    id: string
    description: string
    amount: number
    annualIncrease: number
    type: IncomeSourceType
    startType: string
    startDate: unknown
    endType: string
    endDate: unknown
}

export default function useIncomeUpdate(): [(scenarioId: string, incomeInput: IncomeInputType, onCompleted?: () => void) => void, boolean]{
    const [commit, committing] = useMutation<useIncomeUpdateMutation>(updateMutation);
    const mutation = useCallback((scenarioId: string, incomeInput: IncomeInputType, onCompleted?: () => void) => {
        return handleMutation(incomeInput.id, incomeInput.description, "income", "update", commit, {
                variables: {
                    scenarioId: scenarioId,
                    incomeSourceInput: {
                        id: incomeInput.id,
                        description: incomeInput.description,
                        amount: incomeInput.amount,
                        annualIncrease: incomeInput.annualIncrease,
                        type: incomeInput.type,
                        start: {
                            type: incomeInput.startType === "Now" ? "None" : incomeInput.startType === "Retirement" ? "Retirement" : "Date",
                            date: incomeInput.startDate !== null && incomeInput.startDate !== undefined ? incomeInput.startDate : null
                        },
                        end: {
                            type: incomeInput.endType === "Never" ? "None" : incomeInput.endType === "Retirement" ? "Retirement" : "Date",
                            date: incomeInput.endDate !== null && incomeInput.endDate !== undefined ? incomeInput.endDate : null
                        }
                    }
                },
                onCompleted: () => {
                    if (onCompleted)
                        onCompleted();
                }
            }
        );
    }, [commit]);

    return [mutation, committing];
}