/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type RetirementAgeType = "Age" | "FinAssetValue" | "WithdrawalRate" | "%future added value";
export type ScenarioUpdate = {
    readonly id: string;
    readonly description: string;
    readonly startYear: number;
    readonly retirementAgeValue: number;
    readonly retirementAgeType: RetirementAgeType;
    readonly inflationGeneralRate: number;
    readonly inflationCppRate: number;
    readonly inflationOasRate: number;
    readonly " $refType": "ScenarioUpdate";
};
export type ScenarioUpdate$data = ScenarioUpdate;
export type ScenarioUpdate$key = {
    readonly " $data"?: ScenarioUpdate$data;
    readonly " $fragmentRefs": FragmentRefs<"ScenarioUpdate">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScenarioUpdate",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "retirementAgeValue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "retirementAgeType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "inflationGeneralRate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "inflationCppRate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "inflationOasRate",
      "storageKey": null
    }
  ],
  "type": "Scenario",
  "abstractKey": null
};
(node as any).hash = '671956c99be7698457723b9a69c85701';
export default node;
