import {DragStoppedEvent} from "ag-grid-community/dist/lib/events";
import {ValueSetterParams} from "ag-grid-community";

export function onDragStopped(event: DragStoppedEvent, updateOrdinals: (ids: string[]) => void) {
    if(!event.target.classList.contains("ag-row-drag"))
        return;

    const count = event.api.getDisplayedRowCount();
    let ids = [];

    for(let i =0; i < count; i++){
        if(event.api.getDisplayedRowAtIndex(i)?.data.id)
            ids.push(event.api.getDisplayedRowAtIndex(i)?.data.id)
    }

    updateOrdinals(ids);
}

export function updateIfDiff<T>(params: ValueSetterParams, update: (input: T) => boolean, newInput: any):boolean {
    return params.newValue !== params.oldValue?.toString() ? update({...params.data, ...newInput}) : false;
}