import React, {Suspense, useContext, useState} from "react";
import {useLazyLoadQuery} from "react-relay/hooks";
import {graphql} from "babel-plugin-relay/macro";
import {Affix, Layout, Select, Spin, Col, Row, Card, Typography, Space, Result} from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { CompareWorkspaceQuery } from "./__generated__/CompareWorkspaceQuery.graphql";
import CompareDataProvider from "./CompareDataProvider";
import CompareTable from "./CampareTable";
import {CompareData} from "./CompareData";
import AppContext from "../../AppContext";
import ScenarioMenu from "../ScenarioMenu";

const {Text} = Typography;
const {Content} = Layout;
const query = graphql`query CompareWorkspaceQuery($scenarioIds: [ID!]!) {
    me {
        person {
            scenarios {
                id
                description
            }
        }
    }
    ...CompareDataProviderQuery @arguments(scenarioId: $scenarioIds)
}`;

export default function CompareWorkspace() {
    const app = useContext(AppContext);
    function handleChange(value: string[]) {
        app.setCompare(value);
    }

    const [scenarioIds] = useState(app.compare);
    const [newData, setNewData] = useState<CompareData>({series: []});
    const data = useLazyLoadQuery<CompareWorkspaceQuery>(query, {scenarioIds: [app.scenario.id, ...scenarioIds]});

    if(app.scenario.id === "00000000-0000-0000-0000-000000000000")
        return <Result
            status="warning"
            title="Please select a plan"
            subTitle= {<ScenarioMenu />}
        />

    const scenarioList = data.me.person.scenarios
        .filter(x => x.id !== app.scenario.id)
        .sort((a, b) => {
            return a.description > b.description ? 1 : a.description < b.description ? -1 : 0;
        })
        .map(x => {return {value:x.id, label: x.description}});

    return (
        <Layout>
            <Content style={{padding: '0 0px'}}>
                <div className="site-layout-content">
                    <div style={{height: "calc(100vh - 8em)", width: "100%"}}>
                        <Suspense fallback={
                            <div style={{
                                height: "calc(100vh - 5em)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                Loading...
                            </div>
                        }>
                            <Row gutter={[4,4]}>
                                <Col span={24}>
                                    <Card size={"small"}>
                                        <Space>
                                            <Text>Scenario: </Text>
                                            <Select allowClear optionFilterProp={"label"} defaultValue={app.compare.filter(x => x !== app.scenario.id)} placeholder={"Select a scenario to compare..."} mode={"multiple"} style={{ width: 500 }} onChange={handleChange}  options={scenarioList} />
                                        </Space>
                                    </Card>
                                </Col>
                            </Row>
                            <Suspense fallback={<Affix style={{ position: 'absolute', top: "64px", right: "10px" }}><Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}/></Affix>}>
                                <CompareDataProvider key={"pro"} scenarios={data} scenarioIds={[app.scenario.id, ...app.compare]} setNewData={setNewData} />
                            </Suspense>
                        </Suspense>
                        <Row>
                            <Col span={12}>
                                <CompareTable key={"table1"} name={"topLeft"} data={newData} selectedChartIndex={0} showLegend={false}/>
                            </Col>
                            <Col span={12}>
                                <CompareTable key={"table2"} name={"topRight"} data={newData} selectedChartIndex={8} showLegend={false}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <CompareTable key={"table3"} name={"bottom"} data={newData} selectedChartIndex={2} />
                            </Col>
                        </Row>
                    </div>
                </div>
            </Content>
    </Layout>
    );
}