import {Payload} from "echarts/types/src/util/types";

export type ChartObserver = (chartId: string, event: Payload) => void;

export default class ChartSubject {
    private observers: ChartObserver[] = [];

    public attach(observer: ChartObserver){
        this.observers.push(observer);
    }

    public detach(observerToRemove: ChartObserver){
        this.observers = this.observers.filter(observer => observer !== observerToRemove);
    }

    public notify(chartId: string, event: Payload){
        this.observers.forEach(observer => observer(chartId, event));
    }
}