import React, {useEffect, useState} from "react";
import {AgGridReact} from "ag-grid-react";
import {GridReadyEvent} from "ag-grid-community/dist/lib/events";
import {GridApi, ValueSetterParams} from "ag-grid-community";
import {columnTypes, columnTypesComponents} from "../Grids/ColumnTypes";
import {DeleteColumnDef, TimingColumnDefs} from "../Grids/ColumnDefs";
import {onDragStopped, updateIfDiff} from "../Grids/Utilities";

export interface Expense {
    id: string
    description: string
    amount: number
    inflation: number
    startType: string
    startDate: any
    endType: string
    endDate: any
    onRemove: () => void
}

export default function ExpensesGrid (props: {
    data: Expense[]
    update: (expense: Expense) => boolean
    updateOrdinals: (ids: string[]) => void
}) {
    const [gridApi, setGridApi] = useState<GridApi>();

    function onGridReady(event: GridReadyEvent) {
        setGridApi(event.api);
    }

    const [gridState] = useState({
        columnDefs: [
            {   headerName: "Description",
                field: "description",
                editable: true,
                rowDrag: true,
                width: 160,
                valueSetter:  (params:ValueSetterParams) =>  updateIfDiff(params, props.update,{description: params.newValue})
            },
            {   headerName: "Amount",
                field: "amount",
                type: ['currencyColumn','importantColumn'],
                editable: true,
                width: 145,
                valueSetter:  (params:ValueSetterParams) =>  updateIfDiff(params, props.update,{amount: params.newValue ?? 0})
            },
            {   headerName: "Inflation",
                field: "inflation",
                type: ['percentageColumn','importantColumn'],
                editable: true,
                width: 145,
                valueSetter:  (params:ValueSetterParams) =>  updateIfDiff(params, props.update,{inflation: params.newValue ?? 0})
            },
            ...TimingColumnDefs<Expense>(props.update),
            DeleteColumnDef(),
        ],
        columnTypes: columnTypes,
        components: columnTypesComponents,
        defaultColDef: {
            resizable: true,
        },
    });

    useEffect(() => {
        gridApi?.setRowData(props.data)
    }, [gridApi, props.data])

    return <div className="ag-theme-material" style={{height: "100%", overflow: "hidden"}}>
        <AgGridReact
            stopEditingWhenGridLosesFocus={true}
            rowData={props.data}
            singleClickEdit={true}
            onDragStopped={event => onDragStopped(event, props.updateOrdinals)}
            suppressMoveWhenRowDragging={true}
            rowDragManaged={true}
            onGridReady={onGridReady}
            frameworkComponents={gridState.components}
            columnDefs={gridState.columnDefs}
            columnTypes={gridState.columnTypes}
            defaultColDef={gridState.defaultColDef}
            suppressNoRowsOverlay={true}
        />
    </div>
}