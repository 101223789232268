import React from "react";
import {useLazyLoadQuery} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {AssumptionsStepQuery} from "./__generated__/AssumptionsStepQuery.graphql";
import {FormInstance, Typography} from "antd";
import ScenarioUpdate from "../ScenarioEditor/ScenarioUpdate";

const {Title} = Typography;

const query = graphql`
    query AssumptionsStepQuery {
        me {
            person {
                id
                defaultScenario {
                    ...ScenarioUpdate
                    assets {
                        ...FinancialAssetsUpdate
                    }
                }
            }
        }
    }`;

export default function AssumptionsStep(props: {form: FormInstance}) {
    const data = useLazyLoadQuery<AssumptionsStepQuery>(query, {});

    return (
        <div>
            <Title>Assumptions</Title>
            <ScenarioUpdate scenario={data.me.person.defaultScenario} formProps={{form: props.form, labelCol: { span: 8 }, wrapperCol: { span: 8 }}} tailProps={{hidden: false, wrapperCol: { offset: 8, span: 8 }}} hideInflation={true} />
        </div>
    );
}