import {ColDef, IsColumnFuncParams, ValueGetterParams, ValueSetterParams} from "ag-grid-community";
import {updateIfDiff} from "./Utilities";

export function TimingColumnDefs<T> (update: (input: T) => boolean) {
    const columns:Array<ColDef> = [
            {   headerName: "Start",
                field: "startType",
                type: "selectColumn",
                editable: true,
                width: 160,
                valueSetter: (params: ValueSetterParams) => {
                    return updateIfDiff<T>(params, () => {
                        const startType = params.newValue;
                        let endType = params.data.endType;

                        if(startType === "Retirement" && endType === "Retirement")
                            endType = "Never";

                        return update({...params.data,
                            startType: startType,
                            endType: endType
                        })
                    }, {});
                },
                cellEditorParams: {values: [{key:"Now", value:"Now"}, {key:"Retirement", value:"Retirement"}, {key:"Date", value:"Date"}]}
            },
            {   headerName: "Start date",
                type: "dateColumn",
                width: 160,
                editable: (params: IsColumnFuncParams) => params.data.startType === "Date",
                valueGetter: (params: ValueGetterParams) => params.data.startType === "Date" ? params.data.startDate : null,
                valueSetter:  (params:ValueSetterParams) =>  update({...params.data, startDate: params.newValue}),
            },
            {   headerName: "End",
                field: "endType",
                type: "selectColumn",
                editable: true,
                width: 160,
                valueSetter: (params: ValueSetterParams) => {
                    const endType = params.newValue;
                    let startType = params.data.startType;

                    if(startType === "Retirement" && endType === "Retirement")
                        startType = "Now";

                    return update({...params.data,
                        startType: startType,
                        endType: endType
                    })
                },
                cellEditorParams: {values: [{key: "Never", value: "Never"}, {key: "Retirement", value: "Retirement"}, {key: "Date", value: "Date"}]},
            },
            {   headerName: "End date",
                type: "dateColumn",
                width: 160,
                editable: (params: IsColumnFuncParams) => params.data.endType === "Date",
                valueGetter: (params: ValueGetterParams) => params.data.endType === "Date" ? params.data.endDate : null,
                valueSetter:  (params:ValueSetterParams) =>  update({...params.data, endDate: params.newValue}),
            }
        ];

    return columns;
}

export function DeleteColumnDef() {
    return {
        headerName: "",
        field: "id",
        width: 80,
        //flex: 1,
        cellRenderer: "agDeleteRenderer"
    };
}