/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type useScenarioRemoveMutationVariables = {
    scenarioId: string;
};
export type useScenarioRemoveMutationResponse = {
    readonly scenarioRemove: {
        readonly scenarios: ReadonlyArray<{
            readonly id: string;
            readonly description: string;
        }>;
    };
};
export type useScenarioRemoveMutation = {
    readonly response: useScenarioRemoveMutationResponse;
    readonly variables: useScenarioRemoveMutationVariables;
};



/*
mutation useScenarioRemoveMutation(
  $scenarioId: ID!
) {
  scenarioRemove(scenarioId: $scenarioId) {
    scenarios {
      id
      description
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "scenarioId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "scenarioId",
    "variableName": "scenarioId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Scenario",
  "kind": "LinkedField",
  "name": "scenarios",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useScenarioRemoveMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Person",
        "kind": "LinkedField",
        "name": "scenarioRemove",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useScenarioRemoveMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Person",
        "kind": "LinkedField",
        "name": "scenarioRemove",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "732be7c7abec4d27a612a1d54d6274b9",
    "id": null,
    "metadata": {},
    "name": "useScenarioRemoveMutation",
    "operationKind": "mutation",
    "text": "mutation useScenarioRemoveMutation(\n  $scenarioId: ID!\n) {\n  scenarioRemove(scenarioId: $scenarioId) {\n    scenarios {\n      id\n      description\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '625c1d7a9cdf52ee2a29f41ce3646eb5';
export default node;
