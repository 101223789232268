import {graphql} from "babel-plugin-relay/macro";
import {useCallback} from "react";
import {useMutation} from "react-relay/hooks";
import { useRealAssetUpdateMutation } from "./__generated__/useRealAssetUpdateMutation.graphql";
import {handleMutation} from "../HandleMutation";

const updateMutation = graphql`
    mutation useRealAssetUpdateMutation($scenarioId: ID!, $realAssetInput: RealAssetInput!){
        realAssetUpdate(scenarioId: $scenarioId, realAssetInput: $realAssetInput){
            assets {
                ...RealAssetsUpdate
            }
            result {
                ...ScenarioResultsDataResult
            }
            ...ScenarioDetailedSummary
            ...ScenarioHierarchicalMenu
        }
    }`;

export interface RealAssetInputType {
    id: string
    description: string
    appreciationRate: number
    currentValue: number
    originalValue: number
    taxableGains: boolean
    futurePurchaseDate: unknown
    futureSaleDate: unknown
}

export default function useRealAssetUpdate():[(scenarioId: string, value: RealAssetInputType) => void, boolean]{
    const [commit, committing] = useMutation<useRealAssetUpdateMutation>(updateMutation);
    const callback = useCallback((scenarioId: string, value: RealAssetInputType) => {
        handleMutation(value.id, value.description, "financial asset", "update", commit,{
            variables: {
                scenarioId: scenarioId,
                realAssetInput: {
                    id: value.id,
                    description: value.description,
                    appreciationRate: value.appreciationRate,
                    currentValue: value.currentValue,
                    originalValue: value.originalValue,
                    taxableGains: value.taxableGains,
                    futurePurchaseDate: value.futurePurchaseDate,
                    futureSaleDate: value.futureSaleDate,
                }
            }
        });
    }, [commit]);

    return [callback, committing];
}