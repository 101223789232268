import React, {ReactChild, useMemo} from 'react';
import {useFragment} from "react-relay/hooks";
import {graphql} from "babel-plugin-relay/macro";
import {
    ScenarioResultsDataResult,
    ScenarioResultsDataResult$key
} from './__generated__/ScenarioResultsDataResult.graphql';
import {ResultRecord} from "../Domain/ResultTypes";

const query = graphql`
    fragment ScenarioResultsDataResult on Result {
            id
            retirementAge
            steps {
                year
                age
                expenses {
                    id
                    sourceRecordId
                    name
                    amount
                    amountNom
                    isOverride
                }
                income {
                    name
                    amount
                }
                cash
                financialAssets {
                    name
                    rate
                    value
                    contribution
                }
                realAssets {
                    name
                    rate
                    value
                }
                debts {
                    name
                    owing
                    paid
                }
                estate {
                    beforeTax
                    afterTax
                    afterTaxReal
                    tax
                    taxRate
                }
                tax {
                    marginalRate
                    effectiveRate
                    provincialTax
                    federalTax
                    total
                    cpp
                    ei
                }
                oas {
                    payment
                    recovery
                }
                cpp {
                    payment
                }
                expensesTotal
                incomeTotal
                cppOasTotal
                debtsOwingTotal
                debtsPaidTotal
                financialAssetsTotal
                financialAssetsContribTotal
                financialAssetsRorTotal
                realAssetsTotal
                savingsRate
                withdrawalRate
                dtiRatio
                tfsaRoom
                rrspRoom
            }
    }`;

export interface ResultsProps {
    results: ScenarioResultsDataResult$key
    children: (props: any) => ReactChild
}


const GetData = function (data: ScenarioResultsDataResult) {
    const dataSource = data.steps.map<ResultRecord>(x => {
        return {
            year: x.year,
            age: x.age,
            expenses: x.expenses.map(y => {
                return {id: y.id, sourceRecordId: y.sourceRecordId, name: y.name, amount: y.amount, amountNom: y.amountNom, isOverride: y.isOverride}
            }),
            income: x.income.map(y => {
                return {name: y.name, amount: y.amount}
            }),
            cash: x.cash,
            financialAssets: x.financialAssets.map(y => {
                return {name: y.name, rate: y.rate, value: y.value, contribution: y.contribution}
            }),
            realAssets: x.realAssets.map(y => {
                return {name: y.name, rate: y.rate, value: y.value, contribution: 0}
            }),
            debts: x.debts.map(y => {return {name: y.name, owing: y.owing, paid: y.paid }}),
            estate: {beforeTax: x.estate.beforeTax, afterTax: x.estate.afterTax, afterTaxReal: x.estate.afterTaxReal, tax: x.estate.tax, taxRate: x.estate.taxRate},
            tax: {
                effectiveRate: x.tax.effectiveRate,
                marginalRate: x.tax.marginalRate,
                provincial: x.tax.provincialTax,
                federal: x.tax.federalTax,
                total: x.tax.total,
                cpp: x.tax.cpp,
                ei: x.tax.ei
            },
            oas: {payment: x.oas.payment, recovery: x.oas.recovery},
            cpp: {payment: x.cpp.payment},
            expensesTotal: x.expensesTotal,
            incomeTotal: x.incomeTotal,
            cppOasTotal: x.cppOasTotal,
            debtsOwingTotal: x.debtsOwingTotal,
            debtsPaidTotal: x.debtsPaidTotal,
            financialAssetsTotal: x.financialAssetsTotal,
            financialAssetsContribTotal: x.financialAssetsContribTotal,
            financialAssetsRorTotal: x.financialAssetsRorTotal,
            realAssetsTotal: x.realAssetsTotal,
            savingsRate: x.savingsRate,
            withdrawalRate: x.withdrawalRate,
            dtiRatio: x.dtiRatio,
            tfsaRoom: x.tfsaRoom,
            rrspRoom: x.rrspRoom
        };
    });

    const retirementAge = data.retirementAge - 0.5;
    const max = 10000000;
    const scale = {
        age: {
            alias: "Age",
            tickInterval: 1
        },
        expenses:{
            alias: "Expenses ($)",
            min: 0,
            max: Math.max(...dataSource.map(x => x.expenses.map(y => y.amount).flat()).flat()) * 2 *0.7
        },
        value: {
            alias: "$",
            tickCount: 5,
            min: 0,
            max: max
        },
        estateValue:{
            min: 0,
            max: max
        },
        estateTaxRate: {
            tickCount: 5,
            max: 60,
            min: 0
        },
        taxEffectiveRate: {
            alias: "Tax Rate (%)",
            max: 60,
            min: 0
        }
    }

    return {
        height: "50%",
        retirementAge: retirementAge,
        oasAge: 65,
        cppAge: 71,
        scale: scale,
        dataSource: dataSource,
        dataId: data.id
    }
};


export default function ScenarioResultsData (props: ResultsProps) {
    const data = useFragment(query, props.results);

    const dataProps = useMemo(() => GetData(data), [data]);

    return (<>{props.children(dataProps)}</>)
}