import React, {useRef} from "react";
import {Button, Popconfirm, Typography} from "antd";
import { DeleteTwoTone } from '@ant-design/icons';

const {Text} = Typography;

export default function DeleteConfirmButton(props: {id: string, description: string, type: string, onRemove: (id: string, description: string) => void}) {
    let btnRef = useRef<any>();

    return   <Popconfirm
        title={<div>Remove {props.type} <Text type="danger" strong>{props.description}</Text>?</div>}
        onConfirm={() => {
            btnRef.current.setAttribute("disabled", "disabled");
            props.onRemove(props.id, props.description);
        }}
        okText="Yes"
        cancelText="No"
    >
        <Button ref={btnRef} type={"link"} >
            <DeleteTwoTone twoToneColor={"#eb2f2f"}/>
        </Button>
    </Popconfirm>
}
