import {useCallback, useContext} from "react";
import {graphql} from "babel-plugin-relay/macro";
import {useMutation} from "react-relay/hooks";
import {handleMutation} from "../HandleMutation";
import AppContext from "../../AppContext";
import { useSetScenarioDescriptionMutation } from "./__generated__/useSetScenarioDescriptionMutation.graphql";

const mutation = graphql`
    mutation useSetScenarioDescriptionMutation($scenarioId: ID!, $description: String!){
        setScenarioDescription(scenarioId: $scenarioId, description: $description){
            id
            description
        }
    }`;

export default function useSetScenarioDescription(): [(id: string, description: string, onCompleted?: () => void) => void, boolean]{
    const app = useContext(AppContext);
    const [commit, committing] = useMutation<useSetScenarioDescriptionMutation>(mutation);
    const callback = useCallback((id: string, description: string, onCompleted?: () => void) => {
        handleMutation("", description, "scenario", "update", commit, {
            variables: {
                scenarioId: id,
                description: description,
            },
            onCompleted: response => {
                app.setScenario({id: response.setScenarioDescription.id, name: response.setScenarioDescription.description});
                if(onCompleted)
                    onCompleted();
            }
        })
    }, [commit, app]);

    return [callback, committing];
}