import React, {useContext, useEffect} from "react";
import {graphql} from "babel-plugin-relay/macro";
import {useLazyLoadQuery} from "react-relay/hooks";
import {Menu} from "antd";
import {FileAddOutlined, FileOutlined, MenuOutlined, UserOutlined} from '@ant-design/icons';
import {ScenarioMenuQuery} from "./__generated__/ScenarioMenuQuery.graphql";
import AppContext from "../AppContext";
import useScenarioAdd from "./Mutations/useScenarioAdd";
import {Link, useLocation} from "react-router-dom";

const query = graphql`query ScenarioMenuQuery {
    me {
        person {
            id
            scenarios {
                id
                description
                startYear
            }
        }
    }
}`;

export default function ScenarioMenu(props: {toggleMenu?: () => void}) {
    const app = useContext(AppContext);
    const data = useLazyLoadQuery<ScenarioMenuQuery>(query, {});
    const [commitAdd] = useScenarioAdd();
    const location = useLocation();

    function handleOnClickScenario(id: string) {
        const name = data.me.person.scenarios.find(x => x.id === id)?.description ?? "";
        app.setScenario({id, name});
    }
    
    useEffect(() => {
        if(app.person.id !== data.me.person.id)
            app.setPerson({id: data.me.person.id});
    }, [data.me.person.id, app])

    function sort(a:any, b:any, desc?:boolean) {
        if(a < b)
            return desc ? 1 : -1;
        if(a > b)
            return desc ? -1 : 1;
        return 0;
    }

    function distinct(value:any, index:number, self: Array<any>) {
        return self.indexOf(value) === index;
    }

    const years = data.me.person.scenarios.map(x => x.startYear).filter(distinct).sort((a, b) => sort(a, b, true));

    const linkLocation = location.pathname === "/" ? "/scenario/edit" : location.pathname;

    return <Menu selectedKeys={[app.scenario.id]} theme={"dark"} mode={"inline"} inlineIndent={16} defaultOpenKeys={["plans"]}>
        <Menu.Item key={"title"} hidden={!props.toggleMenu} style={{color: "#FFF"}} icon={<MenuOutlined />} onClick={() => {if(props.toggleMenu) props.toggleMenu()}}>
            Wealth.li
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="personal/" icon={<UserOutlined/>}>
            <Link to="/personal">Personal</Link>
        </Menu.Item>
        <Menu.Item key={"new-plan"} style={{color: "#FFF"}} icon={<FileAddOutlined />} onClick={() => commitAdd(data.me.person.id, null, "New plan", () => {})}>
            New plan
        </Menu.Item>
        <Menu.Divider />


            {years.map(y => <Menu.ItemGroup key={y} title={y}>
                {data.me.person.scenarios
                    .filter(x => x.startYear === y)
                    .map(x => {return{...x}})
                    .sort((a, b) => sort(a.description, b.description))
                    .map(x => <Menu.Item style={{color: "#FFF"}} key={x.id} title={x.description} icon={<FileOutlined />} onClick={() => {handleOnClickScenario(x.id)}}>
                        <Link to={linkLocation}>{x.description}</Link>
                    </Menu.Item>)}
            </Menu.ItemGroup>)}



    </Menu>
}