import {useCallback} from "react";
import {graphql} from "babel-plugin-relay/macro";
import {useMutation} from "react-relay/hooks";
import {handleMutation} from "../HandleMutation";
import {useIncomeUpdateOrdinalsMutation} from "./__generated__/useIncomeUpdateOrdinalsMutation.graphql";

const updateOrdinalMutation = graphql`
    mutation useIncomeUpdateOrdinalsMutation($scenarioId: ID!, $inputType: InputType!, $ids: [String!]!){
        ordinalUpdate(scenarioId: $scenarioId, inputType: $inputType, ids: $ids ){
            income {
                ...IncomeUpdate
            }
            result {
                ...ScenarioResultsDataResult
            }
            ...ScenarioDetailedSummary
            ...ScenarioHierarchicalMenu
        }
    }
`;

export default function useIncomeUpdateOrdinals():[(scenarioId: string, id: string, ids: string[]) => void, boolean]{
    const [commit, committing] = useMutation<useIncomeUpdateOrdinalsMutation>(updateOrdinalMutation)
    const mutation = useCallback((scenarioId: string, id: string, ids: string[]) => {
        handleMutation(id, "Income", "order", "update", commit, {
            variables: {scenarioId: scenarioId, inputType: "Income", ids: ids}
        })}, [commit])

    return [mutation, committing]
}