import React from "react";
import {Typography} from "antd";
import {FinancialAssetsUpdate$key} from "../ScenarioEditor/Assets/FinancialAssets/__generated__/FinancialAssetsUpdate.graphql";
import FinancialAssetsUpdate from "../ScenarioEditor/Assets/FinancialAssets/FinancialAssetsUpdate";
import FinancialAssetsChart from "../ScenarioEditor/Assets/FinancialAssets/FinancialAssetsChart";
import {FinancialAssetsChartResult$key} from "../ScenarioEditor/Assets/FinancialAssets/__generated__/FinancialAssetsChartResult.graphql";

const {Title} = Typography;

export default function InvestmentsStep(props: {gridData: FinancialAssetsUpdate$key, chartData: FinancialAssetsChartResult$key}) {

    return (
        <div style={{height: "100%", display: "flex", flexFlow: "column"}}>
            <Title style={{flex: "0 0 47px"}} level={3}>Investments</Title>
            <div style={{flex: "0 0 100px"}}>
                <FinancialAssetsUpdate data={props.gridData} simple={true} />
            </div>
            <div style={{flex: "1 1 100%"}}>
                <FinancialAssetsChart data={props.chartData} hideTitle={true} hideTypes={true} />
            </div>
        </div>
    );
}