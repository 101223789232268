import React from "react";
import {Card, Col, Layout, Row} from "antd";
import PersonUpdate from "./PersonUpdate";

const {Content} = Layout;

export default function PersonUpdateLayout() {
    return <Layout className="layout">
        <Layout>
            <div className="site-layout-content">
                <Row gutter={[4,4]}>
                    <Col span={24}>
                        <Content>
                            <Card>
                                <PersonUpdate />
                            </Card>
                        </Content>
                    </Col>
                </Row>
            </div>
        </Layout>
    </Layout>
}